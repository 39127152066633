import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs';
import { ActiveSubstanceService } from 'src/app/services/active-substance.service';
import { DefaultService } from 'src/app/utils/api';
import { BrandServiceService } from 'src/app/services/brand-service.service';

@Component({
    selector: 'app-add-brand-modal',
    templateUrl: './add-brand-modal.component.html',
    styleUrls: ['./add-brand-modal.component.scss']
})
export class AddBrandModalComponent implements OnInit {
    @Input() activeSubstance?: any;
    @Input() brand?: any;
    @Output() created = new EventEmitter();
    ready: boolean = false;
    loading: boolean = false;
    activeBrand: any = {};
    validation: any = {};
    changes: boolean = false;
    submitting: boolean = false;
    id: any;

    formSTR: any = {};

    constructor(
        public ActiveModal: NgbActiveModal,
        public activeSubstanceService: ActiveSubstanceService,
        public DefaultService: DefaultService,
        private BrandServiceService: BrandServiceService
    ) {}

    ngOnInit(): void {
        setTimeout(() => {
            this.ready = true;
        }, 1);
        this.formSTR.activeSubstanceId = this.activeSubstance.id;
        if (this.brand) {
            this.id = this.brand.id;
            this.formSTR = this.brand;
        }
    }

    formChange() {
        this.changes = true;
    }

    resetForm() {
        this.formSTR = { ...this.activeBrand };
    }

    submit() {
        const FORM = this.formSTR;
        this.submitting = true;
        // if url doesnt start with http:// or https:// add http://
        if (FORM.url && !FORM.url.startsWith('http')) {
            FORM.url = 'http://' + FORM.url;
        }
        FORM.url = FORM.url || null;
        this.BrandServiceService.updateInsertBrand(FORM, this.id || null)
            .pipe(
                finalize(() => {
                    this.submitting = false;
                })
            )
            .subscribe({
                next: (next) => {
                    this.validation = {};
                    this.changes = false;
                    this.created.emit();
                    this.ActiveModal.dismiss();
                },
                error: (error) => {
                    this.validation = error.error.details;
                }
            });
    }

    canEdit(sectionCode) {
        if (this.activeBrand) {
            return true;
        }
        return false;
    }

    canExit(): boolean {
        if (!this.changes || confirm('Je hebt onopgeslagen wijzigingen, weet je zeker dat je wilt afsluiten?')) {
            this.changes = false;
            return true;
        } else {
            return false;
        }
    }
}
