<ng-container>
    <nav>
        <ul class="d-flex flex-column">
            <li *ngFor="let page of pageARR;" class="d-block" [class.active]="model === page.code" [class.no-pointer-events]="page.disabled" (click)="!page.disabled && modelChange.emit(page.code)">
                <div><span class="icon-container"><i class="im-icon im-icon-{{page.icon || 'arrow-right'}}"></i></span> {{page.name}} <span *ngIf="page.notify" class="ping"></span>
                    
                    <ng-container *ngIf="page.showSubscriptionAmount">
                        (   
                            <span *ngIf="!subscriptionAmountSpinner">{{subscriptionAmount}}</span>
                            <app-inline-spinner *ngIf="subscriptionAmountSpinner"></app-inline-spinner>
                        )
                    </ng-container>
                </div>
            </li>
        </ul>
    </nav>
</ng-container>