<ng-container *ngIf="!linkHref">
    <button (click)="!disabled && !submitting && emitClick.emit()" class="button-component {{classes}}" [class.button--disabled]="disabled" [class.button--submitting]="submitting">
        <div *ngIf="icon" class="button-pre-wrap">
            <!-- icon -->
            <i *ngIf="!submitting" class="im-icon im-icon-{{icon}}" [ngStyle]="{'font-size' : iconSize }"></i>
            <!-- submitting -->
            <app-inline-spinner *ngIf="submitting"></app-inline-spinner>
        </div>
        <!-- text -->
        <span>{{text}}</span>
    </button>
</ng-container>
<ng-container *ngIf="linkHref">
    <a [attr.href]="linkHref" target="{{linkTarget ? linkTarget : '_self'}}" (click)="!disabled && !submitting && emitClick.emit()" class="button-component {{classes}}" [class.button--disabled]="disabled" [class.button--submitting]="submitting">
        <div *ngIf="icon" class="button-pre-wrap">
            <!-- icon -->
            <i *ngIf="!submitting" class="im-icon im-icon-{{icon}}" [ngStyle]="{'font-size' : iconSize }"></i>
            <!-- submitting -->
            <app-inline-spinner *ngIf="submitting"></app-inline-spinner>
        </div>
        <!-- text -->
        <span>{{text}}</span>
    </a>
</ng-container>