import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, takeUntil } from 'rxjs';
import { TipDetailModalComponent } from 'src/app/components/modals/tip-detail-modal/tip-detail-modal.component';
import { ConfigService } from 'src/app/services/config.service';
import { HelpersService } from 'src/app/services/helpers.service';
import { SearchService } from 'src/app/services/search.service';
import { TipsService } from 'src/app/services/tips.service';
import { DefaultService } from 'src/app/utils/api';

@Component({
    selector: 'app-tips',
    templateUrl: './tips.component.html',
    styleUrls: ['./tips.component.scss']
})
export class TipsComponent implements OnInit, OnDestroy {
    onDestroy$ = new Subject<void>();
    popoverHelper: any = null;
    panelData: any = null;
    hasPanelData: boolean = false;
    searchSTR: any = {};
    SEARCHED: any = {};

    // Table
    tableHeads: any = [
        { name: 'Titel', code: 'title', sortable: true, width: '40%' },
        { name: 'Status', code: 'isPublished', sortable: true, width: '20%' },
        { name: 'Homepage', code: 'homePage', sortable: true, width: '.1%' },
        { name: 'Laatst gewijzigd', code: 'updateTS', sortable: true, width: '20%' },
        { name: 'Gewijzigd door', code: 'updateBy', sortable: true, width: '20%' },
        { name: '', code: 'actions' }
    ];
    tableSort: { code: string; dir: string } = { code: 'updateTS', dir: 'desc' };

    tips: any[] = null;
    startRow: number = 0;
    RPP: number = 50;
    totalRows: number = null;
    tableLoading: boolean = false;
    ready: boolean = false;

    constructor(
        private ModalService: NgbModal,
        private TipsService: TipsService,
        public HelpersService: HelpersService,
        public route: ActivatedRoute,
        public router: Router,
        private SearchService: SearchService,
        public ConfigService: ConfigService,
        private DefaultService: DefaultService
    ) {}

    ngOnInit(): void {
        this.initSearchSTR();
        const tipId = this.HelpersService.getParam('tipId');
        if (tipId) this.openCreateUpdateModal(tipId);
        const page = parseInt(this.HelpersService.getParam('page'));
        if (page) this.startRow = this.RPP * page - this.RPP;
        const query = this.HelpersService.getParam('query');
        if (query) this.searchSTR = JSON.parse(unescape(query));

        this.getTips();
    }

    getTips() {
        this.SearchService.formatTags({ ...this.searchSTR }, this.getDefaultSearch()).then((response) => {
            this.SEARCHED = response;
            const searchSTR = this.getSearchQuery();
            this.deSelectRows();
            this.tableLoading = true;
            this.DefaultService.tipsGetTips(searchSTR.startRow, searchSTR.rRP, searchSTR.term, searchSTR.orderBy)
                .pipe(takeUntil(this.onDestroy$))
                .subscribe((next) => {
                    if (this.startRow && this.startRow >= next.rows) {
                        this.resetStartRow();
                        return;
                    }
                    this.tips = next.data.map((item: any) => {
                        return this.getFormattedItem(item);
                    });
                    this.tableLoading = false;
                    this.ready = true;
                    this.totalRows = next.rows;
                    document.body.scrollTop = 0;
                    document.documentElement.scrollTop = 0;
                });
        });
    }

    getFormattedItem(item) {
        const actions = [
            {
                name: 'Wijzigen',
                code: 'edit',
                icon: 'pencil'
            },
            {
                name: 'Verwijderen',
                code: 'delete',
                icon: 'trash',
                class: 'delete-red',
                confirmDelete: true
            }
        ];
        let published = item.isPublished > 1 ? 'Niet gepubliceerd' : 'Gepubliceerd';
        let titleClassName = 'text-color';
        return {
            ...item,
            title: {
                type: 'default',
                classList: titleClassName,
                value: item.title
            },
            actions: {
                type: 'actions',
                actions: actions
            },
            updateTS: {
                type: 'ts',
                ts: item.updateTS,
                format: 'DD-MM-YYYY'
            },
            isPublished: published,
            homePage: { type: 'checkmark', checked: item.homePage ? true : false }
        };
    }

    deleteTip(id) {
        this.dismissPopover();
        this.deSelectRows();
        this.TipsService.deleteTip(id).subscribe((next) => {
            this.getTips();
        });
    }

    dismissPopover() {
        setTimeout(() => {
            this.popoverHelper = null;
        }, 1);
    }
    openCreateUpdateModal(id?: string, editMode?: boolean) {
        const modalRef = this.ModalService.open(TipDetailModalComponent, {
            windowClass: 'main-modal detail-modal',
            beforeDismiss: () => {
                return modalRef.componentInstance.canExit && modalRef.componentInstance.canExit();
            }
        });
        if (id) {
            this.HelpersService.addParam('tipId', id);
            //add pathparam to url
            modalRef.componentInstance.id = id;
            modalRef.componentInstance.editMode = editMode;
        } else this.HelpersService.addParam('tipId', 'new');
        modalRef.result
            .then((returnValue) => {
                switch (returnValue) {
                    case 'delete':
                        this.deSelectRows();
                        this.getTips();
                        break;
                }
            })
            .finally(() => {
                this.HelpersService.removeParam('tipId');
            });
        // on create
        modalRef.componentInstance.created.subscribe((tip: any) => {
            this.HelpersService.addParam('tipId', tip.id);
            this.search(true);
        });
        // on update
        modalRef.componentInstance.updated.subscribe((tip: any) => {
            this.getTips();
        });
    }

    resetStartRow() {
        this.startRow = 0;
        this.HelpersService.addParam('page', (Math.ceil(this.startRow / this.RPP) + 1).toString());
        this.getTips();
    }

    changeStartRow(startRow: number) {
        this.startRow = startRow;
        this.HelpersService.addParam('page', (Math.ceil(this.startRow / this.RPP) + 1).toString());
        this.getTips();
    }

    getSearchQuery() {
        return {
            ...this.searchSTR,
            startRow: this.startRow,
            rRP: this.RPP,
            orderBy: this.getSortARR()
        };
    }

    getDefaultSearch() {
        return {
            tips: []
        };
    }

    getSortARR() {
        if (!this.tableSort || !this.tableSort.code) {
            return [];
        }
        return [`${this.tableSort.code} ${this.tableSort.dir}`];
    }

    setSort(code: string, dir: string) {
        this.tableLoading = true;
        this.tableSort = { code: code, dir: dir };
        this.getTips();
    }

    tableClick(item: any, head: string) {
        switch (item.type) {
            case 'actions':
                break;
            default:
                this.hasPanelData ? this.selectRow(item) : this.openCreateUpdateModal(item.id);
                break;
        }
    }

    actionClick(item: any, action: string) {
        switch (action) {
            case 'edit':
                this.openCreateUpdateModal(item.id, true);
                break;
            case 'delete':
                this.deleteTip(item.id);
                break;
        }
    }

    selectRow(item: any) {
        this.tips = this.tips.map((x) => {
            if (x.id === item.id && !x.trActive) {
                x.trActive = true;
                this.panelData = item;
            } else if (x.id === item.id) {
                delete this.panelData;
                delete x.trActive;
            } else {
                delete x.trActive;
            }
            return x;
        });
    }

    search = (reset?: boolean, SEARCH?: any) => {
        SEARCH = SEARCH || this.searchSTR;
        this.searchSTR = { ...SEARCH };
        this.startRow = 0;
        if (reset) {
            this.initSearchSTR();
        }
        if (this.HelpersService.objectIsEmpty(SEARCH)) {
            this.HelpersService.removeParam('query');
        } else {
            const query = escape(JSON.stringify(SEARCH));
            this.HelpersService.addParam('query', query);
        }
        setTimeout(() => {
            this.HelpersService.addParam('page', (Math.ceil(this.startRow / this.RPP) + 1).toString());
        }, 1);
        this.getTips();
    };

    deSelectRows() {
        if (this.tips) {
            this.tips = this.tips.map((x) => {
                delete x.trActive;
                return x;
            });
        }
        delete this.panelData;
    }

    initSearchSTR() {
        this.searchSTR = {};
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
    }
}
