import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, finalize, takeUntil } from 'rxjs';
import { PageService } from 'src/app/services/page.service';
import { DefaultService } from 'src/app/utils/api';
import { HelpersService } from 'src/app/services/helpers.service';
import { AddSubpageModalComponent } from '../add-subpage-modal/add-subpage-modal.component';

@Component({
    selector: 'app-page-detail-modal',
    templateUrl: './page-detail-modal.component.html',
    styleUrls: ['./page-detail-modal.component.scss']
})
export class PageDetailModalComponent implements OnInit, OnDestroy {
    @Input() id?: any;
    @Input() editMode?: any;
    @Output() updated = new EventEmitter();
    @Output() created = new EventEmitter();

    page: any = {};
    activeSection: string;
    popoverHelper: any = null;
    validation: any = {};
    helper: any = {};
    changes: boolean = false;

    ready: boolean = false;
    loading: boolean = false;
    saving: boolean;
    actionId = null;
    submitting: boolean = false;

    formSTR: any = {};

    onDestroy$: Subject<void> = new Subject<void>();

    constructor(
        private ModalService: NgbModal,
        public ActiveModal: NgbActiveModal,
        public pageService: PageService,
        private DefaultService: DefaultService,
        public HelpersService: HelpersService
    ) {}

    ngOnInit(): void {
        // edit or create
        if (this.id && this.id !== 'new') {
            this.getPage();
        } else {
            this.page = {};
            this.ready = true;
            this.activeSection = 'general';
        }
    }

    getPage() {
        this.loading = true;
        this.pageService
            .getPage(this.id)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((res) => {
                this.page = {
                    ...res,
                    content: res.content.replace(/&nbsp;/gi, ' ')
                };
                if (this.editMode) {
                    this.activeSection = 'general';
                }
                this.resetForm();
                this.ready = true;
                this.loading = false;
            });
    }

    createUpdatePage() {
        const FORM = this.formSTR;
        this.submitting = true;
        this.saving = true;
        if (FORM.isPublished === 1 && !FORM.publishDate) {
            FORM.publishDate = new Date();
        }
        if (FORM.isPublished === 2) {
            FORM.publishDate = null;
        }
        this.pageService
            .updateInsertPage(FORM, this.id || null)
            .pipe(
                finalize(() => {
                    this.saving = false;
                    this.submitting = false;
                })
            )
            .subscribe({
                next: (next) => {
                    this.validation = {};
                    this.changes = false;
                    this.page.id ? this.updated.emit(next) : this.created.emit(next);
                    this.HelpersService.removeParam('pageId');
                    this.ActiveModal.dismiss();
                },
                error: (error) => {
                    this.validation = error.error.details;
                }
            });
    }

    openAddNewSubpageModal(subpage?: any) {
        const modalRef = this.ModalService.open(AddSubpageModalComponent, {
            windowClass: 'add-modal',
            beforeDismiss: () => {
                return modalRef.componentInstance.canExit() && modalRef.componentInstance.canExit;
            }
        });
        if (subpage) {
            modalRef.componentInstance.subpage = subpage;
        }
        modalRef.componentInstance.page = this.page;
        modalRef.componentInstance.created.subscribe((next) => {
            this.getPage();
        });
    }

    openSubpageDetail(subpage) {
        this.id = subpage.id;
        this.HelpersService.addParam('pageId', this.id);
        this.getPage();
    }

    deleteSubpage(subpage) {
        this.pageService.deletePage(subpage.id).subscribe((next) => {
            this.getPage();
        });
    }

    resetForm() {
        this.formSTR = { ...this.page };
    }

    formChange() {
        this.changes = true;
    }

    getPublishedText() {
        if (this.page.isPublished === 1) {
            return 'Ja';
        }
        if (this.page.isPublished === 2) {
            return 'Nee';
        }
    }

    sectionIsActive(code) {
        return this.activeSection === code;
    }

    canExit(): boolean {
        if (!this.changes || confirm('Je hebt onopgeslagen wijzigingen, weet je zeker dat je wilt afsluiten?')) {
            this.changes = false;
            return true;
        } else {
            return false;
        }
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
    }

    @HostListener('window:beforeunload', ['$event'])
    beforeUnloadHandler(event: Event) {
        if (this.changes) return false;
    }
}
