import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, finalize, takeUntil } from 'rxjs';
import { HelpersService } from 'src/app/services/helpers.service';
import { NewsitemServiceService } from 'src/app/services/newsitem-service.service';

@Component({
    selector: 'app-news-detail-modal',
    templateUrl: './news-detail-modal.component.html',
    styleUrls: ['./news-detail-modal.component.scss']
})
export class NewsDetailModalComponent implements OnInit, OnDestroy {
    @Input() id?: any;
    @Input() editMode?: any;
    @Output() created = new EventEmitter();
    @Output() updated = new EventEmitter();

    news: any = {};
    activeSection: string;
    popoverHelper: any = null;
    validation: any = {};
    helper: any = {};
    changes: boolean = false;

    ready: boolean = false;
    loading: boolean = false;
    saving: boolean;
    actionId = null;
    submitting: boolean = false;

    formSTR: any = { homePage: false };
    onDestroy$: Subject<void> = new Subject<void>();

    constructor(
        private ModalService: NgbModal,
        public ActiveModal: NgbActiveModal,
        public newsService: NewsitemServiceService,
        private HelpersService: HelpersService
    ) {}

    ngOnInit(): void {
        // edit or create
        if (this.id) {
            this.getNews();
        } else {
            this.news = {};
            this.ready = true;
            this.activeSection = 'general';
        }
    }

    getNews() {
        this.loading = true;
        this.newsService
            .getNewsitem(this.id)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((res) => {
                this.news = { ...res, homePage: res.homePage || false };
                if (this.editMode) {
                    this.activeSection = 'general';
                }
                this.ready = true;
                this.loading = false;
                this.resetForm();
            });
    }

    imageChange($event) {
        this.formSTR.image = $event;
        if (!$event) {
            delete this.formSTR.imageUrl;
        }
    }

    createUpdateNews() {
        const FORM = this.formSTR;
        if (FORM.isPublished === 1 && !FORM.publishDate) {
            FORM.publishDate = new Date();
        }
        if (FORM.isPublished === 2) {
            FORM.publishDate = null;
        }
        this.submitting = true;
        this.saving = true;
        this.newsService
            .updateInsertNewsitem(FORM, this.id || null)
            .pipe(
                finalize(() => {
                    this.saving = false;
                    this.submitting = false;
                })
            )
            .subscribe({
                next: (next) => {
                    this.validation = {};
                    this.changes = false;
                    if (FORM.image) {
                        this.newsService.uploadImage(FORM.image, next.id).subscribe();
                    } else if (!FORM.imageUrl) {
                        this.newsService.deleteImage(next.id).subscribe();
                    }
                    this.news.id ? this.updated.emit(this.news) : this.created.emit(this.news);
                    this.HelpersService.removeParam('newsId');
                    this.ActiveModal.dismiss();
                },
                error: (error) => {
                    this.validation = error.error.details;
                }
            });
    }

    getPublishedText() {
        if (this.formSTR.isPublished === 1) {
            return 'Ja';
        } else {
            return 'Nee';
        }
    }

    formChange() {
        this.changes = true;
    }

    getHomePageText() {
        return this.news.homePage ? 'Ja' : 'Nee';
    }

    resetForm() {
        this.formSTR = { ...this.news };
    }

    sectionIsActive(code) {
        return this.activeSection === code;
    }

    delete() {
        this.saving = true;
        this.actionId = null;
        this.newsService
            .deleteNewsitem(this.id)
            .pipe(
                finalize(() => {
                    this.saving = false;
                })
            )
            .subscribe((next) => {
                this.activeSection = null;
                this.updated.emit(this.news);
                this.changes = false;
                this.ActiveModal.dismiss();
            });
    }

    canExit(): boolean {
        if (!this.changes || confirm('Je hebt onopgeslagen wijzigingen, weet je zeker dat je wilt afsluiten?')) {
            this.changes = false;
            return true;
        } else {
            return false;
        }
    }

    ngOnDestroy(): void {}
}
