import { Component, EventEmitter, Input, OnInit, Output, OnChanges, ViewChild, SimpleChanges } from '@angular/core';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { merge, Observable, of, OperatorFunction, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';

@Component({
    selector: 'app-field-typeahead',
    templateUrl: './field-typeahead.component.html',
    styleUrls: ['./field-typeahead.component.scss']
})
export class FieldTypeaheadComponent implements OnInit, OnChanges {
    @Input() label?: string;
    @Input() error?: string;
    @Input() optionalString?: string;
    @Input() placeholder?: string;
    @Input() editable?: boolean;
    @Input() disabled?: boolean;
    @Input() model: string;
    @Input() options: { title: string; value: any }[] | any[];
    @Input() searchValueARR?: any[] = [];
    @Input() afterARR?: any[] = [];
    @Input() afterInputARR?: any[] = [];
    @Input() index?: number = 0;
    @Input() getOptionsFn?: Function;
    @Input() code?: string;
    @Input() initialModel?: any;
    @Output() modelChange = new EventEmitter();
    @Output() enter = new EventEmitter();
    localModel: any;

    @ViewChild(NgbTypeahead) ngbTypeahead: NgbTypeahead;
    @ViewChild('instance', { static: true }) instance: NgbTypeahead;
    focus$ = new Subject<string>();
    click$ = new Subject<string>();

    constructor() {}

    ngOnInit() {
        if (!this.searchValueARR || !this.searchValueARR.length) {
            this.searchValueARR = ['title'];
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.model) {
            this.localModel = changes.model.currentValue || null;
        }
        if (changes.initialModel) {
            this.localModel = changes.initialModel.currentValue || null;
        }
    }

    isOpen() {
        if (this.ngbTypeahead) {
            return this.ngbTypeahead.isPopupOpen();
        } else return false;
    }

    onSelect($event) {
        if ($event) {
            this.modelChange.emit($event);
        }
    }

    formatter = (item: { value: any; title: string }) => {
        if (item) {
            let title = item.title;
            if (this.afterInputARR.length) {
                for (let i = 0; i < this.afterInputARR.length; i++) {
                    let val = this.afterInputARR[i];
                    if (val !== undefined) title += ' - ' + item[val];
                }
            }
            return title;
        }
    };

    search = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
        const inputFocus$ = this.focus$;
        if (this.getOptionsFn) {
            return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
                switchMap((term) => this.getOptionsFn(term, this.code)),
                map((next: any) => {
                    if (next?.data) {
                        return next.data.map((item) => {
                            // if (item.amountOfConditions) {
                            //     item.amountOfConditionsDisplay = `${item.amountOfConditions} voorwaarde${
                            //         item.amountOfConditions === 1 ? '' : 'n'
                            //     }`;
                            // }
                            if (item.newsletterTypes) {
                                const stamp = this.getStampForTootltip(
                                    item.newsletterTypes?.map((subItem) => {
                                        return subItem.name;
                                    }),
                                    1
                                );

                                item.newsletterType = {
                                    type: 'arrayTooltip',
                                    subtitle:
                                        `${item.amountOfConditions} voorwaarde${
                                            item.amountOfConditions > 1 ? 'n' : ''
                                        }` +
                                        ' - ' +
                                        this.getTitleMultiValues(
                                            item.newsletterTypes?.map((subItem) => {
                                                return subItem.name;
                                            }),
                                            1
                                        ),
                                    stamp: `${stamp?.[0].value ? stamp[0].value : ''}`
                                };
                            }

                            return { ...item, title: item.name || item.code, value: item.uuid };
                        });
                    } else {
                        return next.map((item) => {
                            return { ...item, title: item.label || item.code, value: item.id };
                        });
                    }
                })
            );
        } else {
            return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
                map((term) => {
                    {
                        return this.options
                            .filter((item) => {
                                let searchValue = '';
                                this.searchValueARR.forEach((value) => {
                                    if (item[value] !== undefined && item[value] !== null) {
                                        searchValue += ` ${item[value]}`;
                                    }
                                });
                                return searchValue.toLowerCase().includes(` ${term.toLowerCase()}`);
                            })
                            .slice(0, 100);
                    }
                })
            );
        }
    };

    getTitleMultiValues(values: any[], numberValues) {
        if (values && values.length > numberValues) {
            return values.slice(0, numberValues).join(', ');
        } else {
            return values ? values.join(', ') : '';
        }
    }

    getStampForTootltip(values: any[], numberValues) {
        if (values && values.length > numberValues) {
            return [{ value: '+' + (values.length - numberValues), classList: 'stamp--small' }];
        }
    } //

    change($event) {
        if (!this.editable && !this.localModel) {
            this.modelChange.emit(undefined);
        }
    }

    onBlur($event?) {
        if (!this.localModel && !this.editable) {
            this.localModel = '';
        }
    }
}
