import { Component, input } from '@angular/core';

@Component({
    selector: 'app-history',
    templateUrl: './history.component.html',
    styleUrl: './history.component.scss'
})
export class HistoryComponent {
    createTS = input.required<string>();
    createUsername = input<string>();
    editTS = input<string>();
    editUsername = input<string>();
    format = input<string>('prettyDateTime');
}
