import { Injectable } from '@angular/core';
import { HelpersService } from './helpers.service';
import { ResourceService } from './resource.service';
import moment from 'moment';
import { AuthenticationService } from './authentication.service';
import { ProductService } from './product.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SearchService {
    searchNames: any = {};

    constructor(
        public HelpersService: HelpersService,
        public ResourceService: ResourceService,
        private ProductService: ProductService
    ) {}

    storeSearchName($event, code) {
        this.searchNames[$event.item.value] = $event.item.title;
    }

    getSearchNameById(id) {
        return this.searchNames[id];
    }

    async formatTags(SEARCHED: any, initialSEARCH: any) {
        const result = [];

        for (const key in SEARCHED) {
            const value = SEARCHED[key];
            let title;
            if (value === '' || value === null || value === undefined) {
                continue;
            }
            switch (key) {
                // no display
                case 'initialProduct':
                    continue;
                // case 'event':
                //     title = this.ResourceService.getResourceById('event_type', value).title;
                //     break;
                // case 'hasAttachment':
                case 'used':
                    title = this.ResourceService.getYesNoOption(value).title;
                    break;
                case 'type':
                    title = [
                        { title: 'Unieke code', value: 'unique' },
                        { title: 'Groepscode', value: 'group' }
                    ].find((item) => {
                        return item.value === value;
                    }).title;
                    break;
                case 'status':
                    title = [
                        { title: 'Geldig', value: 'valid' },
                        { title: 'Inactief', value: 'inactive' }
                    ].find((item) => {
                        return item.value === value;
                    }).title;
                    break;
                case 'category':
                    title = this.ResourceService.getResourceById('categories', value)['name'];
                    break;
                case 'product':
                    await lastValueFrom(this.ProductService.getProducts({ hasCost: true })).then((response: any) => {
                        title = response.data.find((item) => {
                            return item.id == value;
                        }).name;
                    });
                    break;
                case 'date':
                    title = moment(value).format('DD-MM-YYYY');
                    break;
                default:
                    title = value;
                    break;
            }
            // skip defaults
            if (initialSEARCH[key] && initialSEARCH[key] === value) {
                title = null;
            }
            if (title) {
                result.push({
                    title: this.getTagName(key),
                    value: title,
                    key: key
                });
            }
        }
        return result;
    }

    getSortingClass(code: string, SORT: any) {
        if (SORT.code == code) {
            return SORT.dir;
        } else return '';
    }

    getCleanSearch(SEARCH: any) {
        const searchParams = Object.assign({}, SEARCH);
        for (const key in searchParams) {
            const value = searchParams[key];
            if (value === null || value === undefined || value === '') {
                delete searchParams[key];
            }
            // switch (key) {
            //     case 'usage':
            //         delete searchParams[key];
            //         break;
            //     case 'list':
            //         delete searchParams[key];
            //         break;
            // }
        }
        // search
        if (this.HelpersService.objectIsEmpty(searchParams)) {
            return;
        }
        return searchParams;
    }

    getCleanSort(SORT: any, initialSort: any) {
        if (this.HelpersService.objectIsEmpty(SORT) || (SORT.code == initialSort.code && SORT.dir == initialSort.dir)) {
            return;
        } else return SORT;
    }

    getCleanPage(startRow, RPP) {
        const page = (Math.ceil(startRow / RPP) + 1).toString();
        if (page == '1') {
            return;
        } else return page;
    }

    getSort(code: string, SORT: any, dir?: string) {
        let result = SORT;
        if (SORT.code === code) {
            result.dir = SORT.dir === 'asc' ? 'desc' : 'asc';
        } else {
            result = { code: code, dir: 'asc' };
        }
        if (dir) result.dir = dir;
        return result;
    }

    removeTag(key: string, SEARCH: any, searchFn: Function) {
        const result = { ...SEARCH };
        delete result[key];
        SEARCH = result;
        searchFn(false, SEARCH);
    }

    getSearchBody(SEARCH: any, SORT: any, RPP: number, startRow: number) {
        const result = {
            ...Object.assign({}, SEARCH),
            RPP: RPP,
            startRow: startRow,
            orderBy: [`${SORT.code} ${SORT.dir}`]
        };
        return result;
    }

    getTagName(code) {
        switch (code) {
            case 'term':
                return 'Term';
            case 'used':
                return 'Gebruikt';
            case 'type':
                return 'Type';
            case 'status':
                return 'Status';
            case 'category':
                return 'Categorie';
            case 'product':
                return 'Product';
            case 'initialProduct':
                return '';
            case 'experiences':
                return 'Ervaring';
            case 'experiences_after':
                return 'Gebruik na ervaring';
            case 'gender':
                return 'Geslacht';
            case 'approved':
                return 'Status';
            case 'age':
                return 'Leeftijd';
            default:
                break;
        }

        return 'tag_' + code;
    }
}
