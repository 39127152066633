<div class="page">
    <!-- top bar -->
    <section class="top-bar">
        <!-- title -->
        <h1 class="">Upload medicijnen</h1>

        <div class="d-flex align-items-center">
            <!-- overview -->
            <a [href]="appOverviewUrl" class="d-flex align-items-center portal-link">
                <i class="im-icon im-icon-portal-dots"></i>
            </a>
            <!-- profile right -->
            <div class="profile-wrap d-flex align-items-center">
                <app-profile-section></app-profile-section>
            </div>
        </div>
    </section>
    <!-- end top bar -->

    <div class="d-flex flex-column gap-64">
        <section>
            <!-- upload zone -->
            <app-field-upload-dropzone
                [subtext]="'lijst medicijnen'"
                [allowedExtensions]="'csv'"
                [disabled]="uploadingFile"
                [loading]="uploadingFile"
                [error]="error"
                (selectFile)="selectFile($event)"
            ></app-field-upload-dropzone>
        </section>

        <section>
            <h2>Historiek bestanden</h2>
            <!-- table -->
            <div class="table-wrap">
                <app-table
                    emptyState="Geen resultaten"
                    [heads]="tableHeads" 
                    [data]="data"
                    [sort]="SORT"
                    [RPP]="RPP"
                    [startRow]="startRow"
                    [totalRows]="totalRows"
                    [loading]="loading"
                    [ready]="ready"
                    (clickData)="downloadFile($event.item)"
                    (startRowChange)="setStartRow($event)"
                ></app-table>
            </div>
        </section>
    </div>
</div>