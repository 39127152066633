<div class="editor-add-block" [class.active]="active" (click)="active = true" appClickOutside (clickOutside)="active = false;">
    <div class="add-section">
        <span></span>
        <div>Voeg sectie toe</div>
        <span></span>
    </div>
    <div class="types" *ngIf="active">
        <ng-container *ngFor="let type of types">
            <div class="type" (click)="addBlock(type.code)"><i class="icon-{{type.code}}"></i><span>{{type.name}}</span></div>
        </ng-container>
    </div>
</div>