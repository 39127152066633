import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SelectionService } from '../../../services/selection.service';

@Component({
    selector: 'app-recipients-modal',
    templateUrl: './recipients-modal.component.html',
    styleUrls: ['./recipients-modal.component.scss']
})
export class RecipientsModalComponent implements OnInit {
    @Input() selectionUuids: string[] = [];
    @Input() newsletterUuid: string = '';

    tableHeads: any[] = [
        { name: 'Email', code: 'email', sortable: false, width: '33%' },
        { name: 'Naam', code: 'subscriber', sortable: false, width: '33%' },
        { name: 'Type', code: 'function', sortable: false, width: '' }
        // { name: 'Ingeschreven op', code: 'subscribedTS', sortable: false },
    ];
    recipients: any[] = [];

    startRow: number = 0;
    RPP: number = 20;
    totalRows: number = null;
    tableLoading: boolean = false;
    term: string = '';
    amount: number = null;

    ready: boolean = false;

    constructor(private SelectionService: SelectionService, public ActiveModal: NgbActiveModal) {}

    ngOnInit(): void {
        this.getRecipients();
    }

    getRecipients(search?: boolean) {
        if (search) this.startRow = 0;
        this.tableLoading = true;
        const searchSTR = {
            startRow: this.startRow,
            RPP: this.RPP,
            selectionUuids: this.selectionUuids,
            term: this.term
        };
        this.SelectionService.getSelectionSubscriptions(searchSTR).subscribe((next: any) => {
            this.recipients = next.data.map((item) => {
                return {
                    ...item,
                    email: {
                        type: 'default',
                        color: 'black',
                        value: item.email
                    }
                };
            });
            this.totalRows = next.rows;
            this.tableLoading = false;
            if (this.amount === null) {
                this.amount = next.rows;
            }
            setTimeout(() => {
                this.ready = true;
            }, 400);
        });
    }

    changeStartRow(startRow: number) {
        this.startRow = startRow;
        this.getRecipients();
    }
}
