import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Observable, lastValueFrom } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DefaultService } from '../utils/api';
import { HelpersService } from './helpers.service';

@Injectable({
    providedIn: 'root'
})
export class SelectionService {
    @Output() selectionUuidForSubscriptionAmount = new EventEmitter();

    constructor(
        private DefaultService: DefaultService,
        private toastr: ToastrService,
        private http: HttpClient,
        public HelpersService: HelpersService
    ) {}

    public getSelections(searchSTR: any) {
        return this.DefaultService.selectionGetSelections(searchSTR);
    }

    public getSelection(uuid: string) {
        const searchSTR: any = { uuids: [uuid] };
        return this.DefaultService.selectionGetSelections(searchSTR);
    }

    public getSelectionSubscriptions({
        startRow,
        RPP,
        orderBy,
        term,
        selectionUuids,
        returnAmount,
        showAll,
        filterJSON,
        newsletterUuid
    }: any) {
        return this.DefaultService.selectionGetSelectionSubscriptions(
            selectionUuids,
            startRow,
            RPP,
            orderBy,
            term,
            returnAmount,
            showAll,
            filterJSON,
            newsletterUuid
        );
    }

    public deleteSelection(uuid: string) {
        return this.DefaultService.selectionDeleteSelectionByUuid(uuid).pipe(
            tap((next) => {
                this.toastr.success('De selectie werd succesvol verwijderd', 'Selectie verwijderd');
            })
        );
    }

    public toggleBlockList(selectionUuid: string, relationId: number, inBlocklist: boolean) {
        return this.DefaultService.selectionToggleBlocklist(selectionUuid, relationId).pipe(
            tap((next) => {
                if (inBlocklist) {
                    this.toastr.success('De gebruiker werd succesvol verwijderd', 'Gebruiker verwijderd');
                } else {
                    this.toastr.success(
                        'De gebruiker werd opnieuw toegevoegd aan de lijst',
                        'Verwijderen ongedaan gemaakt'
                    );
                }
            })
        );
    }

    public updateInsertSelection(body: any, uuid?: string) {
        return this.DefaultService.selectionUpdateOrInsertSelection(body).pipe(
            tap((next) => {
                if (uuid) {
                    this.toastr.success('Selectie werd succesvol opgeslagen', 'Selectie opgeslagen');
                } else {
                    this.toastr.success('Selectie werd succesvol aangemaakt', 'Selectie aangemaakt');
                }
            })
        );
    }

    public calculateSubscriptionsAmount(filterJSON: string, selectionUuid?: string) {
        const searchSTR = {
            filterJSON: filterJSON,
            returnAmount: true,
            showAll: false
        };
        if (selectionUuid) {
            searchSTR['selectionUuids'] = [selectionUuid];
        }
        return lastValueFrom(this.getSelectionSubscriptions(searchSTR)).then((item: any) => {
            return item.data[0].amount;
        });
    }

    setSelectionUuidForSubscriptionAmount(uuid) {
        this.selectionUuidForSubscriptionAmount.emit(uuid);
    }

    getSelectionUuidForSubscriptionAmount() {
        return this.selectionUuidForSubscriptionAmount;
    }
}
