import { Injectable } from '@angular/core';
import { DefaultService } from '../utils/api';
import { ToastrService } from 'ngx-toastr';
import { tap } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class NewsitemServiceService {
    constructor(private DefaultService: DefaultService, private toastr: ToastrService) {}

    public getNewsitem(id: number) {
        return this.DefaultService.newsitemGetNewsitem(id);
    }

    public updateInsertNewsitem(body: any, id?: number) {
        return this.DefaultService.newsitemUpdateOrInsertNewsitem(body).pipe(
            tap((next) => {
                if (id) {
                    this.toastr.success('Nieuwsitem werd succesvol opgeslagen', 'Nieuwsitem opgeslagen');
                } else {
                    this.toastr.success('Nieuwsitem werd succesvol aangemaakt', 'Nieuwsitem aangemaakt');
                }
            })
        );
    }

    public uploadImage(image: any, id: number) {
        return this.DefaultService.newsitemsUploadImage(image, id);
    }

    public deleteImage(id: number) {
        return this.DefaultService.newsitemsDeleteImage(id);
    }

    public deleteNewsitem(id: number) {
        return this.DefaultService.newsitemDeleteNewsitem(id).pipe(
            tap((next) => {
                this.toastr.success('Nieuwsitem werd succesvol verwijderd', 'Nieuwsitem verwijderd');
            })
        );
    }
}
