<span class="close-modal-outside"><i (click)="ActiveModal.dismiss()" class="im-icon im-icon-x-light"></i></span>
<div class="modal-wrap">
    <ng-container *ngIf="!ready">
        <app-spinner [centerVertically]="true"></app-spinner>
    </ng-container>
    <div class="d-flex">

        <!-- Start form -->
        <div class="d-flex flex-column w-100 flex-grow-1 scroll-y">
            <ng-container *ngIf="loading">
                <div style="height: 100%;">
                    <app-spinner [centerVertically]="true"></app-spinner>
                </div>
            </ng-container>
            <ng-container *ngIf="!loading && ready">
                <div class="">
                    <!-- header -->
                    <div class="detail-modal-header d-flex flex-row justify-content-between align-items-center" style="min-height: 36px;">
                        <h1 style="min-height: 36px;">{{id ? formSTR.title : 'Nieuwe subpagina'}}</h1>
                    </div>
                </div>

                <div class="detail-modal-body">
                    <!-- content -->
                    <div class="row display-row mw-1200-px">
                        <div class="col-xl-4">
                            <!-- Title -->
                            <div class="display-content">
                                <app-field-text
                                    [model]="formSTR.title" 
                                    (modelChange)="formSTR.title = $event; formChange();" 
                                    label="Titel"
                                    [error]="validation.title"
                                    placeholder="Titel">
                                </app-field-text>
                            </div>
                        </div>
                    </div>
                    <!-- Inhoud -->
                    <div class="row display-row mw-1200-px">
                        <div class="col-md-8">
                            <div class="display-content">
                                    <app-field-richtext 
                                        [model]="formSTR.content" 
                                        (modelChange)="formSTR.content = $event; formChange();" 
                                        [label]="'Inhoud' | display"
                                        [expanded]="true"
                                        [error]="validation.content">
                                    </app-field-richtext>
                            </div>
                        </div>
                    </div>
                    <div class="row display-row mw-1200-px">
                        <div class="col-md-3">
                            <div class="display-content">
                                <app-field-date 
                                    [model]="formSTR.editPageTS" 
                                    (modelChange)="formSTR.editPageTS = $event; formChange();" 
                                    label="Datum laatste wijziging"
                                    placeholder="dd-mm-yyyy"
                                    [error]="validation.editPageTS">
                                </app-field-date>
                            </div>
                        </div>
                    </div>
                    <div class="row display-row mw-1200-px">
                        <div class="col-md-2">
                            <!-- published -->
                            <div class="display-content">
                                <app-field-radios 
                                    class="d-block"
                                    label="Gepubliceerd"
                                    [model]="formSTR.isPublished"
                                    (modelChange)="formSTR.isPublished = $event"
                                    [error]="validation.isPublished"
                                    optionalString=""
                                    [options]="[{title: 'Ja', value: 1},{title: 'Nee', value: 2}]"
                                    [optionWidth]="80"
                                    [optionColumnWidth]="">
                                </app-field-radios>
                            </div>
                        </div>
                    </div>
                    <div class="button-section-bottom">
                        <div class="h-100 d-flex align-items-center">
                            <app-button classes="primary-default" icon="check-round-filled" text="Bewaren" [disabled]="saving" [submitting]="submitting" (emitClick)="submit()"></app-button>
                            <button class="add-cancel" (click)="canExit() && (id ? activeSection = null : ActiveModal.dismiss()); ActiveModal.dismiss(); resetForm()" [class.disabled]="saving"><span>Annuleren</span></button>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>