import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { EditorService } from '../../../../services/editor.service';
import Quill from 'quill';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-editor-block-text',
    templateUrl: './editor-block-text.component.html',
    styleUrls: ['./editor-block-text.component.scss']
})
export class EditorBlockTextComponent implements OnInit, OnChanges {
    @Input() model: any;
    @Input() active: boolean = false;
    @Output() modelChange = new EventEmitter();
    @Output() setActive = new EventEmitter();
    @Output() setInactive = new EventEmitter();
    @Output() setActiveModal = new EventEmitter();

    modules = {};
    editor: Quill = null;

    constructor(private EditorService: EditorService, public sanitizer: DomSanitizer, private ModalService: NgbModal) {}

    ngOnInit(): void {
        this.modules = this.EditorService.getQuillModules();
    }

    openLinkModal() {
        this.EditorService.openLinkModal(this.editor, this.setActiveModal);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.active && changes.active.currentValue) {
            this.focusEditor();
        }
    }

    onChange($event) {
        this.modelChange.emit($event.html || '');
    }

    editorCreated($event) {
        this.editor = $event;
        this.focusEditor();
    }

    focusEditor() {
        setTimeout(() => {
            this.active && this?.editor.focus();
        }, 1);
    }

    emitActive() {
        if (this.active) return;
        this.setActive.emit();
    }

    emitInactive() {
        if (!this.active) return;
        this.active && this.setInactive.emit();
    }
}
