<div>
    <h1 style="padding-left: 5px;">{{'Voeg video toe'}}</h1>

    <ng-container *ngIf="!ready">
        <app-spinner [centerVertically]="true"></app-spinner>
    </ng-container>

    <!-- url -->
    <div class="row display-row mb-s" style="padding-left: 5px;padding-right: 5px;">
        <div class="col-12">
            <div class="display-content">
                <app-field-text 
                    [model]="url" 
                    (modelChange)="url = $event;" 
                    (enter)="canSubmit() && submit()"
                    [instant]="true"
                    label="Video link"
                    placeholder="www.voorbeeld.com/video">
                </app-field-text>
            </div>
        </div>
    </div>

    <!-- image -->
    <div class="field d-inline-block">

        <div class="label" style="padding-left: 5px;padding-right: 5px;">Afbeelding</div>

        <!-- crop -->
        <div *ngIf="localFile" style="min-height: 275px;">
            <image-cropper
                class="opacity-hide"
                [class.opacity-show]="ready"
                [imageChangedEvent]="false"
                [maintainAspectRatio]="true"
                [aspectRatio]="aspectRatio"
                [resizeToWidth]="resizeToWidth"
                [imageFile]="localFile"
                format="png"
output="base64"
                (imageCropped)="imageCropped($event)"
                (imageLoaded)="false"
                (cropperReady)="cropperReady()"
                (loadImageFailed)="false"
            ></image-cropper>
        </div>

        <!-- preview -->
        <div *ngIf="!localFile" class="d-flex position-relative" style="margin-left: 5px;margin-right: 5px;">
            <!-- placeholder -->
            <div class="editor-image-placeholder" *ngIf="!hasFile()" style="border-radius: 3px;border-bottom-left-radius: 0;border-bottom-right-radius: 0;" ngfSelect [(file)]="localFile" (filesChange)="fileChange()" accept="image/*">
                <img src="/assets/img/editor/block-image-placeholder--wide.svg" style="width: 602px; height: auto;" draggable="false">
                <span>Voeg afbeelding toe</span>
            </div>
            <!-- preview -->
            <img class="editor-image-preview" style="width: 602px; height: auto; float: left; border-radius: 3px;border-bottom-left-radius: 0;border-bottom-right-radius: 0;"
                draggable="false"
                *ngIf="draftFile" 
                [ngfSrc]="draftFile">
            <!-- blob urls -->
            <img class="editor-image-preview" style="width: 602px; height: auto; float: left; border-radius: 3px;border-bottom-left-radius: 0;border-bottom-right-radius: 0;"
                draggable="false"
                *ngIf="file"
                [src]="file.blobUrl">

            <div *ngIf="hasFile()" class="editor-image-overlay d-flex align-items-center justify-content-center">
                <span ngfSelect [(file)]="localFile" (filesChange)="fileChange()" accept="image/*"><i class="icon icon-image-edit"></i></span>
            </div>
        </div>

    </div>

    <div *ngIf="true" class="d-flex align-items-center mt-s"  style="padding-left: 5px;">
        <!-- submit -->
        <button class="button default" (click)="canSubmit() && submit()" [class.disabled]="!canSubmit()">Bewaar</button>
        <!-- cancel -->
        <button class="button-link blue cancel ml-m" (click)="ActiveModal.dismiss()" [class.disabled]="false"><span>Annuleer</span></button>
    </div>

</div>