<div class="field field-date {{classes}}" [ngStyle]="{'width': width ? width : ''}">
    <span *ngIf="label" class="label" [class.disabled]="disabledLabel">{{label}}<span *ngIf="optionalString">({{optionalString}})</span><app-tooltip-label *ngIf="tooltipObj" [tooltipObj]="tooltipObj"></app-tooltip-label></span>
    <div class="w-100 position-relative" *ngIf="ready">
        <input
            [ngStyle]="{'width': width ? width : ''}"
            #myinput
            [class.error-field]="error"
            [placeholder]="placeholder" 
            (calendarToggle)="calendarToggle($event)"
            (blur)="onBlur()"
            (focus)="onFocus()"
            (dateSelect)="onDateChanged($event)"
            [(ngModel)]="localModel" 
            [autocomplete]="autocomplete"
            [class.disabled]="disabled"
            [disabled]="disabled"
            ngbDatepicker
            (click)="!disabled ? d.toggle() : ''"
            [minDate]="localDisableUntil"
            [maxDate]="localDisableSince"
            #d="ngbDatepicker"/>
    </div>
    <span *ngIf="error" class="error-field-display">{{error}}</span>
</div>