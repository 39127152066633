import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

export const mimeToExt = {
    'audio/x-mpeg': 'mpega',
    'application/postscript': 'ps',
    'audio/x-aiff': 'aiff',
    'application/x-aim': 'aim',
    'image/x-jg': 'art',
    'video/x-ms-asf': 'asx',
    'audio/basic': 'ulw',
    'video/x-msvideo': 'avi',
    'video/x-rad-screenplay': 'avx',
    'application/x-bcpio': 'bcpio',
    'application/octet-stream': 'exe',
    'image/bmp': 'dib',
    'text/html': 'html',
    'application/x-cdf': 'cdf',
    'application/pkix-cert': 'cer',
    'application/java': 'class',
    'application/x-cpio': 'cpio',
    'application/x-csh': 'csh',
    'text/css': 'css',
    'application/msword': 'doc',
    'application/xml-dtd': 'dtd',
    'video/x-dv': 'dv',
    'application/x-dvi': 'dvi',
    'application/vnd.ms-fontobject': 'eot',
    'text/x-setext': 'etx',
    'image/gif': 'gif',
    'application/x-gtar': 'gtar',
    'application/x-gzip': 'gz',
    'application/x-hdf': 'hdf',
    'application/mac-binhex40': 'hqx',
    'text/x-component': 'htc',
    'image/ief': 'ief',
    'text/vnd.sun.j2me.app-descriptor': 'jad',
    'application/java-archive': 'jar',
    'text/x-java-source': 'java',
    'application/x-java-jnlp-file': 'jnlp',
    'image/jpeg': 'jpg',
    'application/javascript': 'js',
    'text/plain': 'txt',
    'application/json': 'json',
    'audio/midi': 'midi',
    'application/x-latex': 'latex',
    'audio/x-mpegurl': 'm3u',
    'image/x-macpaint': 'pnt',
    'text/troff': 'tr',
    'application/mathml+xml': 'mathml',
    'application/x-mif': 'mif',
    'video/quicktime': 'qt',
    'video/x-sgi-movie': 'movie',
    'audio/mpeg': 'mpa',
    'video/mp4': 'mp4',
    'video/mpeg': 'mpg',
    'video/mpeg2': 'mpv2',
    'application/x-wais-source': 'src',
    'application/x-netcdf': 'nc',
    'application/oda': 'oda',
    'application/vnd.oasis.opendocument.database': 'odb',
    'application/vnd.oasis.opendocument.chart': 'odc',
    'application/vnd.oasis.opendocument.formula': 'odf',
    'application/vnd.oasis.opendocument.graphics': 'odg',
    'application/vnd.oasis.opendocument.image': 'odi',
    'application/vnd.oasis.opendocument.text-master': 'odm',
    'application/vnd.oasis.opendocument.presentation': 'odp',
    'application/vnd.oasis.opendocument.spreadsheet': 'ods',
    'application/vnd.oasis.opendocument.text': 'odt',
    'application/vnd.oasis.opendocument.graphics-template': 'otg',
    'application/vnd.oasis.opendocument.text-web': 'oth',
    'application/vnd.oasis.opendocument.presentation-template': 'otp',
    'application/vnd.oasis.opendocument.spreadsheet-template': 'ots',
    'application/vnd.oasis.opendocument.text-template': 'ott',
    'application/ogg': 'ogx',
    'video/ogg': 'ogv',
    'audio/ogg': 'spx',
    'application/x-font-opentype': 'otf',
    'audio/flac': 'flac',
    'application/annodex': 'anx',
    'audio/annodex': 'axa',
    'video/annodex': 'axv',
    'application/xspf+xml': 'xspf',
    'image/x-portable-bitmap': 'pbm',
    'image/pict': 'pict',
    'application/pdf': 'pdf',
    'image/x-portable-graymap': 'pgm',
    'audio/x-scpls': 'pls',
    'image/png': 'png',
    'image/x-portable-anymap': 'pnm',
    'image/x-portable-pixmap': 'ppm',
    'application/vnd.ms-powerpoint': 'pps',
    'image/vnd.adobe.photoshop': 'psd',
    'image/x-quicktime': 'qtif',
    'image/x-cmu-raster': 'ras',
    'application/rdf+xml': 'rdf',
    'image/x-rgb': 'rgb',
    'application/vnd.rn-realmedia': 'rm',
    'application/rtf': 'rtf',
    'text/richtext': 'rtx',
    'application/font-sfnt': 'sfnt',
    'application/x-sh': 'sh',
    'application/x-shar': 'shar',
    'application/x-stuffit': 'sit',
    'application/x-sv4cpio': 'sv4cpio',
    'application/x-sv4crc': 'sv4crc',
    'image/svg+xml': 'svgz',
    'application/x-shockwave-flash': 'swf',
    'application/x-tar': 'tar',
    'application/x-tcl': 'tcl',
    'application/x-tex': 'tex',
    'application/x-texinfo': 'texinfo',
    'image/tiff': 'tiff',
    'text/tab-separated-values': 'tsv',
    'application/x-font-ttf': 'ttf',
    'application/x-ustar': 'ustar',
    'application/voicexml+xml': 'vxml',
    'image/x-xbitmap': 'xbm',
    'application/xhtml+xml': 'xhtml',
    'application/vnd.ms-excel': 'xls',
    'application/xml': 'xsl',
    'image/x-xpixmap': 'xpm',
    'application/xslt+xml': 'xslt',
    'application/vnd.mozilla.xul+xml': 'xul',
    'image/x-xwindowdump': 'xwd',
    'application/vnd.visio': 'vsd',
    'audio/x-wav': 'wav',
    'image/vnd.wap.wbmp': 'wbmp',
    'text/vnd.wap.wml': 'wml',
    'application/vnd.wap.wmlc': 'wmlc',
    'text/vnd.wap.wmlsc': 'wmls',
    'application/vnd.wap.wmlscriptc': 'wmlscriptc',
    'video/x-ms-wmv': 'wmv',
    'application/font-woff': 'woff',
    'application/font-woff2': 'woff2',
    'model/vrml': 'wrl',
    'application/wspolicy+xml': 'wspolicy',
    'application/x-compress': 'z',
    'application/zip': 'zip'
};

@Injectable({
    providedIn: 'root'
})
export class HelpersService {
    params: any;
    constructor(private route: ActivatedRoute, private router: Router, private toastr: ToastrService) {
        route.queryParams.subscribe((next: any) => {
            this.params = next;
        });
    }

    public titleCaseWord(word: string) {
        if (!word) return word;
        return word[0].toUpperCase() + word.substr(1).toLowerCase();
    }

    public getParam(param: string): string {
        if (!param || !this.params[param]) {
            return '';
        } else return this.params[param];
    }
    public addParam(param: string, value: string) {
        if (!param) {
            return;
        } else {
            this.router.navigate([], {
                queryParams: {
                    [param]: value
                },
                queryParamsHandling: 'merge'
            });
        }
    }
    public removeParam(param: string) {
        if (!param) {
            return;
        } else {
            this.router.navigate([], {
                queryParams: {
                    [param]: null
                },
                queryParamsHandling: 'merge'
            });
        }
    }
    // object is empty or only empty keys (null | undefined | '')
    public objectIsEmpty(object: any) {
        if (object.constructor != Object) return true;
        for (const key in object) {
            if (Object.prototype.hasOwnProperty.call(object, key)) {
                if (object[key] == null || object[key] == undefined || object[key] == '') {
                    continue;
                } else return false;
            }
        }
        return true;
    }
    public downloadBlob(blob: Blob, filename: string) {
        let a = document.createElement('a');
        document.body.appendChild(a);
        let blobUrl = window.URL.createObjectURL(blob);
        a.href = blobUrl;
        a.download = filename;
        a.click();
        setTimeout(function () {
            window.URL.revokeObjectURL(blobUrl);
            document.body.removeChild(a);
        }, 0);
    }

    public downloadLink(link: string) {
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.href = link;
        a.setAttribute('download', null);
        a.click();
        setTimeout(function () {
            document.body.removeChild(a);
        }, 0);
    }

    public copyToClipboard(val: string, successMessage: string) {
        if (!val) {
            return;
        }
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = val;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        if (successMessage) {
            this.toastr.info('', successMessage);
        }
    }

    public fillHttp(url) {
        if (!/^(?:f|ht)tps?\:\/\//.test(url)) {
            url = 'http://' + url;
        }
        return url;
    }

    public getUUID(parts?: number): string {
        parts = parts || 4;
        const stringArr = [];
        for (let i = 0; i < parts; i++) {
            // tslint:disable-next-line:no-bitwise
            const S4 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
            stringArr.push(S4);
        }
        return stringArr.join('-');
    }

    public getImageWidth(file: File): Promise<number> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            const image = new Image();

            reader.onload = (event: any) => {
                image.src = event.target.result;

                // Once the image is loaded, resolve the width
                image.onload = () => {
                    resolve(image.width);
                };

                image.onerror = (error) => {
                    reject(`Error loading image: ${error}`);
                };
            };

            reader.onerror = (error) => {
                reject(`Error reading file: ${error}`);
            };

            reader.readAsDataURL(file); // Read the file as a Data URL
        });
    }
}
