import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/services/config.service';

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
    collapsed: boolean = false;
    collapsedAnimationDone: boolean = true;
    rijveilig: boolean = false;

    appCode: string;
    constructor(public ConfigService: ConfigService) {}

    ngOnInit(): void {
        if (localStorage.getItem('navigation_is_collapsed') === 'yes') {
            this.collapsed = true;
        } else this.collapsed = false;
        this.toggleRijveilig();
        this.appCode = this.ConfigService.getConfig().appCode;
    }

    toggleCollapsed() {
        this.collapsed = !this.collapsed;
        this.collapsedAnimationDone = false;
        localStorage.setItem('navigation_is_collapsed', this.collapsed ? 'yes' : 'no');
        setTimeout(() => {
            this.collapsedAnimationDone = true;
        }, 180);
    }

    toggleRijveilig() {
        if (this.ConfigService.getConfig().appCode === 'rijveilig') {
            this.rijveilig = true;
        }
    }
}
