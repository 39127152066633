@if (!ready()) {
    <app-spinner [centerVertically]="true"></app-spinner>
}
@else {

    <div class="page">
        <!-- top bar -->
        <section class="top-bar">
            <!-- title -->
            <h1 class="">
                Meldingen 
                <span *ngIf="ready()" [class.saving]="loading()" class="saving--pre">({{totalRows}})</span>
            </h1>

            <div class="d-flex align-items-center">
                <!-- overview -->
                <a [href]="appOverviewUrl" class="d-flex align-items-center portal-link">
                    <i class="im-icon im-icon-portal-dots"></i>
                </a>
                <!-- profile right -->
                <div class="profile-wrap d-flex align-items-center">
                    <app-profile-section></app-profile-section>
                </div>
            </div>
        </section>
        <!-- end top bar -->

        <div class="top-bar d-block">
            <!-- search left -->
            <div class="search-wrap  d-flex align-center justify-content-between">
                <div class="d-flex align-items-center gap-8">
                    <div class="search-bar-wrap">
                        <!-- search bar -->
                        <app-search-input
                            [model]="SEARCH?.term" 
                            (modelChange)="SEARCH.term = $event" 
                            [placeholder]="'In meldingen zoeken...'"
                            maxWidth="240"
                            (enter)="search()"
                            (clear)="SEARCH.term = undefined; search()"
                        ></app-search-input>
                    </div>

                    <!-- ervaringen filter -->
                    <app-search-multiselect-icon
                        [options]="experiences"
                        [model]="SEARCH?.experiences"
                        (modelChange)="SEARCH.experiences = $event; search();"
                        [allowClear]="true"
                        [placeholder]="'Ervaring'"
                    ></app-search-multiselect-icon>

                    <!-- ervaring nadien -->
                    <app-search-multiselect
                        [options]="experiences_after"
                        [model]="SEARCH?.experiences_after"
                        (modelChange)="SEARCH.experiences_after = $event; search();"
                        [allowClear]="true"
                        [placeholder]="'Gebruik na ervaring'"
                    ></app-search-multiselect>

                    <!-- geslacht -->
                    <app-search-multiselect
                        [options]="gender"
                        [model]="SEARCH?.gender"
                        (modelChange)="SEARCH.gender = $event; search();"
                        [allowClear]="true"
                        [placeholder]="'Geslacht'"
                    ></app-search-multiselect>

                    <!-- leeftijd -->
                    <app-search-age
                        [codeOptions]="age_codes"
                        [code]="SEARCH.age?.age_code"
                        [from]="SEARCH.age?.age_from"
                        [until]="SEARCH.age?.age_until"
                        (emitData)="setAgePeriod($event); "
                        (emitClear)="SEARCH.age = undefined; search();"
                        (search)="search();"
                        [allowClear]="true"
                        [placeholder]="'Leeftijd'"
                    ></app-search-age>

                    <!-- status -->
                    <app-search-radios
                        [options]="status"
                        [model]="SEARCH?.approved?.length ? SEARCH?.approved?.[0] : undefined"
                        [placeholder]="'Status'"
                        allowClear="true"
                        (modelChange)="SEARCH.approved = $event ? [$event] : []; search();"
                    ></app-search-radios>
                     
                    @if (SEARCHED.length) {
                        @if (SEARCHED.length === 1) {
                            @if (SEARCH?.approved && SEARCH?.approved?.[0] !== 'active') {
                                <button (click)="search(true)" class="button-link underline pt-xxs pb-xxs">Herstellen</button>
                            }
                        } @else {
                            <button (click)="search(true)" class="button-link underline pt-xxs pb-xxs">Herstellen</button>
                        }
                    }

                   
                </div>

                <button (click)="export()" [class.disabled]="processingExport" class="button-link fontsize-s export-btn">
                    @if (processingExport) {
                        <app-inline-spinner class="mr-xxxs"></app-inline-spinner>
                    } @else {
                        <i class="im-icon im-icon-download"></i>
                    }
                    <span>
                        {{processingExport ? 'Lijst aan het exporteren...' : 'Exporteer deze lijst'}}
                    </span>
                </button>
                
            </div>
        </div>

        <!-- table -->
        <div class="table-wrap">
            <app-table
                [emptyState]="'Geen resultaten'"
                [heads]="tableHeads" 
                [data]="data" 
                [sort]="SORT"
                [RPP]="RPP"
                [startRow]="startRow"
                [totalRows]="totalRows"
                [loading]="loading()"
                [ready]="ready()"
                (sortChange)="setSort($event.code, $event.dir)"
                (clickData)="openDetail($event.item.id)"
                (clickAction)="actionClick($event.item, $event.action)"
                (startRowChange)="setStartRow($event)"
            ></app-table>
        </div>
    </div>
}