import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';

@Component({
    selector: 'app-field-typeahead-small',
    templateUrl: './field-typeahead-small.component.html',
    styleUrls: ['./field-typeahead-small.component.scss']
})
export class FieldTypeaheadSmallComponent implements OnInit, OnChanges {
    @Input() label?: string;
    @Input() error?: string;
    @Input() optionalString?: string;
    @Input() placeholder?: string;
    @Input() editable?: boolean;
    @Input() initialModel: string;
    @Input() options: { key: any; value: string }[] | any[];
    @Input() searchKeyARR?: any[] = [];
    @Input() afterARR?: any[] = [];
    @Input() afterInputARR?: any[] = [];
    @Input() index?: number = 0;
    @Input() detectModelChange?: any;
    @Output() modelChange = new EventEmitter();
    @Output() enter = new EventEmitter();
    model: any;
    // searchString: string = '';

    constructor() {}

    ngOnInit() {
        this.updateModel();
        if (!this.searchKeyARR || !this.searchKeyARR.length) {
            this.searchKeyARR = ['value'];
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        this.updateModel();
    }

    updateModel() {
        if (this.initialModel) {
            this.model = this.initialModel;
        } else {
            this.model = '';
        }
    }

    onSelect($event) {
        if ($event) {
            this.modelChange.emit($event);
        }
    }

    formatter = (item: { key: any; value: string }) => {
        if (item.value) {
            let value = item.value;

            if (this.afterInputARR.length) {
                for (let i = 0; i < this.afterInputARR.length; i++) {
                    let val = this.afterInputARR[i];
                    if (val !== undefined) value += ' - ' + item[val];
                }
            }
            return value;
        }
    };

    search = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            filter((term) => term.length >= 1),
            map((term) =>
                this.options
                    .filter((item) => {
                        let search = '';
                        this.searchKeyARR.forEach((key) => {
                            if (item[key] !== undefined) {
                                search += ` ${item[key]}`;
                            }
                        });
                        return new RegExp(term, 'mi').test(search);
                    })
                    .slice(0, 100)
            )
        );

    change($event) {
        if (!this.editable && !this.model) {
            this.modelChange.emit(undefined);
        }
    }

    onBlur($event?) {
        if (!this.model && !this.editable) {
            this.model = '';
        }
    }
}
