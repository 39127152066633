import { Injectable } from '@angular/core';
import { DefaultService } from '../utils/api';
import { ToastrService } from 'ngx-toastr';
import { tap } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TipsService {
    constructor(private DefaultService: DefaultService, private toastr: ToastrService) {}

    public getTip(id: number) {
        return this.DefaultService.tipGetTip(id);
    }

    public updateInsertTip(body: any, id?: number) {
        return this.DefaultService.tipsUpdateOrInsertTip(body).pipe(
            tap((next) => {
                if (id) {
                    this.toastr.success('Tip werd succesvol opgeslagen', 'Tip opgeslagen');
                } else {
                    this.toastr.success('Tip werd succesvol aangemaakt', 'Tip aangemaakt');
                }
            })
        );
    }

    public deleteTip(id: number) {
        return this.DefaultService.tipsDeleteTip(id).pipe(
            tap((next) => {
                this.toastr.success('Tip werd succesvol verwijderd', 'Tip verwijderd');
            })
        );
    }
}
