<div class="page">

    <!-- top bar -->
    <section class="top-bar">
        <!-- search left -->
        <h1 class="">Dashboard</h1>
        <div class="d-flex align-items-center">
            <!-- overview -->
            <a href="{{this.ConfigService.getConfig().appOverviewUrl}}" class="d-flex align-items-center portal-link">
                <i class="im-icon im-icon-portal-dots"></i>
            </a>
            <!-- profile right -->
            <div class="profile-wrap d-flex align-items-center">
                <app-profile-section></app-profile-section>
            </div>
        </div>
    </section>
    <!-- end top bar -->

    <!-- <ng-container *ngIf="!ready">
        <app-spinner [centerVertically]="true"></app-spinner>
    </ng-container> -->

    <ng-container *ngIf="ready || true">
        <div class="content-wrap d-flex flex-column">
            <div class="content-row">
                <div class="left" *ngIf="newsletters?.length">
                    <section>
                        <!-- recent newsletters -->
                        <div class="dashboard-title">
                            <div class="icon-wrap">
                                <i class="im-icon im-icon-mail title-icon"></i>
                                <div class="prev-next-wrap" *ngIf="helper.newslettersReady">
                                    <i class="im-icon im-icon-chev-left" (click)="helper.newsletterIndex = (helper.newsletterIndex - 1)" [class.inactive]="helper.newsletterIndex === 0"></i>
                                    <span>{{helper.newsletterIndex + 1}} van {{newsletters.length}}</span>
                                    <i class="im-icon im-icon-chev-right" (click)="helper.newsletterIndex = (helper.newsletterIndex + 1)" [class.inactive]="helper.newsletterIndex === (newsletters.length - 1)"></i>
                                </div>
                            </div>
                            <h2>Recente nieuwsbrieven</h2>
                        </div>
                        <div class="recent-wrap">
                            <div class="title"><div class="shimmer" style="width: 100px; height: 14px;" *ngIf="!helper.newslettersReady"></div><span *ngIf="helper.newslettersReady">{{newsletters[helper.newsletterIndex].name}}</span></div>
                            <div class="descr"><div class="shimmer" style="width: 240px; height: 10px;" *ngIf="!helper.newslettersReady"></div><ng-container *ngIf="helper.newslettersReady">Verstuurd op <span>{{newsletters[helper.newsletterIndex].sendTS | formatTs : 'DD MMMM YYYY'}}</span> om {{newsletters[helper.newsletterIndex].sendTS | formatTs : 'HH:mm'}}. <a [routerLink]="['/nieuwsbrieven']" [queryParams]="{newsletter: newsletters[helper.newsletterIndex].uuid}">Ga naar nieuwsbrief</a>.</ng-container></div>
                            <div class="range-chart" *ngIf="helper.newslettersReady">
                                <div class="range" style="--pLength:{{((newsletters[helper.newsletterIndex].opened / newsletters[helper.newsletterIndex].sent) || 0) * 100}};--rotate:{{0}}deg"></div>
                                <div class="range-placeholder"></div>
                                <div class="info-wrap">
                                    <span class="amount">{{newsletters[helper.newsletterIndex].opened}}</span>
                                    <span class="total">/{{newsletters[helper.newsletterIndex].sent}}</span>
                                    <span class="descr">geopend</span>
                                </div>
                            </div>
                            <div class="shimmer" style="width: 240px; height: 240px; border-radius: 100%; margin-left: auto; margin-right: auto;" *ngIf="!helper.newslettersReady"></div>
                            <div class="sep"></div>
                            <app-button classes="primary-default" icon="plus-round" text="Nieuwe nieuwsbrief" [disabled]="false" [submitting]="false" (emitClick)="goToNew()"></app-button>
                        </div>
                    </section>
                </div>
                <div class="right flex-grow-1">
                    <section>
                        <!-- subscribers -->
                        <div class="dashboard-title">
                            <div class="icon-wrap">
                                <i class="im-icon im-icon-contract title-icon"></i>
                            </div>
                            <h2>Abonnees laatste 30 dagen</h2>
                        </div>
                        <div class="subscribers-wrap tiles">
                            <ng-container *ngIf="!helper.figuresReady">
                                <div class="shimmer" style="width: 210px; height: 106px;"></div>
                                <div class="shimmer" style="width: 210px; height: 106px;"></div>
                                <div class="shimmer" style="width: 210px; height: 106px;"></div>
                            </ng-container>
                            <ng-container *ngIf="helper.figuresReady">
                                <div class="tile">
                                    <div class="title">Totaal abonnees</div>
                                    <div class="value-wrap">
                                        <div class="value">{{figures.subscribers | floatDisplay : 'thousandSeparator'}}</div>
                                        <!-- <div class="percentage">+3.2%</div> -->
                                    </div>
                                </div>
                                <div class="tile">
                                    <div class="title">Ingeschreven</div>
                                    <div class="value-wrap">
                                        <div class="value">{{figures.subscribed | floatDisplay : 'thousandSeparator'}}</div>
                                        <!-- <div class="percentage">+8.2%</div> -->
                                    </div>
                                </div>
                                <div class="tile">
                                    <div class="title">Uitgeschreven</div>
                                    <div class="value-wrap">
                                        <div class="value value--neg">{{figures.unsubscribed | floatDisplay : 'thousandSeparator'}}</div>
                                        <!-- <div class="percentage percentage--neg">+8.2%</div> -->
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </section>
                    <section>
                        <!-- performance -->
                        <div class="dashboard-title">
                            <div class="icon-wrap">
                                <i class="im-icon im-icon-clicks title-icon"></i>
                            </div>
                            <h2>Prestaties laatste 30 dagen</h2>
                        </div>
                        <div class="subscribers-wrap tiles">
                            <ng-container *ngIf="!helper.figuresReady">
                                <div class="shimmer" style="width: 210px; height: 106px;"></div>
                                <div class="shimmer" style="width: 210px; height: 106px;"></div>
                                <div class="shimmer" style="width: 210px; height: 106px;"></div>
                            </ng-container>
                            <ng-container *ngIf="helper.figuresReady">
                                <div class="tile">
                                    <div class="title">Verzonden</div>
                                    <div class="value-wrap">
                                        <div class="value">{{figures.sent | floatDisplay : 'thousandSeparator'}}</div>
                                        <!-- <div class="percentage">+0.2%</div> -->
                                    </div>
                                </div>
                                <div class="tile">
                                    <div class="title">Geopend</div>
                                    <div class="value-wrap">
                                        <div class="value">{{figures.opened | floatDisplay : 'thousandSeparator'}}</div>
                                        <!-- <div class="percentage">+8.2%</div> -->
                                    </div>
                                </div>
                                <div class="tile">
                                    <div class="title">Geklikt</div>
                                    <div class="value-wrap">
                                        <div class="value">{{figures.clicked | floatDisplay : 'thousandSeparator'}}</div>
                                        <!-- <div class="percentage percentage--neg">-1.1%</div> -->
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </section>
                </div>
            </div>
            <div class="content-row">
                <section>
                    <!-- enrollment -->
                    <div class="dashboard-title">
                        <div class="icon-wrap">
                            <i class="im-icon im-icon-stats title-icon"></i>
                        </div>
                        <h2>In- / uitschrijvingen</h2>
                    </div>
                    <div class="enrollment-wrap">
                        <div class="chart-legend-wrap" *ngIf="helper.subscribersReady">
                            <app-field-select 
                                [model]="newsletterType" 
                                [options]="newsletterTypes"
                                (modelChange)="changeNewsletterType($event)" 
                                [placeholder]="'Alle nieuwsbrieven'"
                            ></app-field-select>
                            <div class="legend-wrap">
                                <div class="legend-item">
                                    <i class="green"></i>
                                    <span>Nieuwe inschrijvingen</span>
                                </div>
                                <div class="legend-item">
                                    <i class="red"></i>
                                    <span>Uitschrijvingen</span>
                                </div>
                            </div>
                        </div>
                        <div class="shimmer" style="width: 100%; height: 40px; margin-bottom: 24px;" *ngIf="!helper.subscribersReady"></div>
                        <div class="shimmer" style="width: 100%; height: 300px;" *ngIf="!helper.subscribersReady"></div>
                        <ng-container *ngIf="helper.subscribersReady">
                            <ng-container *ngIf="!helper.chartLoading">
                                <div class="line-chart-wrap">
                                    <app-line-chart
                                        [datasets]="charts.enrollment.datasets"
                                        [labels]="charts.enrollment.labels"
                                        [options]="charts.enrollment.options">
                                    </app-line-chart>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="helper.chartLoading">
                                <app-spinner [centerVertically]="true"></app-spinner>
                            </ng-container>
                        </ng-container>
                    </div>
                </section>
            </div>
            <!-- <div class="content-row">
                <section>
                    <div class="dashboard-title">
                        <div class="icon-wrap">
                            <i class="im-icon im-icon-doc-stats title-icon"></i>
                        </div>
                        <h2>Exporteer rapport</h2>
                    </div>
                    <div class="export-wrap">
                        <app-button classes="passive-blue mr-s" icon="doc-pdf" text="PDF exporteren" [disabled]="false" [submitting]="false" (emitClick)="false"></app-button>
                        <app-button classes="passive-blue" icon="doc-excel" text="Excel exporteren" [disabled]="false" [submitting]="false" (emitClick)="false"></app-button>
                    </div>
                </section>
            </div> -->
        </div>
    </ng-container>

</div>