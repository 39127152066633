<div class="field field-select">
    <span *ngIf="label" class="label" [class.hide-text]="hideLabel">{{label}} <span *ngIf="optionalString">({{optionalString}})</span></span>
    <select 
        [ngModel]="model" 
        (ngModelChange)="modelChange.emit($event)" 
        [class.model-empty]="!model" 
        [class.error-field]="error"
        [ngStyle]="{'max-width': maxWidth ? (maxWidth + 'px') : 'auto'}"
        [disabled]="disabled"
        >
        <ng-container *ngFor="let option of localOptions;">
            <option *ngIf="!getOptionsToExclude().includes(option.value) && !hasParent(option) && !isParent(option)" [ngValue]="option.value">{{option.title}}</option>
            <optgroup *ngIf="isParent(option)" [label]="option.title">
                <ng-container *ngFor="let option of getChildren(option)">
                    <option *ngIf="!getOptionsToExclude().includes(option.value)" [ngValue]="option.value">{{option.title}}</option>
                </ng-container>
            </optgroup>
        </ng-container>
    </select>
    <span *ngIf="error" class="error-field-display">{{error}}</span>
</div>