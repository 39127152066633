import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DefaultService } from '../utils/api';
import { HelpersService } from './helpers.service';

@Injectable({
    providedIn: 'root'
})
export class NewsletterService {
    constructor(
        private DefaultService: DefaultService,
        private toastr: ToastrService,
        public HelpersService: HelpersService
    ) {}

    public getNewsletters({ startRow, rpp, orderBy, term, typeId }: any) {
        return this.DefaultService.newslettersGetNewsletters(startRow, rpp, orderBy, term, typeId);
    }

    public getNewsletterMetadata(uuid: string) {
        return this.DefaultService.newsletterGetNewsletterMetaData(uuid);
    }

    public getTemplateById(id: number) {
        return this.DefaultService.newsletterGetNewsletterTemplate(id);
    }

    public addFiles(files: any, uuid: string) {
        let fileObj = {};
        for (const uuid in files) {
            const file = files[uuid];
            fileObj[uuid] = {
                name: file.name || '',
                sectionType: file.sectionType || '',
                type: file.type || '',
                size: file.size || ''
            };
        }
        return lastValueFrom(this.DefaultService.newsletterAddFiles(fileObj, uuid));
    }

    public getNewsletterFiles(uuid: string) {
        return this.DefaultService.newsletterGetFiles(uuid);
    }

    public deleteNewsletter(uuid: string) {
        return this.DefaultService.newsletterDeleteNewsletter(uuid).pipe(
            tap((next) => {
                this.toastr.success('Nieuwsbrief werd succesvol verwijderd', 'Nieuwsbrief verwijderd');
            })
        );
    }
    public createOrUpdateNewsletter(body: any) {
        return this.DefaultService.newsletterCreateOrUpdateNewsletter(body).pipe(
            tap((next) => {
                if (body.uuid) {
                    this.toastr.success('Nieuwsbrief werd succesvol opgeslagen', 'Nieuwsbrief opgeslagen');
                } else {
                    this.toastr.success('Nieuwsbrief werd succesvol aangemaakt', 'Nieuwsbrief aangemaakt');
                }
            })
        );
    }

    public saveSelections(body: any, uuid: string) {
        return this.DefaultService.newsletterSaveNewsletterSelections(body, uuid).pipe(
            tap((next) => {
                // this.toastr.success('Ontvangers werden succesvol opgeslagen', 'Ontvangers opgeslagen');
            })
        );
    }

    public getContent(uuid: string) {
        return this.DefaultService.newsletterGetNewsletterContent(uuid, true);
    }

    public sendTest(recipients: string[], uuid: string) {
        return this.DefaultService.newsletterSendTestNewsletterToRecipients({ recipients: recipients }, uuid).pipe(
            tap((next) => {
                this.toastr.success('Test e-mail werd succesvol verzonden', 'Test e-mail verzonden');
            })
        );
    }

    public send(uuid: string) {
        return this.DefaultService.newsletterSendNewsletterToRecipients(uuid).pipe(
            tap((next) => {
                this.toastr.success('Nieuwsbrief werd succesvol verzonden', 'Nieuwsbrief verzonden');
            })
        );
    }

    public sendStopped(uuid: string) {
        return this.DefaultService.newsletterRetrySendNewsletterToRecipients(uuid).pipe(
            tap((next) => {
                this.toastr.success('Het verzenden van de nieuwsbrief werd hervat', 'Verzenden hervat');
            })
        );
    }
}
