<div class="page">

    <!-- top bar -->
    <section class="top-bar">
        <!-- search left -->
        <div class="search-wrap">
            <div class="d-flex align-items-center">
                <div class="search-bar-wrap d-flex">
                    <!-- search bar -->
                    <app-field-text
                        [model]="searchSTR.term" 
                        (modelChange)="searchSTR.term = $event" 
                        (enter)="search()"
                        [placeholder]="'Zoek nieuwsbrief...'">
                    </app-field-text>
                    <!-- select type -->
                    <app-field-select
                        style="display: block; width: 200px;"
                        [model]="searchSTR.createdByUser" 
                        [options]="filterARR"
                        (modelChange)="searchSTR.createdByUser = $event; search();"
                    ></app-field-select>
                    <!-- reset -->
                    <button *ngIf="" (click)="search(true);" class="button-link underline" style="margin-left: 24px;font-size: 12px;"><span>Alle filters wissen</span></button>
                </div>
            </div>
            <div class="search-sub-wrap d-flex">
                <!--  -->
            </div>
        </div>
        <div class="d-flex align-items-center">
            <!-- overview -->
            <a href="{{this.ConfigService.getConfig().appOverviewUrl}}" class="d-flex align-items-center portal-link">
                <i class="im-icon im-icon-portal-dots"></i>
            </a>
            <!-- profile right -->
            <div class="profile-wrap d-flex align-items-center">
                <app-profile-section></app-profile-section>
            </div>
        </div>
    </section>
    <!-- end top bar -->

    <!-- title -->
    <div class="d-flex justify-content-between">
        <h1 class="">Selecties <span [class.saving]="tableLoading" class="saving--pre" *ngIf="ready">({{totalRows}})</span></h1>
        <app-button classes="primary-default" icon="plus-round" text="Nieuwe selectie" [disabled]="false" [submitting]="false" (emitClick)="openCreateUpdateModal()"></app-button>
    </div>

    <!-- table -->
    <div class="table-wrap">
        <app-table
            [heads]="tableHeads" 
            [data]="selections"
            [sort]="tableSort"
            [RPP]="RPP"
            [startRow]="startRow"
            [totalRows]="totalRows"
            [loading]="tableLoading"
            [ready]="ready"
            [emptyState]="'Geen resultaten'"
            (sortChange)="setSort($event.code, $event.dir)"
            (clickData)="tableClick($event.item, $event.head)"
            (clickAction)="actionClick($event.item, $event.action)"
            (startRowChange)="changeStartRow($event)"
        ></app-table>
    </div>
    
</div>