<span class="close-modal-outside"><i (click)="ActiveModal.dismiss()" class="im-icon im-icon-x-light"></i></span>
<div class="modal-wrap">
    <ng-container *ngIf="!ready || !resourcesReady">
        <app-spinner [centerVertically]="true"></app-spinner>
    </ng-container>
    <div *ngIf="discount" class="d-flex detail-modal">

        <!-- start form -->
        <div class="d-flex flex-column w-100 flex-grow-1 scroll-y">
            <ng-container *ngIf="loading">
                <div style="height: 100%;">
                    <app-spinner [centerVertically]="true"></app-spinner>
                </div>
            </ng-container>
            <ng-container *ngIf="!loading && ready && resourcesReady">
                <div class="">
                <!-- <div class="pl-m pr-m pt-s pb-s modal-form-section" [class.edit-section]="sectionIsActive('general') && id"> -->
                    <!-- header -->
                    <div class="detail-modal-header d-flex flex-row justify-content-between align-items-center" style="min-height: 36px;">
                        <h1 style="min-height: 36px;">Kortingscode {{id ? discount.code : 'toevoegen'}}</h1>
                        <div class="h-100 d-flex align-items-center" *ngIf="this.activeSection !== 'general'">
                            <!-- delete -->
                            <div *ngIf="canEdit('general')" class="position-relative" appClickOutside (clickOutside)="actionId = null">
                                <button class="button tertiary delete-red" (click)="actionId = 'delete'" [class.disabled]="saving || !canDelete()"><i class="im-icon im-icon-trash delete-red icon-l mr-xxxs" style="transform: translateY(-1px);"></i><span>Verwijderen</span></button>
                                <app-confirm-delete (confirm)="delete();" (cancel)="actionId = null" [open]="actionId === 'delete'" positionClass="left" positionClassV="bottom"></app-confirm-delete>
                            </div>
                            <!-- edit -->
                            <button *ngIf="canDelete('general')" class="button tertiary ml-xs" (click)="activeSection = 'general'" [class.disabled]="saving || !canEdit('general')"><i class="im-icon im-icon-pencil icon-l mr-xxxs"></i><span>Wijzigen</span></button>
                            <!-- <div *ngIf="canEdit('general') || canDelete('general')" class="button-row-sep ml-xs"></div> -->
                            <!-- close -->
                            <!-- <button class="button-link blue ml-xs" (click)="ActiveModal.dismiss()"><span>Sluit</span></button> -->
                        </div>
                        <div class="h-100 d-flex align-items-center" *ngIf="sectionIsActive('general')">
                            <button class="button tertiary" (click)="canExit() && (id ? activeSection = null : ActiveModal.dismiss()); resetForm()" [class.disabled]="saving"><span>Annuleren</span></button>
                            <!-- <button class="button default ml-xs" (click)="createUpdateDiscount()" [class.disabled]="saving">Bewaar</button> -->
                            <app-button classes="primary-default ml-xs" icon="check-round-filled" text="Bewaar" [disabled]="saving" [submitting]="submitting" (emitClick)="createUpdateDiscount()"></app-button>
                        </div>
                    </div>
                    
                    <div class="detail-modal-body">
                        <!-- content -->
                        <div class="row display-row mw-1200-px">
                            <div class="col-xl-4">
                                <!-- type -->
                                <div class="display-content">
                                    <ng-container *ngIf="id">
                                        <div class="display-label" [class.text-color]="sectionIsActive('general')">Type</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{ResourceService.getTypeByCode(discount.type) | display}}</span>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="sectionIsActive('general') && !id">
                                        <app-field-radios 
                                            class="d-block"
                                            label="Type"
                                            [readOnly]="id ? true : false"
                                            [model]="formSTR.type" 
                                            (modelChange)="formSTR.type = $event"
                                            [error]="validation.type"
                                            [options]="[{title: 'Unieke code', value: 'unique'},{title: 'Groepscode', value: 'group'}]"
                                            [optionWidth]="190"
                                            [optionColumnWidth]="">
                                        </app-field-radios>
                                    </ng-container>                            
                                </div>
                            </div>
                        </div>
                        <div class="row display-row mw-1200-px" *ngIf="formSTR.type == 'group' || !sectionIsActive('general')">
                            <div class="col-xl-4">
                                <!-- code -->
                                <div class="display-content">
                                    <ng-container *ngIf="this.activeSection !== 'general'">
                                        <div class="display-label" [class.text-color]="sectionIsActive('general')">Code</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{discount.code | display}} <button data-tip="Kopieer naar klembord" class="tip" (click)="HelpersService.copyToClipboard(discount.code, 'Code is gekopiëerd naar het klembord')"><i class="im-icon im-icon-clipboard icon-xxl" style="transform: translateY(0px);margin-top: -1px;"></i></button></span>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="sectionIsActive('general') && formSTR.type == 'group'">
                                        <app-field-text 
                                            [model]="formSTR.code" 
                                            (modelChange)="formSTR.code = $event; formChange();" 
                                            label="Code"
                                            afterString="Gebruik geen spaties"
                                            [error]="validation.code"
                                            placeholder="">
                                        </app-field-text>
                                    </ng-container>                            
                                </div>
                            </div>
                        </div>
                        <div class="row display-row mw-1200-px" *ngIf="formSTR.type == 'group' && (!id || discount.maxAmount)">
                            <div class="col-xl-2">
                                <!-- max group amount -->
                                <div class="display-content">
                                    <ng-container *ngIf="this.activeSection !== 'general'">
                                        <div class="display-label" [class.text-color]="sectionIsActive('general')">Max. aantal</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{discount.maxAmount | display}}</span>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="sectionIsActive('general')">
                                        <app-field-text 
                                            [model]="formSTR.maxAmount" 
                                            (modelChange)="formSTR.maxAmount = $event; formChange();" 
                                            label="Max. aantal"
                                            type="integer"
                                            optionalString="Optioneel"
                                            [error]="validation.maxAmount"
                                            placeholder="">
                                        </app-field-text>
                                    </ng-container>                            
                                </div>
                            </div>
                        </div>
                        <div class="row display-row mw-1200-px" *ngIf="formSTR.type == 'unique' && !id">
                            <div class="col-xl-2">
                                <!-- uniqueAmount -->
                                <div class="display-content">
                                    <!-- <ng-container *ngIf="this.activeSection !== 'general'">
                                        <div class="display-label">Aantal codes</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{discount.uniqueAmount | display}}</span>
                                        </div>
                                    </ng-container> -->
                                    <ng-container *ngIf="sectionIsActive('general')">
                                        <app-field-text 
                                            [model]="formSTR.uniqueAmount" 
                                            (modelChange)="formSTR.uniqueAmount = $event; formChange();" 
                                            label="Aantal codes"
                                            type="integer"
                                            [error]="validation.uniqueAmount"
                                            placeholder="">
                                        </app-field-text>
                                    </ng-container>                            
                                </div>
                            </div>
                        </div>
                        <div class="row display-row mw-1200-px">
                            <div class="col-xl-8">
                                <!-- discount -->
                                <div class="display-content">
                                    <ng-container *ngIf="this.activeSection !== 'general'">
                                        <div class="display-label">Korting op</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{ResourceService.getDiscountOnByCode(formSTR.discountOn) | display}}</span>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="sectionIsActive('general')">
                                        <app-field-radios 
                                            class="d-block"
                                            label="Korting op"
                                            [model]="formSTR.discountOn" 
                                            (modelChange)="formSTR.discountOn = $event"
                                            [error]="validation.type"
                                            optionalString=""
                                            [options]="[{title: 'Alles', value: 'all'},{title: 'Categorie', value: 'category'},{title: 'Product', value: 'product'}]"
                                            [optionWidth]="190"
                                            [optionColumnWidth]="">
                                        </app-field-radios>
                                    </ng-container>                            
                                </div>
                            </div>
                        </div>
                        <div class="row display-row mw-1200-px" *ngIf="(sectionIsActive('general') && formSTR.discountOn == 'product') || (!sectionIsActive('general') && discount.discountOn) == 'product'">
                            <div class="col-xl-6">
                                <!-- product -->
                                <div class="display-content">
                                    <ng-container *ngIf="this.activeSection !== 'general'">
                                        <div class="display-label">Product</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{discount.initialProduct.value + ' - ' + discount.initialProduct.artikelCode | display}}</span>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="sectionIsActive('general')">
                                        <app-field-typeahead 
                                            [initialModel]="formSTR.initialProduct" 
                                            (modelChange)="formSTR.product = $event ? $event.item.key : ''; formChange();" 
                                            [options]="productARR"
                                            [searchValueARR]="['title','value','artikelCode', 'productType']"
                                            [afterARR]="['productType','artikelCode']"
                                            [afterInputARR]="['artikelCode']"
                                            [editable]="false"
                                            label="Product"
                                            [error]="validation.product"
                                            placeholder="Product">
                                        </app-field-typeahead>
                                        <!-- <app-field-typeahead 
                                            [model]="typeaheadModels.newsletterTypeId" 
                                            (modelChange)="selectTypeaheadItem($event, 'selection')" 
                                            [getOptionsFn]="getNewsletterTypeOptions"
                                            [editable]="false"
                                            [afterARR]="['newsletterType', 'amountOfConditionsDisplay']"
                                            placeholder="Zoek een selectie...">
                                        </app-field-typeahead> -->
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <div class="row display-row mw-1200-px" *ngIf="(sectionIsActive('general') && formSTR.discountOn == 'category') || (!sectionIsActive('general') && discount.discountOn == 'category')">
                            <div class="col-xl-4">
                                <!-- category -->
                                <div class="display-content">
                                    <ng-container *ngIf="this.activeSection !== 'general'">
                                        <div class="display-label">Categorie</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{discount.productOrCategory | display}}</span>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="sectionIsActive('general')">
                                        <app-field-select 
                                            [model]="formSTR.category" 
                                            [options]="categoryARR"
                                            (modelChange)="formSTR.category = $event; formChange();" 
                                            label="Categorie"
                                            [error]="validation.category"
                                            placeholder="Selecteer...">
                                        </app-field-select>
                                    </ng-container>                            
                                </div>
                            </div>
                        </div>
                        <div class="row display-row mw-1200-px">
                            <div class="col-md-2">
                                <!-- worth -->
                                <div class="display-content">
                                    <ng-container *ngIf="this.activeSection !== 'general'">
                                        <div class="display-label">Waarde</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{discount.worthDisplay | display}}</span>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="sectionIsActive('general')">
                                        <app-field-text 
                                            [model]="formSTR.worth" 
                                            (modelChange)="formSTR.worth = $event; formChange();" 
                                            label="Waarde"
                                            [type]="formSTR.worthType === 'percentage' ? 'integer' : 'currency'"
                                            [error]="validation.worth"
                                            placeholder="">
                                        </app-field-text>
                                    </ng-container>                            
                                </div>
                            </div>
                            <div class="col-md-1 pl-0">
                                <!-- value type -->
                                <div class="display-content">
                                    <ng-container *ngIf="sectionIsActive('general')">
                                        <app-field-select 
                                            [model]="formSTR.worthType" 
                                            [options]="[{title: 'euro' ,value: 'valuta'},{title: '%', value: 'percentage'}]"
                                            (modelChange)="formSTR.worthType = $event; formChange();" 
                                            label="Waarde"
                                            [hideLabel]="true"
                                            [error]="validation.worthType"
                                            placeholder="">
                                        </app-field-select>
                                    </ng-container>                            
                                </div>
                            </div>
                        </div>
                        <div class="row display-row mw-1200-px">
                            <div class="col-xl-2">
                                <!-- from -->
                                <div class="display-content">
                                    <ng-container *ngIf="this.activeSection !== 'general'">
                                        <div class="display-label">Geldig van</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{discount.dateFrom | formatTs: 'DD-MM-YYYY'}}</span>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="sectionIsActive('general')">
                                        <app-field-date 
                                            [model]="formSTR.dateFrom" 
                                            (modelChange)="formSTR.dateFrom = $event; formChange();" 
                                            label="Geldig van"
                                            placeholder="dd-mm-yyyy"
                                            [error]="validation.dateFrom">
                                        </app-field-date>
                                    </ng-container>     
                                </div>
                            </div>
                            <div class="col-xl-2" [class.pl-0]="sectionIsActive('general')">
                                <!-- to -->
                                <div class="display-content">
                                    <ng-container *ngIf="this.activeSection !== 'general'">
                                        <div class="display-label">Tot</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{discount.dateTo | formatTs: 'DD-MM-YYYY'}}</span>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="sectionIsActive('general')">
                                        <app-field-date 
                                            [model]="formSTR.dateTo" 
                                            (modelChange)="formSTR.dateTo = $event; formChange();" 
                                            label="Tot"
                                            placeholder="dd-mm-yyyy"
                                            [error]="validation.dateTo">
                                        </app-field-date>
                                    </ng-container>                            
                                </div>
                            </div>
                        </div>
                        <div class="row display-row mw-1200-px">
                            <div class="col-md-2">
                                <!-- min order value -->
                                <div class="display-content">
                                    <ng-container *ngIf="this.activeSection !== 'general'">
                                        <div class="display-label">Minimum bestelbedrag</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{discount.minOrderValue | customCurrency}}</span>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="sectionIsActive('general')">
                                        <app-field-currency 
                                            style="white-space: nowrap;"
                                            [model]="formSTR.minOrderValue" 
                                            (modelChange)="formSTR.minOrderValue = $event; formChange();" 
                                            label="Minimum bestelbedrag"
                                            optionalString="Optioneel"
                                            [error]="validation.minOrderValue"
                                            placeholder="€ 0,00">
                                        </app-field-currency>
                                    </ng-container>                            
                                </div>
                            </div>
                        </div>
                        <div class="row display-row mw-1200-px">
                            <div class="col-xl-3">
                                <!-- from -->
                                <div class="display-content">
                                    <ng-container *ngIf="this.activeSection !== 'general'">
                                        <div class="display-label">Aanvrager</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{discount.applicant | display}}</span>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="sectionIsActive('general')">
                                        <app-field-text 
                                            [model]="formSTR.applicant" 
                                            (modelChange)="formSTR.applicant = $event; formChange();" 
                                            label="Aanvrager"
                                            optionalString="Optioneel"
                                            placeholder=""
                                            [error]="validation.applicant">
                                        </app-field-text>
                                    </ng-container>     
                                </div>
                            </div>
                            <div class="col-xl-3" [class.pl-0]="sectionIsActive('general')">
                                <!-- to -->
                                <div class="display-content">
                                    <ng-container *ngIf="this.activeSection !== 'general'">
                                        <div class="display-label">Doelgroep</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{discount.target | display}}</span>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="sectionIsActive('general')">
                                        <app-field-text 
                                            [model]="formSTR.target" 
                                            (modelChange)="formSTR.target = $event; formChange();" 
                                            label="Doelgroep"
                                            optionalString="Optioneel"
                                            placeholder=""
                                            [error]="validation.target">
                                        </app-field-text>
                                    </ng-container>                            
                                </div>
                            </div>
                        </div>
                        <!-- extra info -->
                        <div class="row display-row mw-1200-px" *ngIf="this.activeSection !== 'general'">
                            <div class="col-md-2">
                                <!-- gebruikt -->
                                <div class="display-content">
                                    <ng-container>
                                        <div class="display-label">Gebruikt</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{discount.used | display}}</span>
                                        </div>
                                    </ng-container>                          
                                </div>
                            </div>
                            <div class="col-md-2">
                                <!-- status -->
                                <div class="display-content">
                                    <ng-container>
                                        <div class="display-label">Status</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{discount.isValid ? 'Geldig' : 'Inactief' | display}}</span>
                                        </div>
                                    </ng-container>                          
                                </div>
                            </div>
                        </div>
                        <div class="row display-row mw-1200-px" *ngIf="this.activeSection !== 'general'">
                            <div class="col-md-2">
                                <!-- last mod -->
                                <div class="display-content">
                                    <ng-container>
                                        <div class="display-label">Laatst gewijzigd door</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{discount.lastModifiedBy | display}}</span>
                                        </div>
                                    </ng-container>                          
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <br>
                    {{formSTR | json}} -->

                </div>
            </ng-container>
        </div>
    </div>
</div>