<div class="page">
    <!-- top bar -->
    <section class="top-bar">
        <!-- title -->
        <h1 class="">Nieuws <span [class.saving]="tableLoading" class="saving--pre" *ngIf="ready">({{totalRows}})</span></h1>

        <div class="d-flex align-items-center">
            <!-- overview -->
            <a [href]="appOverviewUrl" class="d-flex align-items-center portal-link">
                <i class="im-icon im-icon-portal-dots"></i>
            </a>
            <!-- profile right -->
            <div class="profile-wrap d-flex align-items-center">
                <app-profile-section></app-profile-section>
            </div>
        </div>
    </section>
    <!-- end top bar -->

    
    <div class="d-flex justify-content-between top-bar">
        <!-- search left -->
        <div class="search-wrap">
            <div class="d-flex align-items-center">
                <div class="search-bar-wrap mr-xs">
                    <!-- search bar -->
                    <app-search-input
                        [model]="SEARCH?.term" 
                        (modelChange)="SEARCH.term = $event" 
                        [placeholder]="'In nieuws zoeken...'"
                        maxWidth="240"
                        (enter)="search()"
                        (clear)="search(true)"
                    ></app-search-input>
                </div>
                 @if (SEARCHED?.length) {
                     <button (click)="search(true)" class="button-link underline pt-xxs pb-xxs">Herstellen</button>
                 }
            </div>
           
        </div>

        <div class="d-flex align-items-center" style="gap: 16px">
            @if (editSequence) {
                <div class="title" style="color: #717277;">Volgorde wijzigen:</div>
                <button class="button tertiary" [class.saving]="saving" (click)="changeSequence(false)">Annuleren</button>
                <app-button classes="primary-default" icon="check-round-filled" text="Volgorde bewaren" [disabled]="saving || tableLoading" [submitting]="saving" (emitClick)="submitSequence()"></app-button>
            } @else {
                <app-button classes="default--light" icon="sort-up-down" text="Volgorde wijzigen" (emitClick)="changeSequence(true)"></app-button>
                <app-button classes="primary-default" icon="plus-round" text="Nieuws toevoegen" [disabled]="false" [submitting]="false" (emitClick)="openDetailModal('create')"></app-button>
            }
        </div>

    </div>

   <!-- table -->
   <div class="table-wrap">
        <app-table
            emptyState="Geen resultaten"
            [heads]="tableHeads" 
            [data]="data" 
            [sort]="SORT"
            [RPP]="RPP"
            [startRow]="startRow"
            [totalRows]="totalRows"
            [loading]="loading"
            [ready]="ready"
            [changeSequence]="editSequence"
            (sortChange)="setSort($event.code, $event.dir)"
            (clickData)="openDetailModal('view', $event.item)"
            (clickAction)="actionClick($event.item, $event.action)"
            (startRowChange)="setStartRow($event)"
        ></app-table>
   </div>
</div>