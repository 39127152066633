import { Component, inject, input, Input, OnInit, output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HelpersService } from 'src/app/services/helpers.service';

@Component({
    selector: 'app-field-upload-dropzone',
    templateUrl: './field-upload-dropzone.component.html',
    styleUrl: './field-upload-dropzone.component.scss'
})
export class FieldUploadDropzoneComponent implements OnInit {
    toastr = inject(ToastrService);
    helpersService = inject(HelpersService);

    @Input() error: boolean = false;
    @Input() label?: string;
    @Input() subtext?: string;
    @Input() optionalString?: string;
    @Input() afterString?: string;
    @Input() placeholder?: string = '';
    @Input() classes?: string = '';

    @Input() maxMegaBytes?: number = 50;
    @Input() tooltipObj?: { descr: { type: string; value: string }[]; title?: string } = null;
    @Input() loading: boolean = false;

    // @Input() file: File = null;
    // @Input() fileUrl: string = null;
    @Input() allowedExtensions?: string = '*';
    @Input() disabled: boolean = false;

    confirmDelete: boolean = false;
    maxBytes: number = 1;

    selectFile = output<File>();
    removeFile = output();

    // overWriteAllowedExtensions: string = null;

    ngOnInit(): void {
        this.maxBytes = this.maxMegaBytes * 1048576;
        // if (window.innerWidth <= 1024) {
        //     this.overWriteAllowedExtensions = '*';
        // }
    }

    // @HostListener('window:resize', ['$event'])
    // OnResize(event) {
    //     if (event.target.innerWidth <= 1024) {
    //         this.overWriteAllowedExtensions = '*';
    //     } else {
    //         this.overWriteAllowedExtensions = null;
    //     }
    // }

    fileChange($event) {
        this.loading = true;
        setTimeout(() => {
            this.loading = false;
            if (
                this.allowedExtensions !== '*' &&
                !this.allowedExtensions.split(',').find((v) => $event?.name?.includes(v))
            ) {
                this.toastr.error(
                    'Ongeldig formaat, toegelaten formaten zijn: ' + this.allowedExtensions.replace(',', ', '),
                    'Er liep iets mis'
                );
                this.error = true;
                return;
            }
            this.error = false;
            this.selectFile.emit(new File([$event], $event['name']));
        }, 1);
    }
}
