import { Injectable } from '@angular/core';
import { DefaultService } from '../utils/api';
import { ToastrService } from 'ngx-toastr';
import { tap } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ToolkitService {
    constructor(private DefaultService: DefaultService, private toastr: ToastrService) {}

    public getToolkit(id: number) {
        return this.DefaultService.toolkitGetToolkit(id);
    }

    public updateInsertToolkit(body: any, id?: number) {
        return this.DefaultService.toolkitUpdateOrInsertToolkit(body).pipe(
            tap((next) => {
                if (id) {
                    this.toastr.success('Toolkit werd succesvol opgeslagen', 'Toolkit opgeslagen');
                } else {
                    this.toastr.success('Toolkit werd succesvol aangemaakt', 'Toolkit aangemaakt');
                }
            })
        );
    }

    public uploadImage(image: any, id: number) {
        return this.DefaultService.toolkitUploadImage(image, id);
    }

    public deleteImage(id: number) {
        return this.DefaultService.toolkitDeleteImage(id);
    }

    public deleteToolkit(id: number) {
        return this.DefaultService.toolkitDeleteToolkit(id).pipe(
            tap((next) => {
                this.toastr.success('Toolkit werd succesvol verwijderd', 'Toolkit verwijderd');
            })
        );
    }
}
