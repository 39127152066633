import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { FieldSwitchOptionsModel } from 'src/app/components/fields/field-switch/field-switch.component';
import { switchPanelAnimation } from 'src/app/utils/animations';
import { SelectionService } from '../../services/selection.service';
import { ResourceService } from '../../services/resource.service';
import { HelpersService } from '../../services/helpers.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectionDetailModalComponent } from 'src/app/components/modals/selection-detail-modal/selection-detail-modal.component';
import { HttpClient, HttpParams } from '@angular/common/http';
import { customCurrencyPipe, percentagePipe } from '../../utils/pipes';
import { Pipe, PipeTransform } from '@angular/core';
import { FilterButtonModel } from 'src/app/components/common/filter-button/filter-button.component';
import { ConfigService } from 'src/app/services/config.service';

@Component({
    selector: 'app-selections',
    templateUrl: './selections.component.html',
    styleUrls: ['./selections.component.scss'],
    animations: [switchPanelAnimation]
})
export class SelectionsComponent implements OnInit, OnDestroy {
    subscriptionObservables$: Observable<any>[] = [];
    onDestroy$: Subject<void> = new Subject<void>();
    popoverHelper: any = null;
    panelData: any;
    hasPanelData: boolean = false;
    searchSTR: any = {};
    exportBusy: boolean = false;
    filterCount: any = {};
    // switchOptions: FieldSwitchOptionsModel = [
    //     // {
    //     //     title: 'Snelle filters',
    //     //     value: 'quick'
    //     // },
    //     {
    //         title: 'Geavanceerd zoeken',
    //         value: 'advanced'
    //     }
    // ];

    filterARR: (FilterButtonModel | any)[] = [
        { title: 'Alle selecties', value: 'false' },
        { title: 'Mijn selecties', value: 'true' }
    ];

    // table
    tableHeads: any = [
        { name: 'Naam', code: 'nameDisplay', sortable: false, width: '25%' },
        { name: 'Aangemaakt op', code: 'createTSDisplay', sortable: true, width: '25%' },
        { name: 'Aangemaakt door', code: 'createdBy', sortable: false, width: '25%' },
        { name: 'Aantal personen', code: 'subscriptionAmountDisplay', sortable: false },
        { name: '', code: 'actions' }
    ];
    tableSort: { code: string; dir: string } = { code: 'createTSDisplay', dir: 'desc' };

    selections: any[] = null;
    startRow: number = 0;
    RPP: number = 20;
    totalRows: number = null;
    tableLoading: boolean = true;
    ready: boolean = false;

    constructor(
        private ModalService: NgbModal,
        public ResourceService: ResourceService,
        private SelectionService: SelectionService,
        public HelpersService: HelpersService,
        private toastr: ToastrService,
        public route: ActivatedRoute,
        public router: Router,
        public ConfigService: ConfigService
    ) {}

    ngOnInit(): void {
        this.initSearchSTR();
        const activeSelection = this.HelpersService.getParam('selection');
        if (activeSelection == 'new') {
            this.openCreateUpdateModal();
        } else if (activeSelection) this.openCreateUpdateModal(activeSelection);
        const page = parseInt(this.HelpersService.getParam('page'));
        if (page) this.startRow = this.RPP * page - this.RPP;
        const query = this.HelpersService.getParam('query');
        if (query) this.searchSTR = JSON.parse(unescape(query));

        this.getSelections();

        this.SelectionService.getSelectionUuidForSubscriptionAmount().subscribe(async (selectionUuid) => {
            if (selectionUuid) {
                const index = this.selections.findIndex((item) => {
                    return item.uuid === selectionUuid;
                });
                this.selections[index].subscriptionAmountDisplay =
                    await this.SelectionService.calculateSubscriptionsAmount(
                        this.selections[index].filterJSON,
                        this.selections[index].uuid
                    );
            }
        });
    }

    deleteSelection(uuid: string) {
        this.dismissPopover();
        this.deSelectRows();
        this.SelectionService.deleteSelection(uuid).subscribe((next) => {
            this.getSelections();
        });
    }

    dismissPopover() {
        setTimeout(() => {
            this.popoverHelper = null;
        }, 1);
    }

    initSearchSTR() {
        this.searchSTR = { createdByUser: 'false' };
    }

    getSearchQuery() {
        return {
            ...this.searchSTR,
            startRow: this.startRow,
            RPP: this.RPP,
            orderBy: this.getSortARR()
        };
    }

    getSortARR() {
        switch (this.tableSort.code) {
            case 'createTSDisplay':
                return [`createTS ${this.tableSort.dir}`];
        }
        if (!this.tableSort || !this.tableSort.code) {
            return [];
        }
        return [`${this.tableSort.code} ${this.tableSort.dir}`];
    }

    search(reset?: boolean) {
        this.startRow = 0;
        if (reset) {
            this.initSearchSTR();
        }
        if (this.HelpersService.objectIsEmpty(this.searchSTR)) {
            this.HelpersService.removeParam('query');
        } else {
            const query = escape(JSON.stringify(this.searchSTR));
            this.HelpersService.addParam('query', query);
        }
        setTimeout(() => {
            this.HelpersService.addParam('page', (Math.ceil(this.startRow / this.RPP) + 1).toString());
        }, 1);
        this.getSelections();
    }

    getSelections() {
        const searchSTR = this.getSearchQuery();
        this.deSelectRows();
        this.tableLoading = true;
        this.SelectionService.getSelections(searchSTR)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(async (next: any) => {
                if (this.startRow && this.startRow >= next.rows) {
                    this.resetStartRow();
                    return;
                }
                this.selections = next.data.map((item: any) => {
                    const actions = [];
                    const deleteAction = {
                        name: 'Verwijderen',
                        code: 'delete',
                        icon: 'trash',
                        class: 'delete-red',
                        confirmDelete: true
                    };
                    actions.push(deleteAction);
                    const editAction = {
                        name: 'Bewerken',
                        code: 'edit',
                        icon: 'pencil'
                    };
                    actions.push(editAction);
                    let subtitle = 'Vaste lijst';
                    if (item.type == 'dynamic') {
                        subtitle = `- ${item.amountOfConditions} voorwaarde${item.amountOfConditions > 1 ? 'n' : ''}`;
                    }
                    const selection = {
                        ...item,
                        nameDisplay: {
                            type: 'titleSubtitle',
                            title: item.name,
                            subtitle:
                                `${item.amountOfConditions} voorwaarde${item.amountOfConditions > 1 ? 'n' : ''}` +
                                ' - ' +
                                this.getTitleMultiValues(
                                    item.newsletterTypes?.map((subItem) => {
                                        return subItem.name;
                                    }),
                                    1
                                ),
                            tooltipSubtitle: this.getTooltipValue(
                                item.newsletterTypes?.map((subItem) => {
                                    return subItem.name;
                                }),
                                1
                            ),
                            stampsSubtitle: this.getStampForTootltip(
                                item.newsletterTypes?.map((subItem) => {
                                    return subItem.name;
                                }),
                                1
                            )
                        },
                        createTSDisplay: {
                            type: 'ts',
                            ts: item.createTS,
                            format: 'DD-MM-YYYY'
                        },
                        subscriptionAmountDisplay: null,

                        actions: {
                            type: 'actions',
                            actions: actions
                        }
                    };
                    return selection;
                });

                this.tableLoading = false;
                this.ready = true;
                this.totalRows = next.rows;
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
                for (let selection of this.selections) {
                    this.selections.find((selectionItem) => {
                        return selectionItem.uuid == selection.uuid;
                    }).subscriptionAmountDisplay = await this.SelectionService.calculateSubscriptionsAmount(
                        selection.filterJSON,
                        selection.uuid
                    );
                }
            });
    }

    getTitleMultiValues(values: any[], numberValues) {
        if (values && values.length > numberValues) {
            return values.slice(0, numberValues).join(', ');
        } else {
            return values ? values.join(', ') : '';
        }
    }

    getTooltipValue(values: any[], numberValues) {
        if (values && values.length > numberValues) {
            return values.slice(numberValues).join(', ');
        }
    }

    getStampForTootltip(values: any[], numberValues) {
        if (values && values.length > numberValues) {
            return [{ value: '+' + (values.length - numberValues), classList: 'stamp--small' }];
        }
    }

    changeStartRow(startRow: number) {
        this.startRow = startRow;
        this.HelpersService.addParam('page', (Math.ceil(this.startRow / this.RPP) + 1).toString());
        this.getSelections();
    }

    resetStartRow() {
        this.startRow = 0;
        this.HelpersService.addParam('page', (Math.ceil(this.startRow / this.RPP) + 1).toString());
        this.getSelections();
    }

    // export() {
    //     const searchSTR = this.getSearchQuery();
    //     let params = new HttpParams();
    //     for (const key in searchSTR) {
    //         params = params.append(key, searchSTR[key]);
    //     }
    //     this.exportBusy = true;
    //     this.SelectionService.exportDiscounts(searchSTR).subscribe((data) => {
    //         setTimeout(() => {
    //             this.exportBusy = false;
    //         }, 500);
    //     });
    // }

    tableClick(item: any, head: string) {
        switch (item.type) {
            case 'actions':
                break;
            default:
                this.hasPanelData ? this.selectRow(item) : this.openCreateUpdateModal(item.uuid);
                break;
        }
    }

    actionClick(item: any, action: string) {
        switch (action) {
            case 'edit':
                this.openCreateUpdateModal(item.uuid, true);
                break;
            case 'delete':
                this.deleteSelection(item.uuid);
                break;
        }
    }

    selectRow(item: any) {
        this.selections = this.selections.map((x) => {
            if (x.uuid === item.uuid && !x.trActive) {
                x.trActive = true;
                this.panelData = item;
            } else if (x.uuid === item.uuid) {
                delete this.panelData;
                delete x.trActive;
            } else {
                delete x.trActive;
            }
            return x;
        });
    }

    deSelectRows() {
        if (this.selections) {
            this.selections = this.selections.map((x) => {
                delete x.trActive;
                return x;
            });
        }
        delete this.panelData;
    }

    setSort(code: string, dir: string) {
        this.tableSort = { code: code, dir: dir };
        this.getSelections();
    }

    openCreateUpdateModal(uuid?: string, editMode?: boolean) {
        const modalRef = this.ModalService.open(SelectionDetailModalComponent, {
            windowClass: 'main-modal detail-modal',
            beforeDismiss: () => {
                return modalRef.componentInstance.canExit && modalRef.componentInstance.canExit();
            }
        });
        if (uuid) {
            this.HelpersService.addParam('selection', uuid);
            modalRef.componentInstance.uuid = uuid;
            modalRef.componentInstance.editMode = editMode;
            if (editMode) {
                modalRef.componentInstance.initSideNavDisabled = true;
            }
        } else this.HelpersService.addParam('selection', 'new');
        modalRef.result
            .then((returnValue) => {
                switch (returnValue) {
                    case 'delete':
                        this.deSelectRows();
                        this.getSelections();
                        break;
                }
            })
            .finally(() => {
                this.HelpersService.removeParam('selection');
            });
        // on create
        modalRef.componentInstance.created.subscribe((selection: any) => {
            setTimeout(() => {
                this.HelpersService.addParam('selection', selection.uuid);
            }, 1);
            this.search(true);
        });
        // on update
        modalRef.componentInstance.updated.subscribe((selection: any) => {
            this.getSelections();
        });
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
    }
}
