import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, finalize, takeUntil } from 'rxjs';
import { HelpersService } from 'src/app/services/helpers.service';
import { ToolkitService } from 'src/app/services/toolkit.service';

@Component({
    selector: 'app-toolkit-detail-modal',
    templateUrl: './toolkit-detail-modal.component.html',
    styleUrls: ['./toolkit-detail-modal.component.scss']
})
export class ToolkitDetailModalComponent implements OnInit, OnDestroy {
    @Input() id?: any;
    @Input() editMode?: any;
    @Output() created = new EventEmitter();
    @Output() updated = new EventEmitter();

    toolkit: any = {};
    activeSection: string;
    popoverHelper: any = null;
    validation: any = {};
    helper: any = {};
    changes: boolean = false;

    ready: boolean = false;
    loading: boolean = false;
    saving: boolean;
    actionId = null;
    submitting: boolean = false;

    formSTR: any = { homePage: 0 };

    onDestroy$: Subject<void> = new Subject<void>();

    constructor(
        private ModalService: NgbModal,
        public ActiveModal: NgbActiveModal,
        public toolkitService: ToolkitService,
        public HelpersService: HelpersService
    ) {}

    ngOnInit(): void {
        // edit or create
        if (this.id && this.id !== 'new') {
            this.getToolkit();
        } else {
            this.toolkit = {};
            this.ready = true;
            this.activeSection = 'general';
        }
    }

    getToolkit() {
        this.loading = true;
        this.toolkitService
            .getToolkit(this.id)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((res) => {
                this.toolkit = res;
                if (this.editMode) {
                    this.activeSection = 'general';
                }
                this.ready = true;
                this.loading = false;
                this.resetForm();
            });
    }

    imageChange($event) {
        this.formSTR.image = $event;
        if (!$event) {
            delete this.formSTR.imageUrl;
        }
    }

    createUpdateToolkit() {
        const image = this.formSTR.image;
        const FORM = JSON.parse(JSON.stringify(this.formSTR));
        this.submitting = true;
        if (FORM.isPublished === 1 && !FORM.publishDate) {
            FORM.publishDate = new Date();
        }
        if (FORM.isPublished === 2) {
            FORM.publishDate = null;
        }
        if (FORM.link) {
            FORM.link = this.HelpersService.fillHttp(FORM.link);
        }
        this.saving = true;
        this.toolkitService
            .updateInsertToolkit(FORM, this.id || null)
            .pipe(
                finalize(() => {
                    this.saving = false;
                    this.submitting = false;
                })
            )
            .subscribe({
                next: (next) => {
                    this.validation = {};
                    this.changes = false;
                    if (image) {
                        this.toolkitService.uploadImage(image, next.id).subscribe();
                    } else if (!FORM.imageUrl) {
                        this.toolkitService.deleteImage(next.id).subscribe();
                    }
                    this.toolkit.id ? this.updated.emit(this.toolkit) : this.created.emit(this.toolkit);
                    this.HelpersService.removeParam('toolkitId');
                    this.ActiveModal.dismiss();
                },
                error: (error) => {
                    this.validation = error.error.details;
                }
            });
    }

    getPublishedText() {
        if (this.toolkit.isPublished === 1) {
            return 'Ja';
        }
        if (this.toolkit.isPublished === 2) {
            return 'Nee';
        }
    }

    formChange() {
        this.changes = true;
    }

    getHomePageText() {
        return this.toolkit.homePage ? 'Ja' : 'Nee';
    }

    resetForm() {
        this.formSTR = { ...this.toolkit };
    }

    sectionIsActive(code) {
        return this.activeSection === code;
    }

    canExit(): boolean {
        if (!this.changes || confirm('Je hebt onopgeslagen wijzigingen, weet je zeker dat je wilt afsluiten?')) {
            this.changes = false;
            return true;
        } else {
            return false;
        }
    }

    delete() {
        this.saving = true;
        this.actionId = null;
        this.toolkitService
            .deleteToolkit(this.id)
            .pipe(
                finalize(() => {
                    this.saving = false;
                })
            )
            .subscribe((next) => {
                this.activeSection = null;
                this.updated.emit(this.toolkit);
                this.changes = false;
                this.ActiveModal.dismiss();
            });
    }

    ngOnDestroy(): void {}
}
