<div class="field field-text">
    <span *ngIf="label" class="label">{{label}} <span *ngIf="optionalString">({{optionalString}})</span></span>
    <input 
        [class.error-field]="error"
        type="text"
        [(ngModel)]="localModel" 
        (blur)="onBlur($event)"
        (keyup.enter)="onBlur(); enter.emit()"
        [placeholder]="placeholder"
    />
    <span *ngIf="error" class="error-field-display">{{error}}</span>
</div>