<div>

    <h1>Ben je zeker?</h1>

    <p>Ben je zeker dat je de nieuwsbrief wil verzenden?</p>

    <div *ngIf="true" class="d-flex align-items-center mt-s">
        <!-- submit -->
        <!-- <button class="button default" (click)="ActiveModal.dismiss(); confirm.emit()">Verzenden</button> -->
        <app-button classes="primary-default" icon="check-round-filled" iconSize="16px" text="Verzenden" [disabled]="false" [submitting]="false" (emitClick)="ActiveModal.dismiss(); confirm.emit()"></app-button>
        <!-- cancel -->
        <!-- <button class="button-link blue cancel ml-m" (click)="ActiveModal.dismiss()" [class.disabled]="false"><span>Annuleer</span></button> -->
        <app-button classes="primary-light ml-s" icon="x-round-filled" iconSize="16px" text="Annuleer" [disabled]="false" [submitting]="false" (emitClick)="ActiveModal.dismiss()"></app-button>
    </div>

</div>