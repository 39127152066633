import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { tap } from 'rxjs/operators';
import { DefaultService } from '../utils/api';
import { HelpersService } from './helpers.service';

@Injectable({
    providedIn: 'root'
})
export class PageService {
    constructor(
        private DefaultService: DefaultService,
        private toastr: ToastrService,
        public HelpersService: HelpersService
    ) {}

    public getPage(id: number) {
        return this.DefaultService.pageGetPage(id);
    }

    public updateInsertPage(body: any, id?: number) {
        return this.DefaultService.pagesUpdateOrInsertPage(body).pipe(
            tap((next) => {
                if (id) {
                    this.toastr.success('Pagina werd succesvol opgeslagen', 'Pagina opgeslagen');
                } else {
                    this.toastr.success('Pagina werd succesvol aangemaakt', 'Pagina aangemaakt');
                }
            })
        );
    }

    public deletePage(id: number) {
        return this.DefaultService.pagesDeletePage(id).pipe(
            tap((next) => {
                this.toastr.success('Pagina werd succesvol verwijderd', 'Pagina verwijderd');
            })
        );
    }
}
