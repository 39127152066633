<span class="close-modal-outside"><i (click)="ActiveModal.dismiss()" class="im-icon im-icon-x-light"></i></span>
<div class="modal-wrap">
    <ng-container *ngIf="!ready || !resourcesReady">
        <app-spinner [centerVertically]="true"></app-spinner>
    </ng-container>
    <div *ngIf="selection" class="d-flex detail-modal selection-detail-modal">
    
        <!-- side panel -->
        <app-modal-side-panel [open]="true" *ngIf="uuid">
            <div class="d-flex flex-column w-100 flex-grow-1 scroll-y pb-5">
                <div class="p-l pb-s panel-head">
                    <button (click)="ActiveModal.dismiss()" [class.disabled]="saving" class="mb-xl fontsize-xs"> <i class="im-icon im-icon-arrow-left mr-0" style="font-size: 18px;"></i> <span class="fontsize-s d-inline-block" style="margin-left: 11px;line-height: 21px;transform: translateY(-2px);">Terug naar overzicht</span></button>  
                    <div class="">
                        <div class="d-flex flex-column titles">
                            <span class="title">
                                {{uuid ? selection.name : 'Nieuwe selectie'}}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-grow-1 scroll-y" [class.saving]="loading">
                    <app-side-navigation
                        class="d-block w-100"
                        [pageARR]="pages"
                        [model]="activePage"
                        (modelChange)="pageChange($event)"
                        [subscriptionAmountSpinner]="subscriptionAmountSpinner"
                        [subscriptionAmount]="subscriptionAmount">
                    </app-side-navigation>
                </div>
            </div>
        </app-modal-side-panel>
    
        <!-- start form -->
        <div class="d-flex flex-column w-100 flex-grow-1 scroll-y" [class.pb-5]="getActivePage().code !== 'preview'">
            <div class="">
            <!-- header -->
                <div class="detail-modal-header d-flex flex-row justify-content-between align-items-center" style="min-height: 36px;">
                    <h1 style="min-height: 36px;" class="d-flex align-items-center">
                        <!-- <i class="active-page-icon" *ngIf="getActivePage()?.icon" class="im-icon im-icon-{{getActivePage().icon}}"></i> -->
                        <span><ng-container *ngIf="!uuid">Nieuwe selectie</ng-container><ng-container *ngIf="uuid">{{getActivePage().name}}</ng-container></span>
                    </h1>
    
                    <div class="h-100 d-flex align-items-center" *ngIf="!sectionIsActive('conditions')">
                        <!-- edit -->
                        <button *ngIf="canDelete('conditions') && activePage == 'conditions'" class="button tertiary ml-xs" (click)="activeSection = 'conditions';setSideNavDisabled(true);" [class.disabled]="saving || !canEdit('conditions')"><i class="im-icon im-icon-pencil icon-l mr-xxxs"></i><span>Wijzig</span></button>
                    </div>
                    <div class="h-100 d-flex align-items-center" *ngIf="sectionIsActive('conditions')">
                        <!-- cancel -->
                        <button *ngIf="!lastPageActive()" class="button tertiary" (click)="canExit() && (uuid ? activeSection = null : ActiveModal.dismiss()); resetForm();setSideNavDisabled(false);" [class.disabled]="saving"><span>Annuleer</span></button>
                        <!-- submit -->
                        <!-- <button *ngIf="!lastPageActive()" class="button default ml-xs" (click)="createUpdateSelection();" [class.disabled]="saving">Bewaar</button> -->
                        <app-button *ngIf="!lastPageActive()" classes="primary-default ml-xs" icon="check-round-filled" text="Bewaar" [disabled]="saving" [submitting]="submitting" (emitClick)="createUpdateSelection()"></app-button>
                    </div>
                </div>
    
                <ng-container *ngIf="loading">
                    <div style="height: 100%;">
                        <app-spinner [centerVertically]="true"></app-spinner>
                    </div>
                </ng-container>
    
                <div *ngIf="!loading" class="detail-modal-body">
    
                    <!--------- CONDITIONS --------->
                    <div *ngIf="activePage == 'conditions'">
                        <div class="row display-row mw-600-px">
                            <div class="col-12">
                                <!-- name -->
                                <div class="display-content">
                                    <ng-container *ngIf="!sectionIsActive('conditions')">
                                        <div class="display-label" [class.text-color]="sectionIsActive('conditions')">Naam selectie</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{selection.name | display}}</span>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="sectionIsActive('conditions')">
                                        <app-field-text 
                                            [model]="formSTR.name" 
                                            (modelChange)="formSTR.name = $event;" 
                                            label="Naam selectie"
                                            [error]="validation.name"
                                            placeholder="">
                                        </app-field-text>
                                    </ng-container>                            
                                </div>
                            </div>
                        </div>
                        <div class="conditions mw-600-px" *ngIf="formSTR.type == 'dynamic'">
                            <ng-container *ngIf="!sectionIsActive('conditions')">
                                <div class="display-row--">
                                    <div class="display-label">Voorwaarden</div>
                                    <ol class="display-value">
                                        <li *ngFor="let condition of selection.conditionARR">
                                            <div class="values">
                                                <span class="fontweight-500">{{condition.condition}}</span> 
                                                <ul>
                                                    <li *ngFor="let value of condition.valueARR">
                                                        <div class="arrow-right"></div>{{value}}
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                    </ol>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="sectionIsActive('conditions')">
                                <div class="fontsize-l mb-xxxs">Voorwaarden</div>
                                <span class="passive-gray fontsize-s">Bouw je selectie op door bijkomende voorwaarden toe te voegen</span>
                                <ng-container *ngFor="let condition of formSTR.conditionARR; index as $i">
                                    <div class="row mt-s mw-1200-px">
                                        <div class="col-5">
                                            <div class="position-relative select-wrap">
                                                <app-field-select 
                                                    [model]="formSTR.conditionARR[$i].condition" 
                                                    [options]="conditionOptions"
                                                    (modelChange)="formSTR.conditionARR[$i].condition = $event;setConditionOptionOptions($i); formChange();" 
                                                    label="Voorwaarde"
                                                    [error]="validation.condition"
                                                    placeholder="Selecteer..."
                                                    [disabled]="$i == 0 ? true : false"
                                                    [excludeValues]="$i !== 0 ? getTestCondition() : []">
                                                </app-field-select>
                                                <!-- <i (click)="deleteCondition($i)" class="icon icon-delete alignToSelect" *ngIf="(formSTR.conditionARR.length > 1 && !showIfConditionSelected($i)) && $i > 0"></i> -->
                                                <i (click)="deleteCondition($i)" class="im-icon im-icon-x-light"  *ngIf="(formSTR.conditionARR.length > 1 && !showIfConditionSelected($i)) && $i > 0"></i>
    
                                            </div>
                                        </div>
                                        
                                        <div  *ngIf="showIfConditionSelected($i)" [ngClass]="getColWidth($i)">
                                            <ng-container *ngFor="let value of formSTR.conditionARR[$i].valueARR; trackBy:identify; index as $j">
                                                <div [ngClass]="(showIfConditionSelected($i) && hasSingleValue($i)) ? 'mb-s' : ''" class=" position-relative select-wrap">
                                                    <ng-container *ngIf="showFieldByType($i, 'dropdown')">
                                                        <app-field-select 
                                                            [model]="formSTR.conditionARR[$i].valueARR[$j]" 
                                                            [options]="selectedConditionOptions[$i]"
                                                            (modelChange)="formSTR.conditionARR[$i].valueARR[$j] = $event; formChange();" 
                                                            [label]="$j === 0 ? 'Waarde' : ''"
                                                            [error]="validation[formSTR.conditionARR[$i].condition]"
                                                            placeholder="Selecteer...">
                                                        </app-field-select>
                                                    </ng-container>
                                                    <ng-container *ngIf="showFieldByType($i, 'text')">
                                                        <app-field-text 
                                                            [model]="formSTR.conditionARR[$i].valueARR[$j]" 
                                                            (modelChange)="formSTR.conditionARR[$i].valueARR[$j] = $event; formChange();" 
                                                            [label]="$j === 0 ? 'Waarde' : ''"
                                                            [error]="validation.value"
                                                            placeholder="">
                                                        </app-field-text>
                                                    </ng-container>
                                                    <ng-container *ngIf="showFieldByType($i, 'autocomplete')">
                                                        <app-field-typeahead-small
                                                            [index]="$j"
                                                            [initialModel]="formSTR.conditionARR[$i].initialValueARR[$j]" 
                                                            (modelChange)="formSTR.conditionARR[$i].valueARR[$j] = $event ? $event.item.key : ''; formChange();formSTR.conditionARR[$i].initialValueARR[$j] = $event ? {key: $event.item.key, value: $event.item.value} : {};" 
                                                            [options]="productARR"
                                                            [searchKeyARR]="['value','code']"
                                                            [afterARR]="['productType','artikelCode']"
                                                            [editable]="false"
                                                            [label]="$j === 0 ? 'Waarde' : ''"
                                                            [error]="validation.value"
                                                            placeholder="">
                                                        </app-field-typeahead-small>
                                                    </ng-container>
                                                    <i (click)="deleteValue($i, $j)" class="im-icon im-icon-x-light"  *ngIf="showIfConditionSelected($i) && (formSTR.conditionARR[$i].valueARR.length > 1 || $i > 0)"></i>
                                                </div>
                                            </ng-container>
                                            <div *ngIf="showIfConditionSelected($i) && hasSingleValue($i)">
                                                <button (click)="addValue($i)" class="button-link mt-xs fontsize-s">
                                                    <i class="im-icon im-icon-plus"></i>
                                                    <span>Voeg waarde toe</span>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-row-sep"></div>
                                        </div>
                                    </div>
                                </ng-container>
                                <div class="row ">
                                    <div class="col-12">
                                        <button (click)="addCondition()" class="button-link mt-s fontsize-s">
                                            <i class="im-icon im-icon-plus"></i>
                                            <span>Voeg voorwaarde toe</span>
                                        </button>
                                    </div>
                                </div>
                                <div class=""></div>
                            </ng-container>
                        </div>
                        
                    </div>
                    <!--------- LIST --------->
                        <div *ngIf="activePage == 'list'">
                        <app-table
                            [heads]="tableHeads" 
                            [data]="listARR" 
                            [sort]=""
                            [RPP]="RPP"
                            [startRow]="startRow"
                            [totalRows]="totalRows"
                            [loading]="tableLoading"
                            [shimmerTypes]="['text-m','text-m','text-sm','text-m','actions']"
                            emptyState="Geen resultaten"
                            (sortChange)="false"
                            (clickData)="false"
                            (clickAction)="actionClick($event.item, $event.action)"
                            (startRowChange)="false"
                            [canAddRows]="formSTR.type == 'static' && sectionIsActive('conditions') ? true : false"
                            (addRowToTable)="addRowToTable($event)"
                            [addRowFormSTR]="addRowFormSTR"
                            (startRowChange)="changeStartRow($event)"
                            >
                        </app-table>
    
    
                    </div>
    
                </div>
    
            </div>
        </div>
    </div>
</div>