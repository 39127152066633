import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UpdateNotificationPayLoad } from 'backend/src/models/meldpunt/notification';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom } from 'rxjs';
import { DefaultService } from 'src/app/utils/api';

export type NotificationDetailMode = 'create' | 'edit' | 'view';
type NotificationDetail = {
    id: string;
    medicijn: string;
    ervaringIndicator: number;
    ervaringDescr: string;
    createTS: string;
    overWerking?: boolean;
    overBijwerkingen?: boolean;
    overPraktisch?: boolean;
    onderwerpen: string[];
    leeftijd?: number;
    geslacht?: 'Man' | 'Vrouw';
    redenGebruik?: string;
    // ervaringNadien?: 'doorgegaan' | 'gestopt' | 'aangepast' | 'overgestapt';
    ervaringNadien?: {
        title: string;
        value: string;
    };
    hulpverlener?: boolean;
    approved?: boolean;
};

@Component({
    selector: 'app-notification-detail-modal',
    templateUrl: './notification-detail-modal.component.html',
    styleUrl: './notification-detail-modal.component.scss'
})
export class NotificationDetailModalComponent implements OnInit {
    activeModal = inject(NgbActiveModal);
    route = inject(ActivatedRoute);
    router = inject(Router);
    defaultService = inject(DefaultService);
    toastr = inject(ToastrService);

    mode: NotificationDetailMode = undefined;
    ready: boolean = false;
    saving: boolean;
    submitting: boolean = false;
    changes: boolean = false;

    id: string = undefined;
    notification: NotificationDetail;
    FORM: NotificationDetail;
    validation: any;

    confirmDelete = false;

    onderwerpen = [
        {
            title: 'Werking',
            value: 'werking'
        },
        {
            title: 'Bijwerkingen',
            value: 'bijwerkingen'
        },
        {
            title: 'Praktisch',
            value: 'praktisch'
        }
    ];

    ervaringenNadien = [
        {
            title: 'Doorgegaan',
            value: 'doorgegaan'
        },
        {
            title: 'Gestopt',
            value: 'gestopt'
        },
        {
            title: 'Aangepast',
            value: 'aangepast'
        },
        {
            title: 'Overgestapt',
            value: 'overgestapt'
        }
    ];

    // actionId = null;
    ngOnInit(): void {
        this.id = this.route.snapshot.queryParamMap.get('notificationID');
        this.mode = this.route.snapshot.queryParamMap.get('mode') as NotificationDetailMode;
        if (this.id) this.getNotification(this.id);
        // else this.initEmptyNotification();
    }
    initEmptyNotification() {
        this.notification = {
            id: this.id,
            medicijn: undefined,
            ervaringIndicator: undefined,
            ervaringDescr: undefined,
            createTS: undefined,
            overWerking: undefined,
            overBijwerkingen: undefined,
            overPraktisch: undefined,
            onderwerpen: [],
            leeftijd: undefined,
            geslacht: undefined,
            redenGebruik: undefined,
            ervaringNadien: undefined,
            hulpverlener: undefined,
            approved: true
        };
        this.resetForm();
    }

    resetForm() {
        this.FORM = structuredClone(this.notification);
    }

    async getNotification(id: string) {
        const notification = await lastValueFrom(this.defaultService.notificationsGetNotificationDetail(id));
        const geslacht = notification?.geslacht === 'F' ? 'Vrouw' : 'Man';
        const onderwerpen = [];
        if (notification.overBijwerkingen) onderwerpen.push('bijwerkingen');
        if (notification.overPraktisch) onderwerpen.push('praktisch');
        if (notification.overWerking) onderwerpen.push('werking');
        this.notification = {
            ...notification,
            id: notification.id,
            geslacht: geslacht,
            onderwerpen: this.onderwerpen?.filter((x) => onderwerpen.includes(x.value)).map((x) => x.title),
            ervaringNadien: this.ervaringenNadien?.find((x) => x.value === notification.ervaringNadien)
        };
        this.FORM = structuredClone(this.notification);

        this.ready = true;
    }

    async update() {
        const updateNotificationPayLoad: UpdateNotificationPayLoad = {
            id: this.id,
            ervaringDescr: this.FORM.ervaringDescr
        };
        this.submitting = true;

        this.defaultService.notificationsUpdateNotification(updateNotificationPayLoad).subscribe({
            next: async (next) => {
                this.toastr.success('Melding werd succesvol gewijzigd', 'Melding gewijzigd');
                this.submitting = false;
                this.activeModal.close();
            },
            error: (error) => {
                this.validation = error.error.details;
                this.submitting = false;
                // this.toastr.error('Er liep iets mis bij het gewijzigen van de melding', 'Melding gewijzigen mislukt');
            }
        });
    }

    deleteNotification() {
        this.submitting = true;

        this.defaultService.notificationsDeleteNotification(this.id).subscribe({
            next: (next) => {
                this.submitting = false;
                this.toastr.success('Melding werd succesvol verwijderd', 'Melding verwijderd');
                this.activeModal.close();
            },
            error: (error) => {
                this.validation = error.error.details;
                this.submitting = false;
                this.toastr.error('Er liep iets mis bij het verwijderen van de melding', 'Melding verwijderen mislukt');
            }
        });
    }

    async approve() {
        this.submitting = true;

        try {
            if (this.changes) {
                const updateNotificationPayLoad: UpdateNotificationPayLoad = {
                    id: this.id,
                    ervaringDescr: this.FORM.ervaringDescr
                };
                await lastValueFrom(this.defaultService.notificationsUpdateNotification(updateNotificationPayLoad));
            }
            await lastValueFrom(this.defaultService.notificationsApproveNotification(this.id)).then((result) => {
                this.submitting = false;
                this.toastr.success('Melding werd succesvol goedgekeurd', 'Melding goedgekeurd');
                this.activeModal.close();
            });
        } catch (error) {
            this.validation = error.error.details;
            this.submitting = false;
        }
    }

    formChange() {
        this.changes = true;
    }

    dismiss() {
        if (this.canExit()) {
            this.activeModal.dismiss();
        }
    }

    canExit(): boolean {
        if (
            (!this.changes && !this.submitting) ||
            confirm('Er zijn niet-opgeslagen wijzigingen. Weet u weker dat u deze pagina wil verlaten?')
        ) {
            this.changes = false;
            return true;
        } else {
            return false;
        }
    }
}
