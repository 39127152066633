import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResourceService } from '../../../services/resource.service';
import { ProductService } from '../../../services/product.service';
import { forkJoin, Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HelpersService } from '../../../services/helpers.service';
import { SelectionService } from '../../../services/selection.service';
import { EditorService } from '../../../services/editor.service';
import { NewsletterService } from '../../../services/newsletter.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ChangeDetectorRef, OnDestroy } from '@angular/core';
import moment from 'moment';

import { HttpClient } from '@angular/common/http';
import { RecipientsModalComponent } from '../recipients-modal/recipients-modal.component';
import { formatTsPipe } from '../../../utils/pipes';
import { RecipientsSentModalComponent } from '../recipients-sent-modal/recipients-sent-modal.component';
import { Router } from '@angular/router';
import { ConfirmSendComponent } from '../confirm-send/confirm-send.component';
import Sortable from 'sortablejs';

@Component({
    selector: 'app-newsletter-detail-modal',
    templateUrl: './newsletter-detail-modal.component.html',
    styleUrls: ['./newsletter-detail-modal.component.scss']
})
export class NewsletterDetailModalComponent implements OnInit, OnDestroy {
    @Input() uuid?: any;
    @Input() isSent?: boolean = false;
    @Output() created = new EventEmitter();
    @Output() updated = new EventEmitter();
    @Output() sent = new EventEmitter();

    lineChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        spanGaps: true,
        scales: {
            x: {
                title: {
                    display: false,
                    text: ''
                },
                grid: {
                    display: false
                },
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: 5,
                    maxRotation: 0
                }
            },
            y: {
                title: {
                    display: false,
                    text: ''
                },
                min: 1,
                max: 10,
                ticks: {
                    stepSize: 1,
                    beginAtZero: false,
                    min: 1,
                    max: 10,
                    maxRotation: 0,
                    callback: function (value, index, values) {
                        return value;
                    }
                }
            }
        },
        plugins: {
            legend: {
                display: false,
                align: 'start',
                position: 'bottom',
                usePointStyle: false,
                labels: {
                    boxWidth: 12,
                    boxHeight: 12
                }
            },
            tooltip: {
                callbacks: {
                    title: function (tooltipItems, data) {
                        return tooltipItems[0].dataset.data[tooltipItems[0].dataIndex].title;
                    },
                    label: function (tooltipItem, data) {
                        var value = tooltipItem.dataset.data[tooltipItem.dataIndex].y;
                        return `${tooltipItem.dataset.label}: ${value.toString().replace('.', ',')}`;
                    }
                }
            }
        }
    };

    charts: any = {};

    newsletter: any = null;
    pages: { code: any; name: any; notify?: boolean; disabled?: boolean }[];
    activePage: string;
    activeSection: string;
    popoverHelper: any = null;
    validation: any = {};
    changes: boolean = false;
    ready: boolean = false;
    loading: boolean = false;
    saving: boolean = false;
    submitting: boolean = false;
    actionId = null;

    typeARR: any[] = [];
    templateARR: any[] = [];
    editorTypeARR: any[] = [];
    uploadQueue: { [key: string]: File } = {};
    files: File[] = [];

    helper: any = { activeIndex: null, activeModal: null, statsMode: 'day' };
    typeaheadModels: any = {};

    formSTR: any = {
        newsletterContent: [
            {
                type: 'newsletterHeader',
                title: '',
                subtitle: '',
                right: '',
                imagePath: ''
            }
        ],
        name: '',
        newsletterTypeId: undefined,
        newsletterTemplateId: undefined
    };
    template: any = null;

    selectionsFormSTR: any = { selections: [] };
    selectionSearchSTR: any = { term: '' };
    selections: any = null;
    selectionsLoading: boolean = false;
    amountOfRecipients: number = null;
    amountOfRecipientsLoadingCalls: number = 0;

    preview: any = null;
    testRecipients: string = '';

    onDestroy$: Subject<void> = new Subject<void>();

    sortableContainer: Sortable = null;

    constructor(
        public ActiveModal: NgbActiveModal,
        public NewsletterService: NewsletterService,
        public SelectionService: SelectionService,
        public ResourceService: ResourceService,
        public ProductService: ProductService,
        public HelpersService: HelpersService,
        public EditorService: EditorService,
        public sanitizer: DomSanitizer,
        private ModalService: NgbModal,
        private cd: ChangeDetectorRef,
        private formatTsPipe: formatTsPipe,
        private httpClient: HttpClient,
        public Router: Router
    ) {}

    ngOnInit(): void {
        if (this.formSTR.newsletterContent?.[0]) {
            this.formSTR.newsletterContent[0].right = this.formatTsPipe.transform(
                moment().toISOString(),
                'DD MMMM YYYY'
            );
        }
        this.templateARR = this.ResourceService.getNewsletterTemplates().map((item) => {
            return { title: item.name, value: item.id };
        });
        this.typeARR = this.ResourceService.getNewsletterTypes().map((item) => {
            return { title: item.name, value: item.id };
        });
        this.editorTypeARR = this.EditorService.getBlockTypes(['text', 'text-right', 'text-left', 'image', 'video']);
        this.EditorService.init();

        // edit or create
        if (this.uuid) {
            this.getNewsletter();
        } else {
            this.newsletter = {};
            this.ready = true;
            this.activeSection = 'content';
            this.initSortable();
        }
        // inits
        this.initPages();
        // scroll
        setTimeout(() => {
            const scrollElement = document.querySelector('.modal.main-modal');
            const that = this;
            scrollElement.addEventListener('scroll', function (event) {
                if (that.helper.activeIndex === null || that.helper.activeIndex === undefined) return;
                that.scrollCheck();
            });
        }, 1);
    }

    initSortable() {
        setTimeout(() => {
            const el = document.getElementById('sort-blocks');
            if (el) {
                let that = this;
                this.sortableContainer = new Sortable(el, {
                    animation: 150,
                    handle: '.sortable-handle',
                    dragClass: 'sortable-dragging',
                    ghostClass: 'sortable-ghost',
                    forceFallback: true,
                    onEnd: function (event) {
                        that.formSTR.newsletterContent.splice(
                            event.newIndex,
                            0,
                            that.formSTR.newsletterContent.splice(event.oldIndex, 1)[0]
                        );
                    },
                    onStart: function ($event) {
                        that.setActiveIndex(null);
                    }
                });
            }
        }, 1);
    }

    scrollCheck() {
        const toolbar: any = document.querySelector('.editor-block.active .ql-toolbar');
        const toolbarHeight: any = toolbar?.offsetHeight;
        const container: any = document.querySelector('.editor-block.active .ql-container');
        if (!toolbar) return;
        if (!toolbar.style.width) {
            toolbar.style.width = `${toolbar.offsetWidth}px`;
        }
        if (window.innerHeight - toolbar.getBoundingClientRect().bottom < 16) {
            if (toolbar.classList.contains('ql-toolbar--fixed')) return;
            // console.log('add');
            toolbar.classList.add('ql-toolbar--fixed');
        } else if (window.innerHeight - container.getBoundingClientRect().bottom >= 32 + toolbarHeight) {
            if (!toolbar.classList.contains('ql-toolbar--fixed')) return;
            // console.log('remove');
            toolbar.classList.remove('ql-toolbar--fixed');
        }
    }

    getNewsletter(afterSave?: boolean) {
        if (!afterSave) this.loading = true;

        // newsletter meta observable
        const observables$: Observable<any>[] = [];
        const newsletterObservable$ = this.NewsletterService.getNewsletterMetadata(this.uuid).pipe(
            tap((next) => {
                let newsletterContent = next?.newsletterContent || [];
                this.newsletter = { ...next, newsletterContent: newsletterContent };
            })
        );
        observables$.push(newsletterObservable$);
        // newsletter files observable
        const filesObservable$ = this.NewsletterService.getNewsletterFiles(this.uuid).pipe(
            tap((next: File[]) => {
                this.uploadQueue = {};
                this.files = next;
            })
        );
        if (!this.isSent) observables$.push(filesObservable$);

        forkJoin(observables$).subscribe((next) => {
            setTimeout(() => {
                if (this.newsletter.newsletterTemplateId && !afterSave)
                    this.getTemplate(this.newsletter.newsletterTemplateId);
                if (this.newsletter.sendStatus.toLowerCase() != 'draft') {
                    this.isSent = true;
                    if (this.newsletter.sendTS && moment(this.newsletter.sendTS).isBefore(moment('2022-12-16'))) {
                        this.helper.hideClicked = true;
                    }
                    this.initPages(this.isSent);
                    // if (this.isSent) this.initStats();
                    this.pageChange('preview');
                    this.ready = true;
                    this.loading = false;
                    return;
                }
                this.resetForm();
                this.ready = true;
                // this.loading = false;
                // if (afterSave) this.saving = false;
                this.initRecipients(afterSave);
            }, 1);
        });
    }

    saveNewsletterContent(draft?: boolean) {
        this.saving = true;
        !draft ? (this.submitting = true) : '';
        const FORM = JSON.parse(JSON.stringify(this.formSTR));
        for (let i = 0; i < FORM.newsletterContent.length; i++) {
            const block = FORM.newsletterContent[i];
            if (block?.html) {
                // zero width spaces
                block.html = block.html.replace(/[\u200B-\u200D\uFEFF]/g, '');
                block.html = block.html.replace(new RegExp('&#xFEFF;', 'g'), ``);
            }
        }
        this.NewsletterService.createOrUpdateNewsletter(FORM).subscribe({
            next: async (next) => {
                if (Object.keys(this.uploadQueue).length) {
                    let addFilesResponse: any[];
                    await this.NewsletterService.addFiles(this.uploadQueue, this.uuid || next.uuid)
                        .then((res: any) => {
                            addFilesResponse = res;
                        })
                        .catch((error) => {
                            console.error('Patch files error...', error);
                        });
                    for (let i = 0; i < addFilesResponse.length; i++) {
                        const file = addFilesResponse[i];
                        if (!this.uploadQueue[file.uuid]) {
                            continue;
                        }

                        const formParams = new FormData();
                        formParams.append('file', this.uploadQueue[file.uuid]);
                        await new Promise((resolve, reject) => {
                            return this.httpClient.put(file.blobUrl, formParams).subscribe({
                                complete: () => {
                                    return resolve(true);
                                },
                                error: (error) => {
                                    console.error('Upload file error...', error);
                                }
                            });
                        });
                    }
                }
                this.validation = {};
                this.changes = false;
                // this.saving = false;
                // !draft && this.nextPage();
                let created = false;
                if (!this.uuid) {
                    this.unblockPages();
                    this.uuid = next.uuid;
                    this.formSTR.uuid = this.uuid;
                    created = true;
                    // this.getNewsletter(true);
                    // this.created.emit(this.formSTR);
                } else {
                    // this.getNewsletter(true);
                    // this.updated.emit(this.newsletter);
                }
                this.saveSelections(draft, created);
            },
            error: (error) => {
                if (error?.error?.details) {
                    this.validation = error.error.details;
                }
                this.saving = false;
                this.submitting = false;
            }
        });
    }

    // START EDITOR
    blockAdded(block, index) {
        this.formChange();
        this.setActiveIndex(index, 1);
    }
    blockModalOpen() {
        if (this.helper.activeModal === 0) return true;
        return this.helper.activeModal;
    }
    setActiveIndex(index, timeout?: number) {
        if (!timeout) {
            this.helper.activeIndex = index;
            this.cd.detectChanges();
        } else {
            setTimeout(() => {
                this.helper.activeIndex = index;
                this.cd.detectChanges();
            }, timeout);
        }
        setTimeout(() => {
            this.scrollCheck();
        }, 1);
    }
    getActiveIndex() {
        return this.helper.activeIndex;
    }
    dismissPopover() {
        setTimeout(() => {
            this.popoverHelper = null;
        }, 1);
    }
    deleteSection(index) {
        if (index === undefined) return;
        if (
            this.formSTR.newsletterContent[index]?.fileId &&
            this.uploadQueue[this.formSTR.newsletterContent[index].fileId]
        ) {
            delete this.uploadQueue[this.formSTR.newsletterContent[index].fileId];
        }
        this.formSTR.newsletterContent.splice(index, 1);
        this.formChange();
        this.setActiveIndex(null);
        this.dismissPopover();
        this.initSortable();
    }
    addFileToBlock(file: File, index?: number) {
        const uuid = this.HelpersService.getUUID();
        this.uploadQueue[uuid] = file;
        this.formSTR.newsletterContent[index].fileId = uuid;
        this.initSortable();
    }
    removeFileFromBlock(uuid: string, index?: number) {
        if (this.uploadQueue[uuid]) delete this.uploadQueue[uuid];
        if (index !== undefined) this.formSTR.newsletterContent[index].fileId = null;
        this.initSortable();
    }
    // custom header

    // END EDITOR

    goToNewSelection() {
        if (this.canExit()) {
            this.changes = false;
            this.ActiveModal.dismiss();
            this.Router.navigate(['/selecties'], { queryParams: { selection: 'new' } });
        }
    }

    resetTemplate() {
        this.template = null;
    }

    getTemplate(templateId: number, changed?: boolean) {
        if (!templateId) {
            return;
        }
        this.NewsletterService.getTemplateById(templateId).subscribe((next) => {
            this.template = {
                ...next,
                mail_header: this.sanitizer.bypassSecurityTrustHtml(next['mail_header']),
                mail_footer: this.sanitizer.bypassSecurityTrustHtml(next['mail_footer'])
            };
            let root = document.documentElement;
            root.style.setProperty('--editor-primary-color', next['primary_color']);
            root.style.setProperty('--editor-secondary-color', next['secondary_color']);
            root.style.setProperty('--editor-tertiary-color', next['tertiary_color']);
            if (changed) {
                this.formSTR.newsletterContent[0].title = next['header_default_title'];
                this.formSTR.newsletterContent[0].subtitle = next['header_default_subtitle'];
                this.formSTR.newsletterContent[0].imagePath = next['header_image_url'];
            }
            this.initSortable();
            setTimeout(() => {
                var elements = document.getElementsByClassName('no-enter');
                for (var i = 0; i < elements.length; i++) {
                    elements.item(i).addEventListener('keydown', (event) => {
                        if (event['which'] === 13) {
                            event.preventDefault();
                        }
                    });
                }
            }, 1);
        });
    }

    log(e) {
        console.log(e);
    }

    formChange() {
        this.changes = true;
    }

    resetForm() {
        this.formSTR = { ...this.newsletter };
    }

    canEdit(sectionCode) {
        return true;
    }

    canDelete() {
        return false;
    }

    delete() {
        return;
    }

    sectionIsActive(code) {
        return this.activeSection == code;
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
    }

    canExit(): boolean {
        if (!this.changes || confirm('You have unsaved changes, are you sure you want to leave?')) {
            this.changes = false;
            return true;
        } else {
            return false;
        }
    }

    getActivePage() {
        return this.pages.find((item) => {
            return item.code === this.activePage;
        });
    }

    lastPageActive() {
        return this.activePage == this.pages[this.pages.length - 1].code;
    }

    firstPageActive() {
        return this.activePage == this.pages[0].code;
    }

    nextPage() {
        const currentIndex = this.pages
            .map((page) => {
                return page.code;
            })
            .indexOf(this.activePage);
        if (currentIndex != -1) {
            this.pageChange(this.pages[currentIndex + 1].code);
        }
    }

    prevPage() {
        const currentIndex = this.pages
            .map((page) => {
                return page.code;
            })
            .indexOf(this.activePage);
        if (currentIndex != -1) {
            this.pageChange(this.pages[currentIndex - 1].code);
        }
    }

    pageChange($event) {
        if (this.activePage === $event || !this.canExit()) return;
        this.changes = false;
        this.activePage = $event;
        switch ($event) {
            case 'content':
                if (this.newsletter.newsletterTemplateId != this.formSTR.newsletterTemplateId)
                    this.getTemplate(this.newsletter.newsletterTemplateId);
                this.resetForm();
                this.initRecipients();
                break;
                // case 'recipients':
                //     this.initRecipients();
                break;
            case 'preview':
                this.initPreview();
                break;
            case 'send':
                this.initRecipients();
                break;
        }
    }

    initPages(sent?: boolean) {
        let pages = [
            { code: 'content', name: 'Inhoud en ontvangers', icon: 'mail' },
            { code: 'preview', name: 'Preview', icon: 'newsletter' },
            { code: 'send', name: 'Verzenden', icon: 'send-outline' }
        ];
        if (!this.uuid) {
            pages = pages.map((item) => {
                return { ...item, disabled: true };
            });
        }
        if (sent) {
            pages = [
                { code: 'preview', name: 'Preview', icon: 'newsletter' },
                { code: 'stats', name: 'Statistieken', icon: 'stats' }
            ];
        }
        this.pages = pages;
        if (!sent) {
            this.activePage = this.pages[0].code;
        }
    }
    unblockPages() {
        this.pages = this.pages.map((item) => {
            return { ...item, disabled: false };
        });
    }

    getSender() {
        const id = this.isSent ? this.newsletter.newsletterTypeId : this.formSTR.newsletterTypeId;
        if (id) {
            return this.ResourceService.getNewsletterTypeById(id)['sender'];
        }
    }

    // RECIPIENTS

    initRecipients(afterSave?: boolean) {
        if (!afterSave) {
            this.selectionsFormSTR = { selections: [] };
            this.amountOfRecipients = null;
            this.amountOfRecipientsLoadingCalls = 0;
        }
        this.selectionSearchSTR = { term: '' };
        this.selections = null;
        if (this.newsletter?.selectionUuids?.length && !afterSave) {
            this.loading = true;
            const SEARCH = {
                ...this.selectionSearchSTR,
                startRow: 0,
                rPP: 999,
                newsletterUuid: this.uuid
            };
            this.SelectionService.getSelections(SEARCH).subscribe(async (next) => {
                this.selectionsFormSTR.selections = next.data.map((item) => {
                    return {
                        ...item,
                        newsletterType: {
                            title:
                                `${item.amountOfConditions} voorwaarde${item.amountOfConditions > 1 ? 'n' : ''}` +
                                ' - ' +
                                this.getTitleMultiValues(
                                    item.newsletterTypes?.map((subItem) => {
                                        return subItem.name;
                                    }),
                                    1
                                ),
                            tooltipSubtitle: this.getTooltipValue(
                                item.newsletterTypes?.map((subItem) => {
                                    return subItem.name;
                                }),
                                1
                            ),
                            stampsSubtitle: this.getStampForTootltip(
                                item.newsletterTypes?.map((subItem) => {
                                    return subItem.name;
                                }),
                                1
                            )
                        }
                    };
                });
                if (!this.isSent) {
                    for (let selection of this.selectionsFormSTR.selections) {
                        this.selectionsFormSTR.selections.find((selectionItem) => {
                            return selectionItem.uuid == selection.uuid;
                        }).amount = await this.SelectionService.calculateSubscriptionsAmount(
                            selection.filterJSON,
                            selection.uuid
                        );
                    }
                }
                this.getAmountOfRecipients();
            });
        } else {
            this.loading = false;
            this.saving = false;
            this.initSortable();
        }
    }

    getTitleMultiValues(values: any[], numberValues) {
        if (values && values.length > numberValues) {
            return values.slice(0, numberValues).join(', ');
        } else {
            return values ? values.join(', ') : '';
        }
    }

    getTooltipValue(values: any[], numberValues) {
        if (values && values.length > numberValues) {
            return values.slice(numberValues).join(', ');
        }
    }

    getStampForTootltip(values: any[], numberValues) {
        if (values && values.length > numberValues) {
            return [{ value: '+' + (values.length - numberValues), classList: 'stamp--small' }];
        }
    }

    getAmountOfRecipients() {
        if (!this.selectionsFormSTR.selections.length) {
            this.amountOfRecipients = null;
            this.loading = false;
            this.saving = false;
            this.initSortable();
            return;
        }
        this.amountOfRecipientsLoadingCalls++;
        const searchSTR = {
            startRow: 0,
            selectionUuids: this.selectionsFormSTR.selections.map((item) => {
                return item.uuid;
            })
        };
        this.SelectionService.getSelectionSubscriptions(searchSTR).subscribe({
            next: (next: any) => {
                this.amountOfRecipients = next.rows;
                this.amountOfRecipientsLoadingCalls--;
                this.loading = false;
                this.saving = false;
                this.initSortable();
            },
            error: (error) => {
                this.amountOfRecipientsLoadingCalls--;
                this.loading = false;
                this.saving = false;
            }
        });
    }

    openRecipientsModal() {
        const modalRef = this.ModalService.open(RecipientsModalComponent, {
            windowClass: 'medium-modal'
        });
        modalRef.componentInstance.selectionUuids = this.selectionsFormSTR.selections.map((item) => {
            return item.uuid;
        });
        modalRef.componentInstance.newsletterUuid = this.uuid;
    }

    openRecipientsSentModal() {
        const modalRef = this.ModalService.open(RecipientsSentModalComponent, {
            windowClass: 'medium-modal'
        });
        modalRef.componentInstance.recipients = this.newsletter.recipients;
    }

    async addSelection(selection) {
        const amount = await this.SelectionService.calculateSubscriptionsAmount(selection.filterJSON, selection.uuid);
        const index = this.selectionsFormSTR.selections
            .map((item) => {
                return item.uuid;
            })
            .indexOf(selection.uuid);
        if (index != -1) return;
        const selectionResult = {
            ...selection,
            amount: amount,
            newsletterType: {
                title:
                    `${selection.amountOfConditions} voorwaarde${selection.amountOfConditions > 1 ? 'n' : ''}` +
                    ' - ' +
                    this.getTitleMultiValues(
                        selection.newsletterTypes?.map((subItem) => {
                            return subItem.name;
                        }),
                        1
                    ),
                tooltipSubtitle: this.getTooltipValue(
                    selection.newsletterTypes?.map((subItem) => {
                        return subItem.name;
                    }),
                    1
                ),
                stampsSubtitle: this.getStampForTootltip(
                    selection.newsletterTypes?.map((subItem) => {
                        return subItem.name;
                    }),
                    1
                )
            }
        };
        this.selectionsFormSTR.selections.push(selectionResult);
        this.changes = true;
        this.selections = null;
        this.selectionSearchSTR.term = '';
        this.getAmountOfRecipients();
    }
    removeSelection(selection) {
        const index = this.selectionsFormSTR.selections
            .map((item) => {
                return item.uuid;
            })
            .indexOf(selection.uuid);
        if (index == -1) return;
        this.selectionsFormSTR.selections.splice(index, 1);
        this.changes = true;
        this.getAmountOfRecipients();
    }

    clearSelections() {
        this.selectionsFormSTR = { selections: [] };
        this.selectionSearchSTR = { term: '' };
        this.selections = null;
        this.amountOfRecipients = null;
        this.amountOfRecipientsLoadingCalls = 0;
        this.newsletter = {
            ...this.newsletter,
            selectionUuids: this.selectionsFormSTR.selections.map((item) => {
                return item.uuid;
            })
        };
        if (this.newsletter) this.newsletter.selectionUuids = [];
    }

    // OLD
    async searchSelections() {
        this.selectionsLoading = true;
        const SEARCH = {
            ...this.selectionSearchSTR,
            startRow: 0,
            rPP: 999,
            newsletterType: this.formSTR.newsletterTypeId || this.newsletter.newsletterTypeId
        };
        this.SelectionService.getSelections(SEARCH).subscribe(async (next) => {
            this.selections = next.data;
            this.selectionsLoading = false;
            if (!this.selections) return;
            for (let selection of this.selections) {
                this.selections.find((selectionItem) => {
                    return selectionItem.uuid == selection.uuid;
                }).amount = await this.SelectionService.calculateSubscriptionsAmount(
                    selection.filterJSON,
                    selection.uuid
                );
            }
        });
    }

    // NEW
    getNewsletterTypeOptions = (term) => {
        const SEARCH = {
            ...this.selectionSearchSTR,
            term: term,
            startRow: 0,
            rPP: 999,
            newsletterType: this.formSTR.newsletterTypeId || this.newsletter.newsletterTypeId
        };
        return this.SelectionService.getSelections(SEARCH);
    };

    selectTypeaheadItem($event, code) {
        switch (code) {
            case 'selection':
                if ($event?.item?.uuid) {
                    this.addSelection($event.item);
                }
                break;
        }
    }

    saveSelections(draft?: boolean, created?: boolean) {
        const FORM = {
            selectionUuids: this.selectionsFormSTR.selections.map((item) => {
                return item.uuid;
            })
        };
        this.saving = true;
        !draft ? (this.submitting = true) : '';
        this.NewsletterService.saveSelections(FORM, this.uuid).subscribe({
            next: (next) => {
                this.changes = false;
                this.saving = false;
                this.submitting = false;
                this.newsletter = {
                    ...this.newsletter,
                    selectionUuids: this.selectionsFormSTR.selections.map((item) => {
                        return item.uuid;
                    })
                };
                // this.nextPage();
                !draft && this.nextPage();
                if (created) {
                    this.getNewsletter(true);
                    this.created.emit(this.formSTR);
                } else {
                    this.getNewsletter(true);
                    this.updated.emit(this.newsletter);
                }
            },
            error: (error) => {
                this.saving = false;
                this.submitting = false;
            }
        });
    }

    filterSelectionResults = (selection: any) => {
        return (
            this.selectionsFormSTR.selections
                .map((item) => {
                    return item.uuid;
                })
                .indexOf(selection.uuid) == -1
        );
    };

    // PREVIEW

    initPreview() {
        this.preview = null;
        this.testRecipients = '';
        this.loading = true;
        // get preview
        this.NewsletterService.getContent(this.uuid).subscribe((next) => {
            this.preview = this.sanitizer.bypassSecurityTrustHtml(next.content);
            // if (this.isSent) {
            //     this.initRecipients();
            // } else this.loading = false;
            this.loading = false;
            this.initLinks();
        });
    }

    canSendTest() {
        let emails = this.getTestEmails();
        if (!emails || this.saving) return false;
        for (let i = 0; i < emails.length; i++) {
            const email = emails[i];
            if (!this.validEmail(email)) {
                return false;
            }
        }
        return true;
    }

    validEmail(email) {
        const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    getTestEmails(): string[] {
        if (!this.testRecipients) return null;
        let emails = this.testRecipients.split(',');
        emails.forEach((item) => {
            item = item.trim();
        });
        return emails;
    }

    sendTest() {
        this.saving = true;
        this.submitting = true;
        this.NewsletterService.sendTest(this.getTestEmails(), this.uuid).subscribe((next) => {
            this.testRecipients = '';
            this.saving = false;
            this.submitting = false;
            this.newsletter.testSent = true;
        });
    }

    // SEND
    checklistValidByCode(code: string) {
        switch (code) {
            case 'subject':
                return this.newsletter.name;
            case 'sender':
                return this.getSender();
            case 'content':
                return this.template?.name;
            case 'recipients':
                return this.amountOfRecipients;
            case 'test':
                return this.newsletter.testSent;
        }
        return false;
    }
    checklistValid() {
        return (
            this.checklistValidByCode('subject') &&
            this.checklistValidByCode('sender') &&
            this.checklistValidByCode('content') &&
            this.checklistValidByCode('recipients') &&
            this.checklistValidByCode('test')
        );
    }

    sendNewsletter() {
        this.saving = true;
        this.submitting = true;
        this.NewsletterService.send(this.uuid).subscribe((next) => {
            this.sent.emit(this.newsletter);
            setTimeout(() => {
                this.changes = false;
                this.submitting = false;
                this.ActiveModal.dismiss();
            }, 400);
        });
    }

    openConfirmSendNewsletter() {
        const modalRef = this.ModalService.open(ConfirmSendComponent, {
            windowClass: 'confirm-modal'
        });
        modalRef.result.then((returnValue) => {}).finally(() => {});
        modalRef.componentInstance.confirm.subscribe(() => {
            this.sendNewsletter();
        });
    }

    sendStopped() {
        this.saving = true;
        this.NewsletterService.sendStopped(this.uuid).subscribe((next) => {
            this.sent.emit(this.newsletter);
            setTimeout(() => {
                this.changes = false;
                this.ActiveModal.dismiss();
            }, 400);
        });
    }

    iframeLoaded = () => {
        const statsFrame: any = document.getElementById('statsFrame');
        if (statsFrame) {
            statsFrame.height = '';
            statsFrame.height = statsFrame.contentWindow.document.body.scrollHeight + 'px';
        }
        this.helper.statsFrameLoaded = true;
        let that = this;
        window.addEventListener('message', function (e: MessageEvent) {
            let msg: any = {};
            if (typeof e.data === 'string') {
                try {
                    msg = JSON.parse(e.data) || {};
                } catch (error) {
                    console.error('Failed to parse JSON:', error);
                    return;
                }
            } else if (typeof e.data === 'object' && e.data !== null) {
                msg = e.data;
            }

            if (msg.mode) {
                that.helper.statsMode = msg?.mode;
            }
            if (msg.nav) {
                if (msg.nav === 'preview') {
                    that.pageChange('preview');
                }
            }
        });
    };

    initLinks() {
        setTimeout(() => {
            const linkElements = document.querySelectorAll('[data-link-ref]');
            for (let i = 0; i < linkElements.length; i++) {
                const element: any = linkElements[i];
                const ref = element.getAttribute('data-link-ref');
                element.style.position = 'relative';
                const info = document.createElement('div');
                info.classList.add('link-info');
                const lnk = this.newsletter.links.find((item) => {
                    return item.ref === ref;
                });
                info.setAttribute('data-info-ref', lnk?.ref);
                const infoInner = document.createElement('div');
                infoInner.classList.add('link-info-inner');
                infoInner.innerText = lnk?.clicks || '0';
                info.append(infoInner);
                const linkListEl: any = document.querySelector("[data-ref='" + lnk?.ref + "'] a");
                info.addEventListener(
                    'mouseover',
                    function () {
                        linkListEl.style.background = '#fffb99';
                    },
                    false
                );
                info.addEventListener(
                    'mouseout',
                    function () {
                        linkListEl.style.background = 'transparent';
                    },
                    false
                );
                if (lnk?.ref) {
                    element.append(info);
                }
            }
        }, 1);
    }

    highlightLink(ref) {
        const el: any = document.querySelector("[data-info-ref='" + ref + "'] div");
        el && (el.style.background = '#fffb99');
    }

    unhighlightLink(ref) {
        const el: any = document.querySelector("[data-info-ref='" + ref + "'] div");
        el && (el.style.background = '#EEF9F8');
    }

    @HostListener('window:beforeunload', ['$event'])
    beforeUnloadHandler(event: Event) {
        if (this.changes) return false;
    }
}
