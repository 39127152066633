import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';

@Component({
    selector: 'app-field-currency',
    templateUrl: './field-currency.component.html',
    styleUrls: ['./field-currency.component.scss']
})
export class FieldCurrencyComponent implements OnInit, OnChanges {
    @Input() label?: string;
    @Input() error?: string;
    @Input() optionalString?: string;
    @Input() placeholder?: string;
    @Input() model: string;
    localModel: string;
    @Output() modelChange = new EventEmitter();
    @Output() enter = new EventEmitter();

    constructor(private currencyPipe: CurrencyPipe) {}

    ngOnInit(): void {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.model) {
            this.localModel = this.model;
            setTimeout(() => {
                this.format(true);
            }, 1);
        }
    }

    onBlur($event?) {
        this.format();
    }

    format(preventEmit?: boolean) {
        if (!this.localModel) {
            this.localModel = undefined;
            if (!preventEmit) {
                this.modelChange.emit(undefined);
            }
            return;
        }
        let numericValue = this.localModel.toString().replace(/\./g, ',');
        const n = numericValue.lastIndexOf(',');
        if (n >= 0 && numericValue.length) {
            numericValue = numericValue.substring(0, n) + '.' + numericValue.substring(n + 1);
        }
        numericValue = numericValue.replace(/,/g, '');
        numericValue = numericValue.replace(/€/g, '');
        if (isNaN(parseFloat(numericValue))) {
            this.localModel = undefined;
            if (!preventEmit) {
                this.modelChange.emit(undefined);
            }
        } else {
            let returnValue = this.currencyPipe
                .transform(parseFloat(numericValue).toString().replace(/,/g, '.'), '€ ')
                .replace(/,/g, '.');
            const n = returnValue.lastIndexOf('.');
            if (n >= 0 && returnValue.length) {
                returnValue = returnValue.substring(0, n) + ',' + returnValue.substring(n + 1);
            }
            this.localModel = returnValue;
            if (!preventEmit) {
                this.modelChange.emit(parseFloat(numericValue));
            }
        }
    }
}
