import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs';
import { PageService } from 'src/app/services/page.service';
import { DefaultService } from 'src/app/utils/api';

@Component({
    selector: 'app-add-subpage-modal',
    templateUrl: './add-subpage-modal.component.html',
    styleUrls: ['./add-subpage-modal.component.scss']
})
export class AddSubpageModalComponent implements OnInit {
    @Input() page?: any;
    @Input() subpage?: any;
    @Output() created = new EventEmitter();
    ready: boolean = false;
    loading: boolean = false;
    activeSubpage: any = {};
    validation: any = {};
    changes: boolean = false;
    submitting: boolean = false;
    id: any;

    formSTR: any = {};

    constructor(
        public ActiveModal: NgbActiveModal,
        public pageService: PageService,
        public DefaultService: DefaultService
    ) {}

    ngOnInit(): void {
        setTimeout(() => {
            this.ready = true;
        }, 1);
        this.formSTR.mainPage = this.page.id;
        if (this.subpage) {
            this.id = this.subpage.id;
            this.formSTR = this.subpage;
        }
    }

    formChange() {
        this.changes = true;
    }

    resetForm() {
        this.formSTR = { ...this.activeSubpage };
    }

    submit() {
        const FORM = this.formSTR;
        this.submitting = true;
        this.pageService
            .updateInsertPage(FORM, this.id || null)
            .pipe(
                finalize(() => {
                    this.submitting = false;
                })
            )
            .subscribe({
                next: (next) => {
                    this.validation = {};
                    this.changes = false;
                    this.created.emit();
                    this.ActiveModal.dismiss();
                },
                error: (error) => {
                    this.validation = error.error.details;
                }
            });
    }

    canExit(): boolean {
        if (!this.changes || confirm('Je hebt onopgeslagen wijzigingen, weet je zeker dat je wilt afsluiten?')) {
            this.changes = false;
            return true;
        } else {
            return false;
        }
    }
}
