<div class="field field-file {{classes}}">
    <span *ngIf="label" class="label">{{label}}<span *ngIf="optionalString">({{optionalString}})</span></span>
    <label class="file-wrap" [class.error]="error" [class.saving]="loading">
        <!-- click/dropzone -->
        
        
        @if (loading) {
           <app-spinner></app-spinner>
        } @else {
            <div class="upload-icon">
                <i class="im-icon im-icon-upload"></i>
            </div>
        }  
        <div class="text-wrap">
            <div class="sub-text text-center">
                <h3 class="font-24">
                    @if (loading) {
                        Aan het uploaden...
                    } @else {
                        Upload {{subtext}}
                    }                    
                </h3>
                <p>
                    @if (loading) {
                        Even geduld, het bestand is aan het uploaden
                    } @else {
                        Je kan het bestand naar dit gebied slepen of <a class="format-link underline">browse op je computer</a> om het bestand te selecteren
                    }  
                </p>
            </div>
        </div>        

        <input class="input-file" id="file-input" type="file" (click)="$event.target.value = null" (change)="fileChange($event.target.files[0])" [accept]="allowedExtensions" [disabled]="disabled">
    </label>
</div>

