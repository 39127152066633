<div class="page">

    <!-- top bar -->
    <section class="top-bar">
        <!-- search left -->
        <h1 class="">Dashboard</h1>
        <div class="d-flex align-items-center">
            <!-- overview -->
            <a [href]="appOverviewUrl" class="d-flex align-items-center portal-link">
                <i class="im-icon im-icon-portal-dots"></i>
            </a>
            <!-- profile right -->
            <div class="profile-wrap d-flex align-items-center">
                <app-profile-section></app-profile-section>
            </div>
        </div>
    </section>
    <!-- end top bar -->

    @if (!ready) {
        <app-spinner [centerVertically]="true"></app-spinner>
    } @else {
        <section>
            <div class="dashboard-title">
                <div class="icon-wrap">
                    <i class="im-icon im-icon-member-card title-icon"></i>
                    <span class="count-indicator">{{totalRows}}</span>
                </div>
                <h2>Goed te keuren meldingen</h2>
            </div>

            <app-table
                [emptyState]="'Geen resultaten'"
                [heads]="tableHeads" 
                [data]="data" 
                [RPP]="RPP"
                [startRow]="startRow"
                [totalRows]="totalRows"
                [loading]="loading"
                [ready]="ready"
                (clickData)="openDetail($event.item.id)"
                (startRowChange)="setStartRow($event)"
            ></app-table>
        </section>
    }
</div>