<div class="editor-block-text" [class.active]="active" [class.inactive]="!active" (click)="emitActive()">

    <!-- edit -->
    <div class="display-hide" [class.d-block]="active">
        <quill-editor 
            (onEditorCreated)="editorCreated($event)"
            customToolbarPosition="bottom"
            placeholder="Schrijf hier je tekst..."
            [modules]="modules"
            [(ngModel)]="model"
            (onContentChanged)="onChange($event)">
            <div quill-editor-toolbar>
                <span class="ql-formats">
                    <button class="ql-header" value="1"></button>
                    <button class="ql-header" value="2"></button>
                    <button class="ql-bold" [title]="''"></button>
                    <button class="ql-italic"></button>
                    <button class="ql-underline"></button>
                    <select class="ql-align" [title]="'Aligment'">
                        <option selected></option>
                        <option value="center"></option>
                        <option value="right"></option>
                        <option value="justify"></option>
                    </select>
                    <button (click)="openLinkModal()" id="link-button"><i class="im-icon im-icon-link"></i></button>
                    <!-- <button class="ql-link"></button> -->
                    <button class="ql-list" value="ordered"></button>
                    <button class="ql-list" value="bullet"></button>
                    <button class="ql-indent" value="-1"></button>
                    <button class="ql-indent" value="+1"></button>
                    <button class="ql-script" value="sub"></button>
                    <button class="ql-script" value="super"></button>
                    <button class="ql-clean"></button>
                </span>
            </div>
        </quill-editor>
    </div>

    <!-- view -->
    <ng-container *ngIf="!active">
        <div style="pointer-events: none;" [innerHtml]="sanitizer.bypassSecurityTrustHtml(model)" class="ql-output"></div>
        <div style="pointer-events: none;" *ngIf="!active && !model" (click)="emitActive()" class="editor-emptystate">Schrijf hier je tekst...</div>
    </ng-container>

</div>