<span class="close-modal-outside"><i (click)="ActiveModal.dismiss(); " class="im-icon im-icon-x-light"></i></span>
<div class="modal-wrap">
    <ng-container *ngIf="!ready">
        <app-spinner [centerVertically]="true"></app-spinner>
    </ng-container>
    <div *ngIf="page" class="d-flex detail-modal">
        <!-- start form -->
        <div class="d-flex flex-column w-100 flex-grow-1 scroll-y">
            <ng-container *ngIf="loading">
                <div style="height: 100%;">
                    <app-spinner [centerVertically]="true"></app-spinner>
                </div>
            </ng-container>
            <ng-container *ngIf="!loading && ready">
                <div class="">
                    <!-- header -->
                    <div class="detail-modal-header d-flex flex-row justify-content-between align-items-center" style="min-height: 36px;">
                        <h1 style="min-height: 36px;">{{page.title ? page.title : 'Nieuwe pagina'}}</h1>
                        <div class="h-100 d-flex align-items-center" *ngIf="!sectionIsActive('general')">
                            <!-- edit -->
                            <button *ngIf="true" class="button tertiary ml-xs" (click)="activeSection = 'general'" [class.disabled]="saving"><i class="im-icon im-icon-pencil icon-l mr-xxxs"></i><span>Wijzigen</span></button>
                        </div>
                        <div class="h-100 d-flex align-items-center" *ngIf="sectionIsActive('general')">
                            <button class="button tertiary" (click)="canExit() && (id ? activeSection = null : ActiveModal.dismiss()); resetForm(); HelpersService.removeParam('werkzameStofID');" [class.disabled]="saving"><span>Annuleer</span></button>
                            <app-button classes="primary-default ml-xs" icon="check-round-filled" text="Bewaren" [disabled]="saving" [submitting]="submitting" (emitClick)="createUpdatePage()"></app-button>
                        </div>
                    </div>
                    <div class="detail-modal-body">
                        <!-- content -->
                        <div class="row display-row">
                            <div class="col-xl-4">
                                <!-- Titel -->
                                <div class="display-content">
                                    <ng-container *ngIf="!sectionIsActive('general')">
                                        <div class="display-label" [class.text-color]="sectionIsActive('general')">Titel</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{page.title | display}}</span>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="sectionIsActive('general')">
                                        <app-field-text
                                            [model]="formSTR.title" 
                                            (modelChange)="formSTR.title = $event; formChange();" 
                                            label="Titel"
                                            [error]="validation.title"
                                            placeholder="Titel"
                                            maxWidth="420">
                                        </app-field-text>
                                    </ng-container>                            
                                </div>
                            </div>
                        </div>
                        <!-- Inhoud -->
                        <div class="row display-row">
                            <div class="col-md-8">
                                <div class="display-content">
                                    <ng-container  *ngIf="!sectionIsActive('general')">
                                        <div class="display-label">Inhoud</div>
                                        <div class="display-value d-flex flex-row" style="border: 1px solid #D7DADD; border-radius: 4px; padding: 24px;">
                                            <div class="page-content">
                                                <span class="page-content" [innerHTML]="page.content | safe : 'html'"></span>
                                            </div>
                                        </div>
                                    </ng-container>          
                                    <ng-container *ngIf="sectionIsActive('general')">
                                        <app-field-richtext 
                                            [model]="formSTR.content" 
                                            (modelChange)="formSTR.content = $event; formChange();" 
                                            [label]="'Inhoud' | display"
                                            [expanded]="true"
                                            [error]="validation.content">
                                        </app-field-richtext>
                                    </ng-container>                
                                </div>
                            </div>
                        </div>
                        <div class="row display-row">
                            <div class="col-md-4">
                                <!-- Date last change -->
                                <div class="display-content">
                                    <ng-container *ngIf="!sectionIsActive('general')">
                                        <div class="display-label">Datum laatste wijziging</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{page.editPageTS | formatTs: 'DD-MM-YYYY'}}</span>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="sectionIsActive('general')">
                                        <app-field-date 
                                            [model]="formSTR.editPageTS" 
                                            (modelChange)="formSTR.editPageTS = $event; formChange();" 
                                            label="Datum laatste wijziging"
                                            placeholder="dd-mm-yyyy"
                                            [error]="validation.editPageTS">
                                        </app-field-date>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <div class="row display-row">
                            <div class="col-md-4">
                                <!-- published -->
                                <div class="display-content">
                                    <ng-container *ngIf="!sectionIsActive('general')">
                                        <div class="display-label">Gepubliceerd</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{getPublishedText() | display}}</span>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="sectionIsActive('general')">
                                        <app-field-radios 
                                            class="d-block"
                                            label="Gepubliceerd"
                                            [model]="formSTR.isPublished"
                                            (modelChange)="formSTR.isPublished = $event"
                                            [error]="validation.isPublished"
                                            optionalString=""
                                            [options]="[{title: 'Ja', value: 1},{title: 'Nee', value: 2}]"
                                            [optionWidth]="80"
                                            [optionColumnWidth]="">
                                        </app-field-radios>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <div class="table--detail" *ngIf="!sectionIsActive('general') && !page.mainPage">
                            <h2>Subpagina's</h2>
                            <table *ngIf="page.subpages">
                                <thead>
                                    <tr>
                                        <th style="width: 33.33%;">Titel</th>
                                        <th style="width: 33.33%;">Laatst gewijzigd</th>
                                        <th style="width: 33.33%;">Gewijzigd door</th>
                                        <th style="width: .1%; min-width: 38px;"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let subpage of page.subpages; index as $i">
                                        <td class="subpage-title"><span (click)="openSubpageDetail(subpage)">{{subpage.title | display}}</span></td>
                                        <td>{{subpage.editPageTS | formatTs: 'DD-MM-YYYY'}}</td>
                                        <td>{{subpage.lastModifiedBy | display}}</td>
                                        <td>
                                            <div class="actions-popover-button">
                                                <i class="im-icon im-icon-actions" [ngbPopover]="popContent" [autoClose]="'outside'" (hidden)="helper.deletesubpage = null" popoverClass="actions-popover" [placement]="['bottom-right', 'auto']"></i>
                                            </div>
                                            <ng-template #popContent>
                                                <ul>
                                                    <li (click)="openAddNewSubpageModal(subpage)"><i class="im-icon im-icon-pencil"></i> <span>Wijzigen</span></li>
                                                    <li><button (click)="helper.deleteSubpage = $i" [class.disabled]="saving" class="delete-red"><i class="im-icon im-icon-trash"></i><span>Verwijderen</span></button></li>
                                                    <app-confirm-delete (confirm)="deleteSubpage(subpage);" (cancel)="helper.deleteSubpage = null" [open]="helper.deleteSubpage === $i" positionClass="left" positionClassV="bottom"></app-confirm-delete>
                                                </ul>
                                            </ng-template>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="nosubpage" *ngIf="!page.subpages">Er zijn geen subpagina's aan deze pagina gelinkt</div>
                            <div class="detail-modal-table-add">
                                <button class="add-subpage" (click)="openAddNewSubpageModal()"><i class="im-icon im-icon-plus-round"></i>Subpagina toevoegen</button>
                            </div>
                        </div>
                    </div>
                    <div class="detail-modal-footer" *ngIf="page.id">
                        <p *ngIf="page.createdTS">Aangemaakt op {{page.createdTS | formatTs: 'DD-MM-YYYY'}} <span *ngIf="page.createdBy">door {{page.createdBy}}</span></p>
                        <p>Gewijzigd op {{page.lastModifiedTS | formatTs: 'DD-MM-YYYY'}}<span *ngIf="page.lastModifiedBy"> door {{page.lastModifiedBy}}</span></p> 
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>