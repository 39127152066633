<div>
    <h1>Link toevoegen</h1>

    <div class="row display-row">
        <div class="col-12">
            <div class="display-content">
                <app-field-radios 
                    class="d-block"
                    label="Type"
                    [model]="formSTR.type" 
                    (modelChange)="formSTR.type = $event"
                    [options]="linkTypeOptions"
                    [optionWidth]=""
                    [optionColumnWidth]="">
                </app-field-radios>
            </div>
        </div>
    </div>
    <div class="row display-row">
        <div class="col-12">
            <div class="display-content">
                <app-field-text 
                    [model]="formSTR.url" 
                    (modelChange)="formSTR.url = $event;" 
                    [instant]="true"
                    [label]="getLabel()"
                    [placeholder]="getPlaceholder()">
                </app-field-text>
            </div>
        </div>
    </div>
    <div class="row display-row">
        <div class="col-12">
            <div class="display-content">
                <app-field-text 
                    [model]="formSTR.text" 
                    (modelChange)="formSTR.text = $event;"
                    [instant]="true"
                    optionalString="Optioneel"
                    label="Weergavetekst"
                    placeholder="Weergavetekst">
                </app-field-text>
            </div>
        </div>
    </div>
    <div class="d-flex align-items-center">
        <!-- submit -->
        <button class="button default" (enter)="submit()" (click)="submit()" [class.disabled]="!canSubmit()">Bewaar</button>
        <!-- cancel -->
        <button class="button-link blue cancel ml-m" (click)="ActiveModal.dismiss()" [class.disabled]="false"><span>Annuleer</span></button>
    </div>

</div>