import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ConfigService } from './config.service';

import { tap } from 'rxjs/operators';
import { Config } from '../models/common';
import { DefaultService } from '../utils/api';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    private user$ = new BehaviorSubject<object>(null);
    private config: Config;

    constructor(private ConfigService: ConfigService, private DefaultService: DefaultService) {
        this.config = this.ConfigService.getConfig();
    }

    public login() {
        // return this.DefaultService.userLogin().pipe(
        //     tap(
        //         (next) => {},
        //         (error) => {
        //             this.logout();
        //         }
        //     )
        // );
        return new Observable((observer) => {}).pipe(tap((next) => {}));
    }

    public fetchUser(): any {
        return this.DefaultService.userMe().pipe(
            tap((next) => {
                this.setUser(next);
            })
        );
    }

    public logout() {
        this.DefaultService.userLogout().subscribe((next) => {
            if (this.ConfigService.getConfig().logoutUrl) {
                window.location.href = this.ConfigService.getConfig().logoutUrl;
            }
        });
    }

    public onUnauth() {
        this.DefaultService.resourcesGetConfig().subscribe((next) => {
            if (next.appOverviewUrl) {
                window.location.href = next.appOverviewUrl;
            }
        });
    }

    public getUser(): Observable<object> {
        return this.user$.asObservable();
    }

    private setUser(user: object) {
        this.user$.next(user);
    }
}
