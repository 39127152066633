<span class="close-modal-outside"><i (click)="ActiveModal.dismiss(); HelpersService.removeParam('contactID');" class="im-icon im-icon-x-light"></i></span>
<div class="modal-wrap">
    <ng-container *ngIf="!ready">
        <app-spinner [centerVertically]="true"></app-spinner>
    </ng-container>
    <div *ngIf="contact" class="d-flex detail-modal">

        <!-- start form -->
        <div class="d-flex flex-column w-100 flex-grow-1 scroll-y">
            <ng-container *ngIf="loading">
                <div style="height: 100%;">
                    <app-spinner [centerVertically]="true"></app-spinner>
                </div>
            </ng-container>
            <ng-container *ngIf="!loading && ready">
                <div class="">
                <!-- <div class="pl-m pr-m pt-s pb-s modal-form-section" [class.edit-section]="sectionIsActive('general') && id"> -->
                    <!-- header -->
                    <div class="detail-modal-header d-flex flex-row justify-content-between align-items-center" style="min-height: 36px;">
                        <h1 style="min-height: 36px;">Contact: {{contact.name}} op {{contact.createdTS | formatTs: "DD MMMM YYYY"}}</h1>
                        <div class="h-100 d-flex align-items-center" *ngIf="this.activeSection !== 'general'">
                            <!-- delete -->
                            <div *ngIf="true" class="position-relative" appClickOutside (clickOutside)="actionId = null">
                                <button class="button tertiary delete-red" (click)="actionId = 'delete'" [class.disabled]="saving"><i class="im-icon im-icon-trash delete-red icon-l mr-xxxs" style="transform: translateY(-1px);"></i><span>Verwijderen</span></button>
                                <app-confirm-delete (confirm)="delete();" (cancel)="actionId = null" [open]="actionId === 'delete'" positionClass="left" positionClassV="bottom"></app-confirm-delete>
                            </div>
                        </div>
                    </div>

                    <div class="detail-modal-body">
                        <!-- content -->
                        <div class="row display-row mw-1200-px">
                            <div class="col-xl-4">
                                <!-- Afzender -->
                                <div class="display-content">
                                    <div class="display-label">Afzender</div>
                                    <div class="display-value d-flex flex-row">
                                        <span>{{contact.name | display}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row display-row mw-1200-px">
                            <div class="col-xl-6">
                                <!-- Email -->
                                <div class="display-content">
                                    <div class="display-label">Email</div>
                                    <div class="display-value d-flex flex-row">
                                        <span>{{contact.email | display}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row display-row mw-1200-px">
                            <div class="col-xl-4">
                                <!-- Telefoon -->
                                <div class="display-content">
                                    <div class="display-label">Telefoon</div>
                                    <div class="display-value d-flex flex-row">
                                        <span>{{contact.phone | display}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row display-row mw-1200-px">
                            <div class="col-xl-12">
                                <!-- Boodschap -->
                                <div class="display-content">
                                    <div class="display-label">Boodschap</div>
                                    <div class="display-value d-flex flex-row">
                                        <span class="message">{{contact.message | display}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
        <!-- end form -->
    </div>
</div>