import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { tap } from 'rxjs/operators';
import { DefaultService } from '../utils/api';
import { HelpersService } from './helpers.service';
@Injectable({
    providedIn: 'root'
})
export class ActiveSubstanceService {
    constructor(
        private DefaultService: DefaultService,
        private toastr: ToastrService,
        public HelpersService: HelpersService
    ) {}

    public getActiveSubstance(id: number) {
        return this.DefaultService.activeSubstanceGetActiveSubstance(id);
    }

    public deleteActiveSubstance(id: number) {
        return this.DefaultService.activeSubstancesDeleteActiveSubstance(id).pipe(
            tap((next) => {
                this.toastr.success('Actieve stof werd succesvol verwijderd', 'Actieve stof verwijderd');
            })
        );
    }

    public updateInsertActiveSubstance(body: any, id?: number) {
        return this.DefaultService.activeSubstancesUpdateOrInsertActiveSubstance(body).pipe(
            tap((next) => {
                if (id) {
                    this.toastr.success('Actieve stof werd succesvol opgeslagen', 'Actieve stof opgeslagen');
                } else {
                    this.toastr.success('Actieve stof werd succesvol aangemaakt', 'Actieve stof aangemaakt');
                }
            })
        );
    }
}
