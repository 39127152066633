import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { tap } from 'rxjs/operators';
import { DefaultService } from '../utils/api';
import { HelpersService } from './helpers.service';

@Injectable({
    providedIn: 'root'
})
export class DiscountService {
    constructor(
        private DefaultService: DefaultService,
        private toastr: ToastrService,
        private http: HttpClient,
        public HelpersService: HelpersService
    ) {}

    public getDiscounts(searchSTR: any) {
        return this.DefaultService.discountcodeGetDiscountcodes(searchSTR);
    }
    public exportDiscounts(searchSTR: any) {
        delete searchSTR.startRow;
        delete searchSTR.rRP;

        return this.DefaultService.discountcodeExportDiscountcodes(searchSTR).pipe(
            tap((response) => {
                this.HelpersService.downloadBlob(response, `kortingscodes-${moment().format('YYYY-MM-DD')}.xlsx`);
            })
        );
    }

    public getDiscount(id: number) {
        const searchSTR: any = { id: id };
        return this.DefaultService.discountcodeGetDiscountcodes(searchSTR);
    }
    public deleteDiscount(id: number) {
        return this.DefaultService.discountcodeDeleteDiscountcodeById(id).pipe(
            tap((next) => {
                this.toastr.success('De kortingscode werd succesvol verwijderd', 'Kortingscode verwijderd');
            })
        );
    }
    public updateInsertDiscount(body: any, id?: number) {
        return this.DefaultService.discountcodeUpdateOrInsertDiscountcode(body).pipe(
            tap((next) => {
                const multiple = body.type == 'unique' && body.uniqueAmount && body.uniqueAmount > 1;
                if (id) {
                    this.toastr.success('Kortingscode werd succesvol opgeslagen', 'Kortingscode opgeslagen');
                } else {
                    this.toastr.success(
                        `Kortingscode${multiple ? 's' : ''} werd${multiple ? 'en' : ''} succesvol aangemaakt`,
                        `Kortingscode${multiple ? 's' : ''} aangemaakt`
                    );
                }
            })
        );
    }
}
