<span class="close-modal-outside"><i (click)="ActiveModal.dismiss()" class="im-icon im-icon-x-light"></i></span>
<div class="modal-wrap">
    <ng-container *ngIf="!ready">
        <app-spinner [centerVertically]="true"></app-spinner>
    </ng-container>
    <div class="d-flex">

        <!-- Start form -->
        <div class="d-flex flex-column w-100 flex-grow-1 scroll-y">
            <ng-container *ngIf="loading">
                <div style="height: 100%;">
                    <app-spinner [centerVertically]="true"></app-spinner>
                </div>
            </ng-container>
            <ng-container *ngIf="!loading && ready">
                <div class="">
                    <!-- header -->
                    <div class="detail-modal-header d-flex flex-row justify-content-between align-items-center" style="min-height: 36px;">
                        <h1 style="min-height: 36px;">{{id ? formSTR.name : 'Nieuwe merknaam'}}</h1>
                    </div>
                </div>

                <div class="detail-modal-body">
                    <!-- content -->
                    <div class="row display-row mw-1200-px">
                        <div class="col-xl-4">
                            <!-- Merknaam -->
                            <div class="display-content">
                                <app-field-text
                                    [model]="formSTR.name" 
                                    (modelChange)="formSTR.name = $event; formChange();" 
                                    label="Merknaam"
                                    [error]="validation.name"
                                    placeholder="Merknaam">
                                </app-field-text>
                            </div>
                        </div>
                    </div>
                    <div class="row display-row mw-1200-px">
                        <div class="col-xl-12">
                            <!-- url -->
                            <div class="display-content">
                                <app-field-text
                                    [model]="formSTR.url" 
                                    (modelChange)="formSTR.url = $event; formChange();" 
                                    label="URL"
                                    optionalString="Optioneel"
                                    [error]="validation.url"
                                    placeholder="URL">
                                </app-field-text>
                            </div>
                        </div>
                    </div>
                    <div class="button-section-bottom">
                        <div class="h-100 d-flex align-items-center">
                            <app-button classes="primary-default" icon="check-round-filled" text="Bewaren" [disabled]="saving" [submitting]="submitting" (emitClick)="submit()"></app-button>
                            <button class="add-cancel" (click)="canExit() && (id ? activeSection = null : ActiveModal.dismiss()); ActiveModal.dismiss(); resetForm()" [class.disabled]="saving"><span>Annuleren</span></button>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>