<span class="close-modal-outside"><i (click)="ActiveModal.dismiss()" class="im-icon im-icon-x-light"></i></span>
<div class="modal-wrap">
    <ng-container *ngIf="!ready">
        <app-spinner [centerVertically]="true"></app-spinner>
    </ng-container>
    <div class="d-flex">

        <!-- Start form -->
        <div class="d-flex flex-column w-100 flex-grow-1 scroll-y">
            <ng-container *ngIf="loading">
                <div style="height: 100%;">
                    <app-spinner [centerVertically]="true"></app-spinner>
                </div>
            </ng-container>
            <ng-container *ngIf="!loading && ready">
                <div class="">
                    <!-- header -->
                    <div class="detail-modal-header d-flex flex-row justify-content-between align-items-center" style="min-height: 36px;">
                        <h1 style="min-height: 36px;">{{'Infobanner'}}</h1>
                    </div>
                </div>

                <div class="detail-modal-body">
                    <!-- content -->
                    <div class="row display-row mw-1200-px">
                        <div class="col-md-2">
                            <!-- Active -->
                            <div class="display-content">
                                <ng-container>
                                    <app-field-radios 
                                        class="d-block"
                                        label="Banner actief?"
                                        [model]="formSTR.active"
                                        (modelChange)="formSTR.active = $event"
                                        [error]="validation.active"
                                        optionalString=""
                                        [options]="[{title: 'Ja', value: true},{title: 'Nee', value: false}]"
                                        [optionWidth]="80"
                                        [optionColumnWidth]="">
                                    </app-field-radios>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <div class="form-section" [class.disabled]="!formSTR.active">
                        <div class="row display-row mw-1200-px">
                            <div class="col-xl-6">
                                <!-- Titel -->
                                <div class="display-content">
                                    <app-field-text
                                        [model]="formSTR.title" 
                                        (modelChange)="formSTR.title = $event; formChange();" 
                                        label="Titel"
                                        [error]="validation.title"
                                        placeholder="Titel">
                                    </app-field-text>
                                </div>
                            </div>
                        </div>
                        <div class="row display-row mw-1200-px">
                            <div class="col-xl-12">
                                <!-- Tekst -->
                                <div class="display-content">
                                    <app-field-textarea
                                        [model]="formSTR.text" 
                                        (modelChange)="formSTR.text = $event; formChange();" 
                                        label="Tekst"
                                        [error]="validation.text"
                                        placeholder="Tekst">
                                    </app-field-textarea>
                                </div>
                            </div>
                        </div>
                        <div class="row display-row mw-1200-px">
                            <div class="col-xl-12">
                                <!-- Knop tonen -->
                                <div class="display-content">
                                    <ng-container>
                                        <app-field-radios 
                                            class="d-block"
                                            label="Knop tonen"
                                            [model]="formSTR.buttonShow"
                                            (modelChange)="formSTR.buttonShow = $event"
                                            [error]="validation.buttonShow"
                                            optionalString=""
                                            [options]="[{title: 'Ja', value: 1},{title: 'Nee', value: 0}]"
                                            [optionWidth]="80"
                                            [optionColumnWidth]="">
                                        </app-field-radios>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <label class="display-label">Knop</label>
                        <div class="button-section" [class.disabled]="!formSTR.buttonShow" style="border: 1px solid #D7DADD; border-radius: 4px; padding: 24px;">
                            <div class="row display-row mw-1200-px">
                                <div class="col-xl-6">
                                    <!-- Knop tekst -->
                                    <div class="display-content">
                                        <app-field-text
                                            [model]="formSTR.buttonText" 
                                            (modelChange)="formSTR.buttonText = $event; formChange();" 
                                            label="Titel"
                                            [error]="validation.buttonText"
                                            placeholder="Titel">
                                        </app-field-text>
                                    </div>
                                </div>
                            </div>
                            <div class="row display-row mw-1200-px">
                                <div class="col-xl-12">
                                    <!-- buttonLink -->
                                    <div class="display-content">
                                        <app-field-text
                                            [model]="formSTR.buttonLink" 
                                            (modelChange)="formSTR.buttonLink = $event; formChange();" 
                                            label="Link"
                                            [error]="validation.buttonLink"
                                            placeholder="Link">
                                        </app-field-text>
                                    </div>
                                </div>
                            </div>
                            <div class="row display-row mw-1200-px" >
                                <div class="col-xl-5" style="margin-bottom: 0px;">
                                    <!-- New tab -->
                                    <input type="checkbox" id="newTab" name="newTab" [(ngModel)]="formSTR.newTab" (ngModelChange)="formChange()">
                                    <label for="newTab" class="newTab">Link in nieuw tablad openen</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="button-section-bottom">
                        <div class="h-100 d-flex align-items-center">
                            <app-button classes="primary-default" icon="check-round-filled" text="Bewaren" [disabled]="saving" [submitting]="submitting" (emitClick)="submit()"></app-button>
                            <button class="add-cancel" (click)="canExit() && (id ? activeSection = null : ActiveModal.dismiss()); ActiveModal.dismiss(); resetForm()" [class.disabled]="saving"><span>Annuleren</span></button>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>