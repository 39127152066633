<div class="field field-typeahead-small position-relative" [class.valid]="model">
    <span *ngIf="label" class="label">{{label}} <span *ngIf="optionalString">({{optionalString}})</span></span>
    <input id="typeahead-prevent-manual-entry_{{index}}"  
        type="text"
        [class.error-field]="error"
        [placeholder]="placeholder"
        [(ngModel)]="model"
        (blur)="onBlur()"
        (selectItem)="onSelect($event)"
        [ngbTypeahead]="search"
        [inputFormatter]="formatter"
        [resultFormatter]="formatter"
        [resultTemplate]="template"
        (change)="change($event)"
        (keyup.enter)="enter.emit()"
        [editable]="editable" />
    <span *ngIf="error" class="error-field-display">{{error}}</span>

    <ng-template #template let-r="result" let-t="term">
        <ngb-highlight [result]="r.value" [term]="t"></ngb-highlight>
        <div class="typeahead-after" *ngIf="afterARR && afterARR.length">
            <ng-container *ngFor="let item of afterARR; index as $i">
                <ng-container *ngIf="r[item]">{{r[item]}}{{$i !== afterARR.length-1 ? ' - ' : ''}}</ng-container>
            </ng-container>
        </div>
    </ng-template>
    
</div>