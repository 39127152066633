import {
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

export type MultiSelectIconOption = {
    title?: string;
    renderTitle?: boolean;
    value: string;
    iconPath?: string;
    iconClass?: string;
};
@Component({
    selector: 'app-search-multiselect-icon',
    templateUrl: './search-multiselect-icon.component.html',
    styleUrls: ['./search-multiselect-icon.component.scss']
})
export class SearchMultiselectIconComponent implements OnInit, OnChanges {
    @Input() options: MultiSelectIconOption[] = [];
    @Input() model: string[] = [];
    @Input() placeholder: string = '';
    @Input() allowClear: boolean = true;
    @Output() modelChange = new EventEmitter();

    selected: MultiSelectIconOption[] = undefined;

    @ViewChild('p', { static: false }) popover: NgbPopover;

    constructor() {}

    ngOnInit(): void {}

    ngOnChanges(changes: SimpleChanges) {
        if (!this.model) this.model = [];
        this.setSelected();
    }

    setSelected() {
        this.selected = this.options.filter((item) => this.model.indexOf(item.value) !== -1);
    }

    toggleSelect(option) {
        const index = this.model.indexOf(option.value);
        if (index === -1) {
            this.selected.push(option);
        } else this.selected.splice(index, 1);

        this.model = this.selected?.map((x) => x.value);
        this.emitData();
    }

    emitData() {
        this.modelChange.emit(this.model.length ? this.model : undefined);
    }

    clear() {
        this.popover.close();
        this.modelChange.emit(undefined);
    }
}
