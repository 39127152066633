import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DiscountService } from '../../../services/discount.service';
import { ResourceService } from '../../../services/resource.service';
import { ProductService } from '../../../services/product.service';
import { forkJoin, Observable, Subject } from 'rxjs';
import { ILeaveComponent } from 'src/app/services/leave-guard.service';
import { takeUntil, finalize, tap } from 'rxjs/operators';
import { customCurrencyPipe, percentagePipe } from '../../../utils/pipes';
import { HelpersService } from '../../../services/helpers.service';

@Component({
    selector: 'app-discount-detail-modal',
    templateUrl: './discount-detail-modal.component.html',
    styleUrls: ['./discount-detail-modal.component.scss']
})
export class DiscountDetailModalComponent implements OnInit, OnDestroy {
    @Input() id?: any;
    @Input() editMode?: any;
    @Output() created = new EventEmitter();
    @Output() updated = new EventEmitter();

    discount: any = null;
    pages: { code: any; name: any; notify?: boolean }[];
    form: any;
    activeSection: string;
    popoverHelper: any = null;
    validation: any = {};
    changes: boolean = false;
    productARR: any[];
    categoryARR: any[];

    ready: boolean = false;
    resourcesReady: boolean = false;
    loading: boolean = false;
    saving: boolean;
    actionId = null;
    submitting: boolean = false;

    formSTR: any = { worthType: 'valuta', discountOn: 'all', type: 'unique' };

    onDestroy$: Subject<void> = new Subject<void>();

    constructor(
        public ActiveModal: NgbActiveModal,
        public DiscountService: DiscountService,
        public ResourceService: ResourceService,
        public ProductService: ProductService,
        private percentagePipe: percentagePipe,
        private customCurrencyPipe: customCurrencyPipe,
        public HelpersService: HelpersService
    ) {}

    ngOnInit(): void {
        // edit or create
        if (this.id) {
            this.getDiscount();
        } else {
            this.discount = {};
            this.ready = true;
            this.activeSection = 'general';
        }
        // fetch resources
        this.initResources();
    }

    getDiscount() {
        this.loading = true;
        this.DiscountService.getDiscount(this.id)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((next) => {
                let discountOn = 'all';
                let used = 'Nee';
                if (next.data[0].type == 'group' && next.data[0].usedGroupCount) {
                    used = `${next.data[0].usedGroupCount} keer`;
                } else if (next.data[0].usedUnique) {
                    used = 'Ja';
                }
                let initialProduct = undefined;
                if (next.data[0].category) {
                    discountOn = 'category';
                } else if (next.data[0].product) {
                    discountOn = 'product';
                    initialProduct = {
                        title: next.data[0].productOrCategory,
                        key: next.data[0].product,
                        value: next.data[0].productOrCategory,
                        artikelCode: next.data[0].product_artikelcode
                    };
                }
                this.discount = {
                    ...next.data[0],
                    initialProduct: initialProduct,
                    discountOn: discountOn,
                    used: used,
                    worthDisplay:
                        next.data[0].worthType == 'percentage'
                            ? this.percentagePipe.transform(next.data[0].worth)
                            : this.customCurrencyPipe.transform(next.data[0].worth)
                };
                if (this.editMode) {
                    this.activeSection = 'general';
                }
                this.resetForm();
                this.ready = true;
                this.loading = false;
            });
    }

    formChange() {
        this.changes = true;
    }

    resetForm() {
        this.formSTR = { ...this.discount };
    }

    canEdit(sectionCode) {
        if (this.discount && !this.discount.usedGroupCount && !this.discount.usedUnique) {
            return true;
        }
        return false;
    }

    canDelete() {
        if (this.discount && !this.discount.usedGroupCount && !this.discount.usedUnique) {
            return true;
        }
        return false;
    }

    delete() {
        this.saving = true;
        this.actionId = null;
        this.DiscountService.deleteDiscount(this.id)
            .pipe(
                finalize(() => {
                    this.saving = false;
                })
            )
            .subscribe((next) => {
                this.activeSection = null;
                this.updated.emit(this.discount);
                this.changes = false;
                this.ActiveModal.dismiss();
            });
    }

    createUpdateDiscount() {
        const FORM = { ...this.formSTR };
        if (FORM.type != 'unique') {
            delete FORM.uniqueAmount;
        }
        if (FORM.discountOn != 'category') {
            delete FORM.category;
        }
        if (FORM.discountOn != 'product') {
            delete FORM.product;
        }
        this.saving = true;
        this.submitting = true;
        this.DiscountService.updateInsertDiscount(FORM, this.id || null)
            .pipe(
                finalize(() => {
                    this.saving = false;
                    this.submitting = false;
                })
            )
            .subscribe({
                next: (next) => {
                    this.validation = {};
                    this.activeSection = null;
                    this.discount.id ? this.updated.emit(this.discount) : this.created.emit(this.discount);
                    this.changes = false;
                    this.ActiveModal.dismiss();
                },
                error: (error) => {
                    this.validation = error.error.details;
                }
            });
    }

    initResources() {
        const observables$: Observable<any>[] = [];

        const productsObservable$ = this.ProductService.getProducts({ hasCost: true }).pipe(
            tap((next: any) => {
                this.productARR = next.data.map((item) => {
                    return { ...item, value: item.name, title: item.name, key: item.id, code: item.artikelCode };
                });
            })
        );
        observables$.push(productsObservable$);

        this.categoryARR = this.ResourceService.getResource('categories').map((item) => {
            return { value: item.code, title: item.name };
        });

        forkJoin(observables$).subscribe((next) => {
            this.resourcesReady = true;
        });
    }

    sectionIsActive(code) {
        return this.activeSection == code;
    }

    dismissPopover() {
        setTimeout(() => {
            this.popoverHelper = null;
        }, 1);
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
    }

    canExit(): boolean {
        if (!this.changes || confirm('You have unsaved changes, are you sure you want to leave?')) {
            this.changes = false;
            return true;
        } else {
            return false;
        }
    }

    @HostListener('window:beforeunload', ['$event'])
    beforeUnloadHandler(event: Event) {
        if (this.changes) return false;
    }
}
