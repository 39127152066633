import { Component, Input, OnInit, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
    selector: 'app-field-radios',
    templateUrl: './field-radios.component.html',
    styleUrls: ['./field-radios.component.scss']
})
export class FieldRadiosComponent implements OnInit {
    @Input() options: [{ title: string; value: any }];
    @Input() optionWidth: number;
    @Input() optionColumnWidth?: string;
    @Input() fillWidth?: boolean;
    @Input() canUncheck?: boolean;
    @Input() readOnly?: boolean;
    @Input() model: any;
    @Input() label?: string;
    @Input() error?: string;
    @Input() optionalString?: string;
    @Output() modelChange = new EventEmitter();

    constructor() {}

    ngOnInit(): void {}

    emit($event) {
        if (this.readOnly) {
            return;
        }
        this.modelChange.emit($event);
    }
}
