import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin, Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { switchPanelAnimation } from 'src/app/utils/animations';
import { DiscountService } from '../../services/discount.service';
import { ResourceService } from '../../services/resource.service';
import { HelpersService } from '../../services/helpers.service';
import { ProductService } from '../../services/product.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { DiscountDetailModalComponent } from 'src/app/components/modals/discount-detail-modal/discount-detail-modal.component';
import { HttpClient, HttpParams } from '@angular/common/http';
import { customCurrencyPipe, percentagePipe } from '../../utils/pipes';
import { Pipe, PipeTransform } from '@angular/core';
import { SearchService } from 'src/app/services/search.service';
import { ConfigService } from 'src/app/services/config.service';

@Component({
    selector: 'app-discount',
    templateUrl: './discount.component.html',
    styleUrls: ['./discount.component.scss'],
    animations: [switchPanelAnimation]
})
export class DiscountComponent implements OnInit, OnDestroy {
    onDestroy$: Subject<void> = new Subject<void>();
    popoverHelper: any = null;
    panelData: any;
    hasPanelData: boolean = false;
    searchSTR: any = {};
    SEARCHED: any = {};
    exportBusy: boolean = false;
    advancedSearchOpen: boolean = false;

    // table
    tableHeads: any = [
        { name: 'Code', code: 'code', sortable: true },
        { name: 'Type', code: 'type', sortable: true },
        { name: 'Gebruikt', code: 'used', sortable: true },
        { name: 'Product/categorie', code: 'productOrCategory', sortable: true },
        { name: 'Waarde', code: 'worth', sortable: true },
        { name: 'Geldig van', code: 'dateFrom', sortable: true },
        { name: 'Geldig tot', code: 'dateTo', sortable: true },
        { name: 'Status', code: 'isValid', sortable: true },
        { name: 'Gewijzigd door', code: 'lastModifiedBy', sortable: true },
        { name: '', code: 'actions' }
    ];
    tableSort: { code: string; dir: string } = { code: '', dir: '' };

    productARR: any[];
    discounts: any[] = null;
    startRow: number = 0;
    RPP: number = 20;
    totalRows: number = null;
    tableLoading: boolean = true;
    resourcesReady: boolean = false;
    searchType: string = 'advanced';
    ready: boolean = false;

    categoryARR: any[];

    constructor(
        private ModalService: NgbModal,
        public ResourceService: ResourceService,
        private DiscountService: DiscountService,
        public HelpersService: HelpersService,
        public ProductService: ProductService,
        private toastr: ToastrService,
        public route: ActivatedRoute,
        public router: Router,
        private percentagePipe: percentagePipe,
        private customCurrencyPipe: customCurrencyPipe,
        private SearchService: SearchService,
        public ConfigService: ConfigService
    ) {}

    ngOnInit(): void {
        this.initSearchSTR();
        this.initResources();
        const activeDiscountId = this.HelpersService.getParam('discountId');
        if (activeDiscountId) this.openCreateUpdateModal(activeDiscountId);
        const page = parseInt(this.HelpersService.getParam('page'));
        if (page) this.startRow = this.RPP * page - this.RPP;
        const query = this.HelpersService.getParam('query');
        if (query) this.searchSTR = JSON.parse(unescape(query));

        this.getDiscounts();

        this.categoryARR = this.ResourceService.getResource('categories').map((item) => {
            return { value: item.code, title: item.name };
        });
    }

    initResources() {
        const observables$: Observable<any>[] = [];

        const productsObservable$ = this.ProductService.getProducts({ hasCost: true }).pipe(
            tap((next: any) => {
                this.productARR = next.data.map((item) => {
                    return { ...item, value: item.name, key: item.id, code: item.artikelCode };
                });

                if (this.searchSTR.product) {
                    const product = this.productARR.find((item) => {
                        return item.id == this.searchSTR.product;
                    });
                    if (product) {
                        this.searchSTR.initialProduct = product;
                    }
                }
            })
        );
        observables$.push(productsObservable$);

        forkJoin(observables$).subscribe((next) => {
            this.resourcesReady = true;
        });
    }

    deleteDiscount(id: number) {
        this.dismissPopover();
        this.deSelectRows();
        this.DiscountService.deleteDiscount(id).subscribe((next) => {
            this.getDiscounts();
        });
    }

    dismissPopover() {
        setTimeout(() => {
            this.popoverHelper = null;
        }, 1);
    }

    initSearchSTR() {
        this.searchSTR = {};
    }

    getSearchQuery() {
        return {
            ...this.searchSTR,
            startRow: this.startRow,
            rRP: this.RPP,
            orderBy: this.getSortARR()
        };
    }

    getDefaultSearch() {
        return {};
    }

    getSortARR() {
        if (!this.tableSort || !this.tableSort.code) {
            return [];
        }
        return [`${this.tableSort.code} ${this.tableSort.dir}`];
    }

    search = (reset?: boolean, SEARCH?: any) => {
        this.advancedSearchOpen = false;
        SEARCH = SEARCH || this.searchSTR;
        this.searchSTR = { ...SEARCH };
        this.startRow = 0;
        if (reset) {
            this.initSearchSTR();
        }
        if (this.HelpersService.objectIsEmpty(SEARCH)) {
            this.HelpersService.removeParam('query');
        } else {
            const query = escape(JSON.stringify(SEARCH));
            this.HelpersService.addParam('query', query);
        }
        setTimeout(() => {
            this.HelpersService.addParam('page', (Math.ceil(this.startRow / this.RPP) + 1).toString());
        }, 1);
        this.getDiscounts();
    };

    getDiscounts() {
        this.SearchService.formatTags({ ...this.searchSTR }, this.getDefaultSearch()).then((response) => {
            this.SEARCHED = response;
            const searchSTR = this.getSearchQuery();
            this.deSelectRows();
            this.tableLoading = true;
            this.DiscountService.getDiscounts(searchSTR)
                .pipe(takeUntil(this.onDestroy$))
                .subscribe((next) => {
                    if (this.startRow && this.startRow >= next.rows) {
                        this.resetStartRow();
                        return;
                    }
                    this.discounts = next.data.map((item: any) => {
                        const actions = [];
                        if (!item.used) {
                            const deleteAction = {
                                name: 'Verwijderen',
                                code: 'delete',
                                icon: 'trash',
                                class: 'delete-red',
                                confirmDelete: true
                            };
                            actions.push(deleteAction);
                        }
                        let codeClassName = 'text-color';
                        if (!item.used) {
                            const editAction = {
                                name: 'Bewerken',
                                code: 'edit',
                                icon: 'pencil'
                            };
                            actions.push(editAction);
                        }

                        if (!item.isValid) {
                            codeClassName += ' line-through';
                        }
                        const discount = {
                            ...item,
                            productOrCategory:
                                item.product_artikelcode !== undefined
                                    ? item.productOrCategory + ' - ' + item.product_artikelcode
                                    : item.productOrCategory,
                            code: {
                                type: 'default',
                                classList: codeClassName,
                                value: item.code
                            },
                            used: item.type == 'group' ? item.usedGroupCount || 'Nee' : item.usedUnique ? 'Ja' : 'Nee',
                            type: this.ResourceService.getTypeByCode(item.type),
                            worth:
                                item.worthType == 'percentage'
                                    ? this.percentagePipe.transform(item.worth)
                                    : this.customCurrencyPipe.transform(item.worth),
                            dateFrom: {
                                type: 'ts',
                                ts: item.dateFrom,
                                format: 'DD-MM-YYYY'
                            },
                            dateTo: {
                                type: 'ts',
                                ts: item.dateTo,
                                format: 'DD-MM-YYYY'
                            },
                            isValid: item.isValid ? 'Geldig' : 'Inactief',
                            actions: {
                                type: 'actions',
                                actions: actions
                            }
                        };
                        return discount;
                    });
                    this.tableLoading = false;
                    this.ready = true;
                    this.totalRows = next.rows;
                    document.body.scrollTop = 0;
                    document.documentElement.scrollTop = 0;
                });
        });
    }

    changeStartRow(startRow: number) {
        this.startRow = startRow;
        this.HelpersService.addParam('page', (Math.ceil(this.startRow / this.RPP) + 1).toString());
        this.getDiscounts();
    }

    resetStartRow() {
        this.startRow = 0;
        this.HelpersService.addParam('page', (Math.ceil(this.startRow / this.RPP) + 1).toString());
        this.getDiscounts();
    }

    export() {
        const searchSTR = this.getSearchQuery();
        let params = new HttpParams();
        for (const key in searchSTR) {
            params = params.append(key, searchSTR[key]);
        }
        this.exportBusy = true;
        this.DiscountService.exportDiscounts(searchSTR).subscribe((data) => {
            setTimeout(() => {
                this.exportBusy = false;
            }, 500);
        });
    }

    tableClick(item: any, head: string) {
        switch (item.type) {
            case 'actions':
                break;
            default:
                this.hasPanelData ? this.selectRow(item) : this.openCreateUpdateModal(item.id);
                break;
        }
    }

    actionClick(item: any, action: string) {
        switch (action) {
            case 'edit':
                this.openCreateUpdateModal(item.id, true);
                break;
            case 'delete':
                this.deleteDiscount(item.id);
                break;
        }
    }

    selectRow(item: any) {
        this.discounts = this.discounts.map((x) => {
            if (x.id === item.id && !x.trActive) {
                x.trActive = true;
                this.panelData = item;
            } else if (x.id === item.id) {
                delete this.panelData;
                delete x.trActive;
            } else {
                delete x.trActive;
            }
            return x;
        });
    }

    deSelectRows() {
        if (this.discounts) {
            this.discounts = this.discounts.map((x) => {
                delete x.trActive;
                return x;
            });
        }
        delete this.panelData;
    }

    setSort(code: string, dir: string) {
        this.tableSort = { code: code, dir: dir };
        this.getDiscounts();
    }

    openCreateUpdateModal(id?: string, editMode?: boolean) {
        const modalRef = this.ModalService.open(DiscountDetailModalComponent, {
            windowClass: 'main-modal detail-modal',
            beforeDismiss: () => {
                return modalRef.componentInstance.canExit && modalRef.componentInstance.canExit();
            }
        });
        if (id) {
            this.HelpersService.addParam('discountId', id);
            modalRef.componentInstance.id = id;
            modalRef.componentInstance.editMode = editMode;
        }
        modalRef.result
            .then((returnValue) => {
                switch (returnValue) {
                    case 'delete':
                        this.deSelectRows();
                        this.getDiscounts();
                        break;
                }
            })
            .finally(() => {
                this.HelpersService.removeParam('discountId');
            });
        // on create
        modalRef.componentInstance.created.subscribe((discount: any) => {
            this.HelpersService.addParam('discountId', discount.id);
            this.search(true);
        });
        // on update
        modalRef.componentInstance.updated.subscribe((discount: any) => {
            this.getDiscounts();
        });
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
    }
}
