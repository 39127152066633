<div class="field field-image">



    <span *ngIf="label" class="label" [class.hide-text]="hideLabel">{{label}} <span class="optional-string" *ngIf="optionalString">({{optionalString}})</span></span>
    <div>
        <div *ngIf="!file && !url" class="file-input" ngfSelect [(file)]="file2" (filesChange)="fileChange()" accept="image/*" ngfDrop>
            <i class="im-icon im-icon-image"><span>Afbeelding uploaden ...</span></i>
            <button class="image-input-button" type="button">Afbeelding zoeken</button>
        </div>
        <div *ngIf="file || url" class="d-flex position-relative image-field-preview" style="margin-left: 5px;margin-right: 5px;">
            <img *ngIf="file" class="editor-image-preview" draggable="false" [ngfSrc]="file">
            <img *ngIf="url" class="editor-image-preview" draggable="false" [src]="url">          
            <div class="editor-image-overlay d-flex align-items-center justify-content-center">
                <span ngfSelect [(file)]="file2" (filesChange)="fileChange()" accept="image/*"><i class="icon icon-image-edit"></i></span>
                <span (click)="removeImage()"><i class="icon icon-image-delete"></i></span>
            </div>
        </div>
    </div>

    <span *ngIf="error" class="error-field-display">{{error}}</span>
</div>