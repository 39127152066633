import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, of, takeUntil } from 'rxjs';
import { PageDetailModalComponent } from 'src/app/components/modals/page-detail-modal/page-detail-modal.component';
import { ConfigService } from 'src/app/services/config.service';
import { HelpersService } from 'src/app/services/helpers.service';
import { SearchService } from 'src/app/services/search.service';
import { DefaultService } from 'src/app/utils/api';

@Component({
    selector: 'app-pages',
    templateUrl: './pages.component.html',
    styleUrls: ['./pages.component.scss']
})
export class PagesComponent implements OnInit, OnDestroy {
    onDestroy$: Subject<void> = new Subject<void>();
    panelData: any;
    hasPanelData: boolean = false;
    searchSTR: any = {};
    SEARCHED: any = {};

    //table
    tableHeads: any = [
        { name: 'Titel', code: 'title', width: '60%' },
        { name: 'Status', code: 'isPublished', width: '13%' },
        { name: 'Laatst gewijzigd', code: 'editPageTS', width: '13%' },
        { name: 'Gewijzigd door', code: 'lastModifiedBy', width: '13%' },
        { name: '', code: 'actions' }
    ];

    pages: any[] = null;
    tableLoading: boolean = false;
    ready: boolean = false;

    constructor(
        private ModalService: NgbModal,
        public HelpersService: HelpersService,
        public ConfigService: ConfigService,
        private DefaultService: DefaultService,
        private SearchService: SearchService
    ) {}

    ngOnInit(): void {
        const activePageId = this.HelpersService.getParam('pageId');
        if (activePageId) this.openCreateUpdateModal(activePageId);
        const query = this.HelpersService.getParam('query');
        if (query) this.searchSTR = JSON.parse(unescape(query));
        this.getPages();
    }

    getPages(): void {
        this.SearchService.formatTags({ ...this.searchSTR }, this.getDefaultSearch()).then((response) => {
            this.SEARCHED = response;
            const searchSTR = this.getSearchQuery();
            this.deSelectRows();
            this.tableLoading = true;
            this.DefaultService.pagesGetPages(searchSTR.term)
                .pipe(takeUntil(this.onDestroy$))
                .subscribe((next) => {
                    this.pages = next.map((item: any) => {
                        return this.getFormattedItem(item);
                    });
                    this.tableLoading = false;
                    this.ready = true;
                    document.body.scrollTop = 0;
                    document.documentElement.scrollTop = 0;
                    this.pages = this.sortPages(this.pages);
                });
        });
    }

    getFormattedItem(item: any) {
        const actions = [];
        actions.push({
            name: 'Wijzigen',
            code: 'edit',
            icon: 'pencil'
        });
        return {
            ...item,
            title: {
                type: 'pageTitle',
                classList: 'text-color',
                value: item.title
            },
            actions: {
                type: 'actions',
                actions: actions
            },
            editPageTS: {
                type: 'ts',
                ts: item.editPageTS,
                format: 'DD-MM-YYYY'
            },
            lastModifiedTS: {
                type: 'ts',
                ts: item.lastModifiedTS,
                format: 'DD-MM-YYYY'
            },
            isPublished: item.isPublished > 1 ? 'Niet gepubliceerd' : 'Gepubliceerd',
            isSubPage: item.mainPage ? true : false
        };
    }

    // sort the pages that subpage is under the mainpage
    sortPages(pages: any[]) {
        const mainPages = [];
        const subPages = [];
        const sortedPages = [];
        for (const page of pages) {
            if (page.mainPage === undefined) {
                mainPages.push(page);
            } else {
                subPages.push(page);
            }
        }
        for (const mainPage of mainPages) {
            sortedPages.push(mainPage);
            for (const subPage of subPages) {
                if (subPage.mainPage === mainPage.id) {
                    sortedPages.push(subPage);
                }
            }
        }
        return sortedPages;
    }

    openCreateUpdateModal(id?: string, editMode?: boolean): void {
        const modalRef = this.ModalService.open(PageDetailModalComponent, {
            windowClass: 'modal-modal detail-modal',
            beforeDismiss: () => {
                return modalRef.componentInstance.canExit && modalRef.componentInstance.canExit();
            }
        });
        if (id) {
            this.HelpersService.addParam('pageId', id);
            modalRef.componentInstance.id = id;
            modalRef.componentInstance.editMode = editMode;
        } else this.HelpersService.addParam('pageId', 'new');
        //update
        modalRef.result
            .then((returnValue) => {
                switch (returnValue) {
                    case 'delete':
                        this.getPages();
                        break;
                }
            })
            .finally(() => {
                this.HelpersService.removeParam('pageId');
                // setTimeout(() => {
                //     this.getPages();
                // }, 1000);
            });
        modalRef.componentInstance.created.subscribe((page: any) => {
            this.HelpersService.addParam('pageId', page.id);
            this.search(true);
        });
        modalRef.componentInstance.updated.subscribe((page: any) => {
            this.getPages();
            this.HelpersService.removeParam('pageId');
        });
    }

    initSearchSTR() {
        this.searchSTR = {};
    }

    getSearchQuery() {
        return {
            ...this.searchSTR
        };
    }

    search = (reset?: boolean, SEARCH?: any) => {
        SEARCH = SEARCH || this.searchSTR;
        this.searchSTR = { ...SEARCH };
        if (reset) {
            this.initSearchSTR();
        }
        if (this.HelpersService.objectIsEmpty(SEARCH)) {
            this.HelpersService.removeParam('query');
        } else {
            const query = escape(JSON.stringify(SEARCH));
            this.HelpersService.addParam('query', query);
        }
        this.getPages();
    };

    getDefaultSearch() {
        return {
            activeSubstance: []
        };
    }

    tableClick(item: any, head: string) {
        switch (item.type) {
            case 'actions':
                break;
            default:
                this.hasPanelData ? this.selectRow(item) : this.openCreateUpdateModal(item.id);
                break;
        }
    }

    actionClick(item: any, action: string) {
        switch (action) {
            case 'edit':
                this.openCreateUpdateModal(item.id, true);
                break;
        }
    }

    selectRow(item: any) {
        this.pages = this.pages.map((x) => {
            if (x.id === item.id && !x.trActive) {
                x.trActive = true;
                this.panelData = item;
            } else if (x.id === item.id) {
                delete this.panelData;
                delete x.trActive;
            } else {
                delete x.trActive;
            }
            return x;
        });
    }

    deSelectRows() {
        if (this.pages) {
            this.pages = this.pages.map((x) => {
                delete x.trActive;
                return x;
            });
        }
        delete this.panelData;
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
    }
}
