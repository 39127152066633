<div class="field field-text" [ngClass]="typeClass">
    <span *ngIf="label" class="label">{{label}} <span *ngIf="optionalString">({{optionalString}})</span></span>
    <div class="d-flex align-items-center w-100">
        <input 
            [class.error-field]="error"
            type="text"
            class="flex-grow-1"
            [(ngModel)]="localModel" 
            (blur)="onBlur($event)"
            (ngModelChange)="onChange()"
            (keyup.enter)="onBlur(null); enter.emit()"
            [placeholder]="placeholder ?? ''"
            [disabled]="disabled"
        />
        <!-- button -->
        <span class="after-string passive-gray" *ngIf="afterString">{{afterString | display}}</span>
    </div>
    <span *ngIf="error" class="error-field-display">{{error}}</span>
</div>