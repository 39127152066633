import { Injectable } from '@angular/core';
import { DefaultService } from '../utils/api';
import { ToastrService } from 'ngx-toastr';
import { tap } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class BannerService {
    constructor(private DefaultService: DefaultService, private toastr: ToastrService) {}

    public updateBanner(body: any, id?: number) {
        return this.DefaultService.bannersUpdateBanner(body).pipe(
            tap((next) => {
                if (id) {
                    this.toastr.success('Banner werd succesvol opgeslagen', 'Banner opgeslagen');
                } else {
                    this.toastr.success('Banner werd succesvol aangemaakt', 'Banner aangemaakt');
                }
            })
        );
    }

    public deleteBanner(id: number) {
        return this.DefaultService.bannerDeleteBanner(id).pipe(
            tap((next) => {
                this.toastr.success('Banner werd succesvol verwijderd', 'Banner verwijderd');
            })
        );
    }
}
