<div class="search-period" [class.search-period--open]="p?.isOpen()" [class.search-period--filled]="selected">

    <!-- field -->
    <div 
        class="inner" 
        [ngbPopover]="popContent" 
        #p="ngbPopover"
        placement="bottom-left bottom-right" 
        popoverClass="popover--search popover--search-period" 
        [autoClose]="'outside'" 
        (hidden)="setState(isValid())"
        >
        @if (!selected) {
            <!-- empty -->
            <span class="empty">{{placeholder}}</span>
        } @else {
            <!-- filled -->
            <span class="label">{{placeholder}}:</span>

            @if (FORM.code === 'code') {
                <!-- code -->
                <span class="value">{{selected?.title}}</span>
            } @else if (FORM.code === 'range') {
                <!-- range -->
                <span class="value">
                    <span *ngIf="FORM.from && !FORM.until" class="label label--range m-l-0">van</span> 
                    <ng-container *ngIf="FORM.from">{{FORM.from | display}}</ng-container> 
                    <span *ngIf="FORM.until" class="label label--range" [class.m-l-0]="!FORM.from" >tot</span> 
                    <ng-container *ngIf="FORM.until">{{FORM.until | display}}</ng-container>
                    <span class="label label--range"> jaar</span> 
                    
                </span>
            } @else if(FORM.code === 'maximum' || FORM.code === 'minimum'){
                <!-- range -->
                <span class="value">
                    <ng-container *ngIf="FORM.from">Min. {{FORM.from | display}} jaar</ng-container>
                    <ng-container *ngIf="FORM.until">Max. {{FORM.until | display}} jaar</ng-container>
                </span>
            } @else {
                <span class="value">
                    <ng-container *ngIf="FORM.from">{{FORM.from | display}} jaar</ng-container>
                </span>
            }
        }
        <!-- icon -->
        <i class="im-icon im-icon-triangle-rounded-down"></i>
    </div>

    <!-- popover -->
    <ng-template #popContent>
        <div class="popover--inner">

            
            <ng-container *ngFor="let option of codeOptions">
                <div class="popover-row" (click)="setSelected(option.value)">
                    <div class="radio-wrap">
                        <div class="fake-radio" [class.active]="option.value === selected?.value">
                            <span></span>
                        </div>
                    </div>

                    <div class="value-wrap">
                        <div class="descr">{{option.title}}</div>

                        @if (option.value === 'exact') {
                            <div 
                                class="range" 
                                style="min-height: 32px;"
                                *ngIf="option.value === selected?.value"
                                >
                                <app-field-text
                                    [type]="type"
                                    [instant]="type === 'integer'"
                                    [model]="FORM?.from" 
                                    [width]="'112px'"
                                    (modelChange)="FORM.from = $event; FORM.until = undefined; isValid()" 
                                ></app-field-text>
                            </div>
                        }
                        @if (option.value === 'minimum') {
                            <div 
                                class="range" 
                                style="min-height: 32px;"
                                *ngIf="option.value === selected?.value"
                                >
                                <app-field-text
                                    [type]="type"
                                    [instant]="type === 'integer'"
                                    [model]="FORM?.from" 
                                    [width]="'112px'"
                                    (modelChange)="FORM.from = $event; FORM.until = undefined; isValid()" 
                                ></app-field-text>
                            </div>
                        }
                        @if (option.value === 'maximum') {
                            <div 
                                class="range" 
                                style="min-height: 32px;"
                                *ngIf="option.value === selected?.value"
                                >
                                <app-field-text
                                    [type]="type"
                                    [instant]="type === 'integer'"
                                    [model]="FORM?.until" 
                                    [width]="'112px'"
                                    (modelChange)="FORM.until = $event; FORM.from = undefined; isValid()" 
                                ></app-field-text>
                            
                            </div>
                        }
                        @if (option.value === 'range') {
                            <div 
                                class="range" 
                                style="min-height: 32px;"
                                *ngIf="option.value === selected?.value"
                                >
                                <app-field-text
                                    [type]="type"
                                    [instant]="type === 'integer'"
                                    [model]="FORM?.from" 
                                    [width]="'112px'"
                                    (modelChange)="FORM.from = $event; isValid()"
                                    ></app-field-text>
                              
                                <span class="and">en</span>
                                <app-field-text
                                    [type]="type"
                                    [instant]="type === 'integer'"
                                    [model]="FORM?.until" 
                                    [width]="'112px'"
                                    (modelChange)="FORM.until = $event; isValid()" 
                                ></app-field-text>
                               
                            </div>
                        }
                    </div>
                </div>
            </ng-container>
            <div class="clear-wrap">
                <button [disabled]="!FORM?.code" class="confirm" (click)="confirm()">Toepassen</button>
                <button [disabled]="!FORM?.code" *ngIf="allowClear && selected" class="clear" (click)="clear()">Selectie leegmaken</button>
            </div>
        </div>
    </ng-template>
</div>