import {
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    output,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

type Codes = 'code' | 'exact' | 'range' | 'minimum' | 'maximum' | string;
type DateCode = 'exact' | 'range_from' | 'range_until' | 'minimum' | 'maximum';
type PreviousValues = {
    exact?: string;
    minimum?: string;
    maximum?: string;
    range_from?: string;
    range_until?: string;
};

@Component({
    selector: 'app-search-age',
    templateUrl: './search-age.component.html',
    styleUrls: ['./search-age.component.scss']
})
export class SearchAgeComponent implements OnInit, OnChanges {
    @Input() codeOptions: { title: string; value: string }[] = [];
    @Input() code: string = null;
    @Input() from: string = null;
    @Input() until: string = null;
    @Input() placeholder: string = '';
    @Input() allowClear: boolean = true;
    @Output() codeChange = new EventEmitter();
    @Output() fromChange = new EventEmitter();
    @Output() untilChange = new EventEmitter();
    @Output() search = new EventEmitter();
    @Output() emitClear = new EventEmitter();

    emitData = output<{ code: string; from: string; until: string }>();

    selected?: { title?: string; value?: string; valid?: boolean };

    FORM: {
        code: Codes;
        from: string;
        until: string;
    } = {
        code: undefined,
        from: undefined,
        until: undefined
    };

    previousValues: PreviousValues;

    @ViewChild('p', { static: false }) popover: NgbPopover;

    ngOnInit(): void {
        this.FORM = {
            code: this.code,
            from: this.from,
            until: this.until
        };
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!this.code && this.from && this.until) this.code = 'range';
        this.setSelected(this.code);
    }

    setSelected(code?: Codes) {
        if (this.FORM.code === code) return;
        // this.setPreviousValues(this.FORM);

        if (code) {
            this.selected = this.codeOptions.find((item) => {
                return item.value === code;
            });

            this.selected.valid = this.isValid();
        } else {
            this.selected = undefined;
        }

        const form = { code: code ?? undefined, from: undefined, until: undefined };
        switch (code) {
            case 'exact':
                form.from = this.previousValues?.exact ?? undefined;
                break;
            case 'minimum':
                form.from = this.previousValues?.minimum ?? undefined;
                break;
            case 'maximum':
                form.until = this.previousValues?.maximum ?? undefined;
                break;
            case 'range':
                form.from = this.previousValues?.range_from ?? undefined;
                form.until = this.previousValues?.range_until ?? undefined;
                break;
            default:
                break;
        }

        this.FORM = form;
    }

    setPreviousValues(form) {
        const previous: PreviousValues = {};
        switch (form.code) {
            case 'exact':
                previous.exact = form.from;
                break;
            case 'minimum':
                previous.minimum = form.from;
                break;
            case 'maximum':
                previous.maximum = form.until;
                break;
            case 'range':
                previous.range_from = form.from;
                previous.range_until = form.until;
                break;
            default:
                break;
        }

        this.previousValues = { ...this.previousValues, ...previous };
    }

    setState(valid: boolean) {
        if (!valid) {
            this.selected = undefined;
            this.FORM.code = undefined;
        }
    }

    confirm() {
        if (this.isValid()) {
            this.emitData.emit(this.FORM);
            this.popover.close();
        } else {
            this.clear();
        }
    }

    isValid(): boolean {
        if (this.selected?.value === 'range') {
            return this.FORM.from && this.FORM.until ? true : false;
        } else if (this.selected?.value === 'exact' || this.selected?.value === 'minimum') {
            return this.FORM.from ? true : false;
        } else {
            return this.FORM.until ? true : false;
        }
    }

    clear() {
        this.setState(false);
        this.emitData.emit({ code: undefined, from: undefined, until: undefined });
        this.popover.close();
    }
}
