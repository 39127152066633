import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { base64ToFile, ImageCroppedEvent } from 'ngx-image-cropper';
import { HelpersService } from '../../../../services/helpers.service';

@Component({
    selector: 'app-editor-image-link',
    templateUrl: './editor-image-link.component.html',
    styleUrls: ['./editor-image-link.component.scss']
})
export class EditorImageLinkComponent implements OnInit {
    @Output() confirmed = new EventEmitter();
    @Input() file: File = null;
    @Input() draftFile: File = null;
    @Input() aspectRatio: number = 1 / 1;
    @Input() resizeToWidth: number = 0;
    @Input() url: string = '';
    croppedBase64: any = null;
    localFile: File = null;
    ready: boolean = false;

    constructor(public ActiveModal: NgbActiveModal, public HelpersService: HelpersService) {}

    ngOnInit(): void {
        if (this.hasFile()) {
            this.ready = true;
        }
    }

    cropperReady() {
        setTimeout(() => {
            this.ready = true;
        }, 300);
    }

    imageCropped($event: ImageCroppedEvent) {
        this.croppedBase64 = $event.base64;
    }

    canSubmit() {
        return this.hasFile() || this.croppedBase64;
    }

    submit() {
        const FORM: { url: string; file?: File } = { url: this.HelpersService.fillHttp(this.url) };
        let file;

        if (!this.url) FORM.url = '';
        if (!this.croppedBase64) {
            this.confirmed.emit(FORM);
            this.ActiveModal.dismiss();
            return;
        } else {
            file = new File([base64ToFile(this.croppedBase64)], 'cropped.png', { type: 'image/png' });
            if (file) FORM.file = file;
            this.confirmed.emit(FORM);
            this.ActiveModal.dismiss();
        }
    }

    fileChange() {
        setTimeout(() => {
            this.ready = false;
        }, 1);
    }

    hasFile() {
        return this.file || this.draftFile;
    }
}
