import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FieldSwitchOptionsModel } from 'src/app/components/fields/field-switch/field-switch.component';
import { switchPanelAnimation } from 'src/app/utils/animations';
import { NewsletterService } from '../../services/newsletter.service';
import { ResourceService } from '../../services/resource.service';
import { HelpersService } from '../../services/helpers.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { NewsletterDetailModalComponent } from 'src/app/components/modals/newsletter-detail-modal/newsletter-detail-modal.component';
import { HttpClient, HttpParams } from '@angular/common/http';
import { formatTsPipe } from '../../utils/pipes';
import { Pipe, PipeTransform } from '@angular/core';
import { FilterButtonModel } from 'src/app/components/common/filter-button/filter-button.component';
import { ConfigService } from 'src/app/services/config.service';

@Component({
    selector: 'app-newsletters',
    templateUrl: './newsletters.component.html',
    styleUrls: ['./newsletters.component.scss'],
    animations: [switchPanelAnimation]
})
export class NewslettersComponent implements OnInit, OnDestroy {
    onDestroy$: Subject<void> = new Subject<void>();
    popoverHelper: any = null;
    panelData: any;
    hasPanelData: boolean = false;
    searchSTR: any = {};
    exportBusy: boolean = false;
    newsletterTypes: any[] = [];
    searchType: string = 'quick';
    switchOptions: FieldSwitchOptionsModel = [
        {
            title: 'Snelle filters',
            value: 'quick'
        }
        // {
        //     title: 'Geavanceerd zoeken',
        //     value: 'advanced'
        // }
    ];

    // filterARR: (FilterButtonModel | any)[] = [{ name: 'Alle nieuwsbrieven', count: null, filter: null }];
    filterARR: any[] = [];

    // table
    tableHeads: any = [
        { name: 'Naam', code: 'name', sortable: true, width: '28%' },
        { name: 'Type', code: 'type', sortable: false, width: '18%' },
        { name: 'Status', code: 'sendStatusDisplay', sortable: false, width: '9%' },
        { name: 'Verzonden op', code: 'sendTs', sortable: true, width: '13%' },
        { name: 'Verzonden door', code: 'sentBy', sortable: true, width: '13%' },
        { name: 'Gelezen', code: 'read', sortable: true, width: '12%' },
        { name: '', code: 'actions' }
    ];
    tableSort: { code: string; dir: string } = { code: 'editTS', dir: 'desc' };

    newsletters: any[] = null;
    startRow: number = 0;
    RPP: number = 20;
    totalRows: number = null;
    tableLoading: boolean = true;
    ready: boolean = false;

    createdOrUpdated: boolean = false;

    constructor(
        private ModalService: NgbModal,
        public ResourceService: ResourceService,
        private NewsletterService: NewsletterService,
        public HelpersService: HelpersService,
        private toastr: ToastrService,
        public route: ActivatedRoute,
        public router: Router,
        private formatTsPipe: formatTsPipe,
        public ConfigService: ConfigService
    ) {}

    ngOnInit(): void {
        this.newsletterTypes = this.ResourceService.getNewsletterTypes();
        if (this.newsletterTypes.length) {
            this.filterARR.push({ title: 'Type' });
            this.filterARR = [
                ...this.newsletterTypes.map((item) => {
                    return { title: item.name, value: item.id };
                })
            ];
        }
        this.initSearchSTR();
        const activeNewsletter = this.HelpersService.getParam('newsletter');
        if (activeNewsletter == 'new') {
            this.openCreateUpdateModal();
        } else if (activeNewsletter) this.openCreateUpdateModal(activeNewsletter);
        const page = parseInt(this.HelpersService.getParam('page'));
        if (page) this.startRow = this.RPP * page - this.RPP;
        const query = this.HelpersService.getParam('query');
        if (query) this.searchSTR = JSON.parse(unescape(query));

        this.getNewsletters();
    }

    deleteNewsletter(uuid: string) {
        this.dismissPopover();
        this.deSelectRows();
        this.NewsletterService.deleteNewsletter(uuid).subscribe((next) => {
            this.getNewsletters();
        });
    }

    dismissPopover() {
        setTimeout(() => {
            this.popoverHelper = null;
        }, 1);
    }

    initSearchSTR() {
        this.searchSTR = { typeId: undefined };
    }

    getSearchQuery() {
        return {
            ...this.searchSTR,
            startRow: this.startRow,
            rpp: this.RPP,
            orderBy: this.getSortARR()
        };
    }

    getSortARR() {
        switch (this.tableSort.code) {
            case 'read':
                return [`totalRead ${this.tableSort.dir}`, `totalSent ${this.tableSort.dir}`];
        }
        if (!this.tableSort || !this.tableSort.code) {
            return [];
        }
        return [`${this.tableSort.code} ${this.tableSort.dir}`];
    }

    search(reset?: boolean) {
        this.startRow = 0;
        if (reset) {
            this.initSearchSTR();
        }
        if (this.HelpersService.objectIsEmpty(this.searchSTR)) {
            this.HelpersService.removeParam('query');
        } else {
            const query = escape(JSON.stringify(this.searchSTR));
            this.HelpersService.addParam('query', query);
        }
        setTimeout(() => {
            this.HelpersService.addParam('page', (Math.ceil(this.startRow / this.RPP) + 1).toString());
        }, 1);
        this.getNewsletters();
    }

    getNewsletters() {
        const searchSTR = this.getSearchQuery();
        this.deSelectRows();
        this.tableLoading = true;
        this.NewsletterService.getNewsletters(searchSTR)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((next: any) => {
                if (this.startRow && this.startRow >= next.rows) {
                    this.resetStartRow();
                    return;
                }
                this.newsletters = next.data.map((item: any) => {
                    const actions = [];
                    const deleteAction = {
                        name: 'Verwijderen',
                        code: 'delete',
                        icon: 'trash',
                        class: 'delete-red',
                        confirmDelete: true
                    };
                    if (item.sendStatus.toLowerCase() == 'draft') {
                        actions.push(deleteAction);
                    }
                    const editAction = {
                        name: 'Bewerken',
                        code: 'edit',
                        icon: 'pencil'
                    };
                    if (item.sendStatus.toLowerCase() == 'draft') {
                        actions.push(editAction);
                    }
                    let subtitle = 'Vaste lijst';
                    if (item.type == 'dynamic') {
                        subtitle = `Dynamische lijst - ${item.conditions} voorwaarden`;
                    }
                    let statusString = item.sendStatus;
                    switch (statusString) {
                        case 'sent':
                            statusString = 'Verzonden';
                            break;
                        case 'sending':
                            statusString = 'Verzenden...';
                            break;
                        case 'draft':
                            statusString = 'Draft';
                            break;
                        case 'stopped':
                            statusString = 'Gestopt';
                            break;
                        default:
                            statusString = '-';
                            break;
                    }
                    let readString = '';
                    if (item.totalSent) {
                        readString = `${item.totalRead || 0} van de ${item.totalSent}`;
                    }
                    const newsletter = {
                        ...item,
                        name: {
                            type: 'titleSubtitle',
                            title: item.name || '-',
                            // subtitle: item.newsletterType || '',
                            ellipsis: true,
                            mw0: true
                            // subtitle: this.ResourceService.getNewsletterTypeById(item.typeId)
                        },
                        type: item.newsletterType,
                        sendStatusDisplay: statusString,
                        sendTs: item.sendTs ? this.formatTsPipe.transform(item.sendTs, 'DD MMMM YYYY') : '',
                        read: readString,
                        actions: {
                            type: 'actions',
                            actions: actions
                        }
                    };
                    return newsletter;
                });
                // console.log(this.newsletters);
                this.tableLoading = false;
                this.ready = true;
                this.totalRows = next.rows;
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
            });
    }

    changeStartRow(startRow: number) {
        this.startRow = startRow;
        this.HelpersService.addParam('page', (Math.ceil(this.startRow / this.RPP) + 1).toString());
        this.getNewsletters();
    }

    resetStartRow() {
        this.startRow = 0;
        this.HelpersService.addParam('page', (Math.ceil(this.startRow / this.RPP) + 1).toString());
        this.getNewsletters();
    }

    // export() {
    //     const searchSTR = this.getSearchQuery();
    //     let params = new HttpParams();
    //     for (const key in searchSTR) {
    //         params = params.append(key, searchSTR[key]);
    //     }
    //     this.exportBusy = true;
    //     this.NewsletterService.exportDiscounts(searchSTR).subscribe((data) => {
    //         setTimeout(() => {
    //             this.exportBusy = false;
    //         }, 500);
    //     });
    // }

    tableClick(item: any, head: string) {
        switch (item.type) {
            case 'actions':
                break;
            default:
                this.hasPanelData
                    ? this.selectRow(item)
                    : this.openCreateUpdateModal(item.uuid, item.sendStatus.toLowerCase() != 'draft' ? true : false);
                break;
        }
    }

    actionClick(item: any, action: string) {
        switch (action) {
            case 'edit':
                this.openCreateUpdateModal(item.uuid, item.sendStatus.toLowerCase() != 'draft' ? true : false);
                break;
            case 'delete':
                this.deleteNewsletter(item.uuid);
                break;
        }
    }

    selectRow(item: any) {
        this.newsletters = this.newsletters.map((x) => {
            if (x.uuid === item.uuid && !x.trActive) {
                x.trActive = true;
                this.panelData = item;
            } else if (x.uuid === item.uuid) {
                delete this.panelData;
                delete x.trActive;
            } else {
                delete x.trActive;
            }
            return x;
        });
    }

    deSelectRows() {
        if (this.newsletters) {
            this.newsletters = this.newsletters.map((x) => {
                delete x.trActive;
                return x;
            });
        }
        delete this.panelData;
    }

    setSort(code: string, dir: string) {
        this.tableSort = { code: code, dir: dir };
        this.getNewsletters();
    }

    openCreateUpdateModal(uuid?: string, isSent?: boolean) {
        const modalRef = this.ModalService.open(NewsletterDetailModalComponent, {
            windowClass: 'main-modal detail-modal',
            beforeDismiss: () => {
                return modalRef.componentInstance.canExit && modalRef.componentInstance.canExit();
            }
        });
        if (uuid) {
            this.HelpersService.addParam('newsletter', uuid);
            modalRef.componentInstance.uuid = uuid;
            modalRef.componentInstance.isSent = isSent || false;
        } else this.HelpersService.addParam('newsletter', 'new');
        modalRef.result
            .then((returnValue) => {
                switch (returnValue) {
                    case 'delete':
                        this.deSelectRows();
                        this.getNewsletters();
                        break;
                }
            })
            .finally(() => {
                this.HelpersService.removeParam('newsletter');
                if (this.createdOrUpdated) {
                    this.search(true);
                    this.createdOrUpdated = false;
                }
            });
        // on create
        modalRef.componentInstance.created.subscribe((newsletter: any) => {
            setTimeout(() => {
                this.HelpersService.addParam('newsletter', newsletter.uuid);
            }, 1);
            // this.search(true);
            this.createdOrUpdated = true;
        });
        // on update
        modalRef.componentInstance.updated.subscribe((newsletter: any) => {
            // this.getNewsletters();
            this.createdOrUpdated = true;
        });
        // on send
        modalRef.componentInstance.sent.subscribe((newsletter: any) => {
            this.HelpersService.removeParam('newsletter');
            this.tableSort = { code: 'editTS', dir: 'desc' };
            this.createdOrUpdated = true;
            // this.search(true);
        });
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
    }
}
