import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { toNumber } from 'lodash';
import { Observable, Subject, finalize, forkJoin, takeUntil, tap } from 'rxjs';
import { ActiveSubstanceService } from 'src/app/services/active-substance.service';
import { DefaultService } from 'src/app/utils/api';
import { HelpersService } from 'src/app/services/helpers.service';
import { AddBrandModalComponent } from '../add-brand-modal/add-brand-modal.component';
import { BrandServiceService } from 'src/app/services/brand-service.service';
@Component({
    selector: 'app-active-substance-detail-modal',
    templateUrl: './active-substance-detail-modal.component.html',
    styleUrls: ['./active-substance-detail-modal.component.scss']
})
export class ActiveSubstanceDetailModalComponent implements OnInit, OnDestroy {
    @Input() id?: any;
    @Input() editMode?: any;
    @Output() created = new EventEmitter();
    @Output() updated = new EventEmitter();

    activeSubstance: any = {};
    categories: any = [];
    medgroups: any = [];
    activeSection: string;
    popoverHelper: any = null;
    validation: any = {};
    helper: any = {};
    changes: boolean = false;

    resourcesReady: boolean = false;
    ready: boolean = false;
    loading: boolean = false;
    saving: boolean;
    actionId = null;
    submitting: boolean = false;

    formSTR: any = {};

    onDestroy$: Subject<void> = new Subject<void>();

    constructor(
        private ModalService: NgbModal,
        public ActiveModal: NgbActiveModal,
        public activeSubstanceService: ActiveSubstanceService,
        private DefaultService: DefaultService,
        public HelpersService: HelpersService,
        private BrandService: BrandServiceService
    ) {}

    ngOnInit(): void {
        this.initResources();
        // edit or create
        if (this.id && this.id !== 'new') {
            this.getActiveSubstance();
        } else {
            this.activeSubstance = {};
            this.ready = true;
            this.activeSection = 'general';
        }
    }
    getActiveSubstance() {
        this.loading = true;
        this.activeSubstanceService
            .getActiveSubstance(this.id)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((res) => {
                this.activeSubstance = res;

                if (this.editMode) {
                    this.activeSection = 'general';
                }
                this.resetForm();
                this.ready = true;
                this.loading = false;
            });
    }

    formChange() {
        this.changes = true;
    }

    resetForm() {
        this.formSTR = { ...this.activeSubstance };
    }

    delete() {
        this.saving = true;
        this.actionId = null;
        this.activeSubstanceService
            .deleteActiveSubstance(this.id)
            .pipe(
                finalize(() => {
                    this.saving = false;
                })
            )
            .subscribe((next) => {
                this.activeSection = null;
                this.updated.emit(this.activeSubstance);
                this.changes = false;
                this.HelpersService.removeParam('werkzameStofID');
                this.ActiveModal.dismiss();
            });
    }

    getPublishedText() {
        if (this.activeSubstance.isPublished === 1) {
            return 'Ja';
        }
        if (this.activeSubstance.isPublished !== 1) {
            return 'Nee';
        }
    }

    createUpdateActiveSubstance() {
        // add blank atc to the array in active substance
        if (!this.formSTR.atcCodes) {
            this.formSTR.atcCodes = [];
        }
        const FORM = this.formSTR;
        this.submitting = true;
        this.saving = true;
        this.activeSubstanceService
            .updateInsertActiveSubstance(FORM, this.id || null)
            .pipe(
                finalize(() => {
                    this.saving = false;
                    this.submitting = false;
                })
            )
            .subscribe({
                next: (next) => {
                    this.validation = {};
                    this.changes = false;
                    this.activeSubstance.id ? this.updated.emit() : this.created.emit();
                    this.HelpersService.removeParam('werkzameStofID');
                    this.ActiveModal.dismiss();
                },
                error: (err) => {
                    this.validation = err.error.details;
                }
            });
    }

    removeATC(atc: any) {
        //remove atc from the array in active substance
        this.formSTR.atcCodes.splice(this.formSTR.atcCodes.indexOf(atc), 1);
        this.formChange();
    }

    addATC() {
        // add blank atc to the array in active substance
        if (!this.formSTR.atcCodes) {
            this.formSTR.atcCodes = [];
        }
        this.formSTR.atcCodes.push({
            code: null
        });
        this.formChange();
    }

    initResources() {
        this.ready = false;
        const observables$: Observable<any>[] = [];
        const categoriesObservable$ = this.DefaultService.rvmCategoriesGetAllRvmCategories().pipe(
            tap((res: any) => {
                this.categories = res.map((item) => {
                    return {
                        ...item,
                        title: item.name,
                        value: item.sequence
                    };
                });
            })
        );
        observables$.push(categoriesObservable$);

        const medgroupsObservable$ = this.DefaultService.medgroupsGetAllMedgroups().pipe(
            tap((res: any) => {
                this.medgroups = res.map((item) => {
                    return {
                        ...item,
                        title: item.name,
                        value: toNumber(item.id)
                    };
                });
            })
        );

        observables$.push(medgroupsObservable$);

        forkJoin(observables$).subscribe(() => {
            this.resourcesReady = true;
            this.ready = true;
        });
    }

    sectionIsActive(code) {
        return this.activeSection === code;
    }

    dismissPopover() {
        setTimeout(() => {
            this.popoverHelper = null;
        }, 1);
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
    }

    canExit(): boolean {
        if (!this.changes || confirm('Je hebt onopgeslagen wijzigingen, weet je zeker dat je wilt afsluiten?')) {
            this.changes = false;
            return true;
        } else {
            return false;
        }
    }

    openAddNewBrandModal(brand?: any) {
        const modalRef = this.ModalService.open(AddBrandModalComponent, {
            windowClass: 'add-modal ',
            beforeDismiss: () => {
                return modalRef.componentInstance.canExit && modalRef.componentInstance.canExit();
            }
        });
        if (brand) {
            modalRef.componentInstance.brand = brand;
        }
        modalRef.componentInstance.activeSubstance = this.activeSubstance;
        modalRef.componentInstance.created.subscribe((next) => {
            this.getActiveSubstance();
        });
    }

    deleteBrand(brand) {
        this.BrandService.deleteBrand(brand.id).subscribe((next) => {
            this.getActiveSubstance();
        });
    }

    @HostListener('window:beforeunload', ['$event'])
    beforeUnloadHandler(event: Event) {
        if (this.changes) return false;
    }
}
