import { Component, inject, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageCroppedEvent, CropperPosition } from 'ngx-image-cropper';
import { HelpersService, mimeToExt } from 'src/app/services/helpers.service';

const defaultCropArticle = { x1: 0, y1: 31.722499999999997, x2: 932, y2: 497.72249999999997 };
const defaultCropThumb = { x1: 14, y1: 0, x2: 914.5864999999999, y2: 600.391 };

type CropCode = 'article' | 'thumb';

export type CroppedImages = {
    original: {
        file?: File;
        fileUrl?: string;
        fileWidth?: number;
    };
    article: {
        file: File;
        croppedPosition: CropperPosition;
    };
    thumb: {
        file: File;
        croppedPosition: CropperPosition;
    };
};

@Component({
    selector: 'app-theme-images',
    templateUrl: './theme-images.component.html',
    styleUrls: ['./theme-images.component.scss']
})
export class ThemeImagesComponent implements OnInit {
    helpersService = inject(HelpersService);
    @Input() imageName?: string;
    @Input() imageURL?: string;
    @Input() imageWidth?: number;
    @Input() imageFile?: File;
    @Input() articleURL?: string;
    @Input() thumbURL?: string;
    @Input() cropPositionArticleInput?: CropperPosition = defaultCropArticle;
    @Input() cropPositionThumbInput?: CropperPosition = defaultCropThumb;

    cropPositionArticle?: CropperPosition = defaultCropArticle;
    cropPositionThumb?: CropperPosition = defaultCropThumb;

    cropPositionArticleOutput?: CropperPosition = defaultCropArticle;
    cropPositionThumbOutput?: CropperPosition = defaultCropThumb;

    cropPositionArticleChanged?: boolean = false;
    cropPositionThumbChanged?: boolean = false;

    imageResizeTreshold: number = 1800;

    articleFile: any;
    thumbFile: any;
    imageURLStatic: string;

    ready: boolean = true;
    articleReady: boolean = false;
    thumbReady: boolean = false;
    saving: boolean = false;

    constructor(private activeModal: NgbActiveModal) {}

    async ngOnInit() {
        this.imageURLStatic = this.imageURL;
        if (!this.cropPositionArticleInput) {
            this.cropPositionArticleInput = defaultCropArticle;
        }
        if (!this.cropPositionThumbInput) {
            this.cropPositionThumbInput = defaultCropThumb;
        }
        if (this.imageFile) {
            this.imageName = this.imageFile.name;
            this.imageWidth = await this.helpersService.getImageWidth(this.imageFile);
        }
    }

    dismiss() {
        this.activeModal.dismiss();
    }

    async submit() {
        this.saving = true;

        const result: CroppedImages = {
            original: {
                file: this.imageURL
                    ? undefined
                    : new File(
                          [this.imageFile.slice(0, this.imageFile.size, this.imageFile.type)],
                          this.imageName ??
                              `${this.imageFile.name.split('.').slice(0, -1).join('.')}.${
                                  mimeToExt[this.imageFile.type]
                              }`,
                          { type: this.imageFile.type }
                      ),
                fileUrl: this.imageURL,
                fileWidth: this.imageWidth
            },
            article: {
                file: new File(
                    [this.articleFile],
                    this.imageName ??
                        `${this.imageFile.name.split('.').slice(0, -1).join('.')}.${mimeToExt[this.imageFile.type]}`,
                    {
                        type: this.articleFile.type
                    }
                ),
                croppedPosition: this.cropPositionArticle
            },
            thumb: {
                file: new File(
                    [this.thumbFile],
                    this.imageName ??
                        `${this.imageFile.name.split('.').slice(0, -1).join('.')}.${mimeToExt[this.imageFile.type]}`,
                    {
                        type: this.thumbFile.type
                    }
                ),
                croppedPosition: this.cropPositionThumb
            }
        };

        this.activeModal.close(result);
        setTimeout(() => (this.saving = false), 400);
    }

    imageCropped(event: ImageCroppedEvent, code: CropCode) {
        setTimeout(() => {
            switch (code) {
                case 'article':
                    this.articleFile = event.blob;
                    break;
                case 'thumb':
                    this.thumbFile = event.blob;
                    break;
            }
        }, 1);
    }

    startCropImage(code: CropCode) {
        switch (code) {
            case 'article':
                if (this.articleReady) this.cropPositionArticleChanged = true;
                break;
            case 'thumb':
                if (this.thumbReady) this.cropPositionThumbChanged = true;
                break;
        }
    }

    cropperReady(code: CropCode) {
        switch (code) {
            case 'article':
                if (!this.cropPositionArticleInput) {
                    this.cropPositionArticleInput = defaultCropArticle;
                }
                this.cropPositionArticle = structuredClone(this.cropPositionArticleInput);
                break;
            case 'thumb':
                if (!this.cropPositionThumbInput) {
                    this.cropPositionThumbInput = defaultCropThumb;
                }
                this.cropPositionThumb = structuredClone(this.cropPositionThumbInput);
                break;
        }
    }
}
