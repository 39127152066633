import { Injectable } from '@angular/core';
import { DefaultService } from '../utils/api';
import { ToastrService } from 'ngx-toastr';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class BrandServiceService {
    constructor(private DefaultService: DefaultService, private toastr: ToastrService) {}

    public updateInsertBrand(body: any, id?: number) {
        return this.DefaultService.brandsUpdateOrInsertBrand(body).pipe(
            tap((next) => {
                if (id) {
                    this.toastr.success('Merk werd succesvol opgeslagen', 'Merk opgeslagen');
                } else {
                    this.toastr.success('Merk werd succesvol aangemaakt', 'Merk aangemaakt');
                }
            })
        );
    }

    public deleteBrand(id: number) {
        return this.DefaultService.brandsDeleteBrand(id).pipe(
            tap((next) => {
                this.toastr.success('Merk werd succesvol verwijderd', 'Merk verwijderd');
            })
        );
    }
}
