<div class="app-theme-images">

    <div class="title">Afbeelding bijsnijden</div>
    <button class="close-modal" (click)="dismiss()"></button>
    
    <!-- article -->
    <div class="field-wrap" [class.saving]="saving">
        <div class="standalone-label">Afbeelding artikel pagina</div>
        <image-cropper
            (mousedown)="articleReady = true"
            [maintainAspectRatio]="true"
            [aspectRatio]="2 / 1"
            format="png"
            [resizeToWidth]="imageWidth <= 1800 ? undefined : 1800"
            [imageFile]="imageFile"
            [imageURL]="imageURLStatic"
            (imageCropped)="imageCropped($event, 'article')"
            (cropperReady)="cropperReady('article')"
            (startCropImage)="startCropImage('article')"
            [cropper]="cropPositionArticle"
        ></image-cropper>
    </div>
    
    <!-- thumb -->
    <div class="field-wrap" [class.saving]="saving">
        <div class="standalone-label">Afbeelding overzicht</div>
        <image-cropper
            (mousedown)="thumbReady = true"
            [maintainAspectRatio]="true"
            [aspectRatio]="3 / 2"
            format="png"
            [resizeToWidth]="imageWidth <= 700 ? undefined : 700"
            [imageFile]="imageFile"
            [imageURL]="imageURLStatic"
            (imageCropped)="imageCropped($event, 'thumb')"
            (cropperReady)="cropperReady('thumb')"
            (startCropImage)="startCropImage('thumb')"
            [cropper]="cropPositionThumb"
        ></image-cropper>
    </div>

    <div class="buttons">
        <button [class.no-pointer]="saving" class="app-button button default--light" (click)="dismiss()"><i class="icon icon-cancel-blue"></i><span>Annuleren</span></button>
        <button [disabled]="saving" class="app-button button primary" (click)="submit()"><i class="icon icon-save-blue"></i><span>Opslaan</span></button>
    </div>

</div>