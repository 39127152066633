import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DefaultService } from '../utils/api';
import { ConfigService } from './config.service';

export type resourceType =
    | 'categories'
    | 'functions'
    | 'branches'
    | 'accountManagers'
    | 'tags'
    | 'newsletterTypes'
    | 'products';
@Injectable({
    providedIn: 'root'
})
export class ResourceService {
    constructor(private DefaultService: DefaultService, private ConfigService: ConfigService) {}
    private categories$ = new BehaviorSubject<any>(null);
    private functions$ = new BehaviorSubject<any>(null);
    private branches$ = new BehaviorSubject<any>(null);
    private products$ = new BehaviorSubject<any>(null);
    private accountManagers$ = new BehaviorSubject<any>(null);
    private tags$ = new BehaviorSubject<any>(null);
    private newsletterTypes$ = new BehaviorSubject<any>(null);
    private newsletterTemplates$ = new BehaviorSubject<any>(null);
    private config$ = new BehaviorSubject<any>(null);

    public init(): Observable<any>[] {
        // shared resources
        const observables$: Observable<any>[] = [];
        const categoryObservable$ = this.DefaultService.resourcesGetResources('category').pipe(
            tap((next) => {
                this.categories$.next(next.data);
            })
        );
        observables$.push(categoryObservable$);
        const configObservable$ = this.DefaultService.resourcesGetConfig().pipe(
            tap((next) => {
                this.config$.next(next);
                if (next.appOverviewUrl) {
                    this.ConfigService.getConfig().appOverviewUrl = next.appOverviewUrl;
                    this.ConfigService.getConfig().logoutUrl = next.logoutURL;
                }
            })
        );
        observables$.push(configObservable$);
        // newsletter resources
        if (this.ConfigService.getConfig().appCode == 'newsletters') {
            const productsObservable$ = this.DefaultService.discountcodeGetProducts().pipe(
                tap((next) => {
                    this.products$.next(next.data);
                })
            );
            observables$.push(productsObservable$);
            const newsletterTypesObservable$ = this.DefaultService.resourcesGetResources('newsletter_types').pipe(
                tap((next) => {
                    this.newsletterTypes$.next(next.data);
                })
            );
            observables$.push(newsletterTypesObservable$);
            const newsletterTemplatesObservable$ = this.DefaultService.resourcesGetResources(
                'newsletter_templates'
            ).pipe(
                tap((next) => {
                    this.newsletterTemplates$.next(next.data);
                })
            );
            observables$.push(newsletterTemplatesObservable$);
            const functionObservable$ = this.DefaultService.resourcesGetResources('functions').pipe(
                tap((next) => {
                    this.functions$.next(next.data);
                })
            );
            observables$.push(functionObservable$);
            const brancheObservable$ = this.DefaultService.resourcesGetResources('branches').pipe(
                tap((next) => {
                    this.branches$.next(next.data);
                })
            );
            observables$.push(brancheObservable$);
            const accountManagersObservable$ = this.DefaultService.resourcesGetResources('accountmanagers').pipe(
                tap((next) => {
                    this.accountManagers$.next(next.data);
                })
            );
            observables$.push(accountManagersObservable$);
            const tagsObservable$ = this.DefaultService.resourcesGetResources('tags').pipe(
                tap((next) => {
                    this.tags$.next(next.data);
                })
            );
            observables$.push(tagsObservable$);
        }
        return observables$;
    }

    public getResource(type: resourceType): any[] {
        let resource$ = new BehaviorSubject<any>(null);
        switch (type) {
            case 'categories':
                resource$ = this.categories$;
                break;
            case 'functions':
                resource$ = this.functions$;
                break;
            case 'branches':
                resource$ = this.branches$;
                break;
            case 'accountManagers':
                resource$ = this.accountManagers$;
                break;
            case 'tags':
                resource$ = this.tags$;
                break;
            case 'newsletterTypes':
                resource$ = this.newsletterTypes$;
                break;
            case 'products':
                resource$ = this.products$;
                break;
            default:
                break;
        }
        return resource$.getValue();
    }

    public getResourceById(type: resourceType, code: string): any[] {
        let resource$ = new BehaviorSubject<any>(null);
        switch (type) {
            case 'categories':
                resource$ = this.categories$;
                break;
            case 'functions':
                resource$ = this.functions$;
                break;
            case 'branches':
                resource$ = this.branches$;
                break;
            case 'accountManagers':
                resource$ = this.accountManagers$;
                break;
            case 'newsletterTypes':
                resource$ = this.newsletterTypes$;
                break;
            case 'products':
                resource$ = this.products$;
                break;
            default:
                break;
        }
        return resource$.getValue().find((x) => x.code === code);
    }

    public getYesNoOptions() {
        return [
            { title: 'Ja', value: 'yes' },
            { title: 'Nee', value: 'no' }
        ];
    }

    public getYesNoOption(value) {
        return this.getYesNoOptions().find((item) => {
            return item.value == value;
        });
    }

    // newsletters
    public getNewsletterTypes(): any[] {
        return this.newsletterTypes$.getValue();
    }

    public getNewsletterTypeById(id): any {
        const index = this.newsletterTypes$
            .getValue()
            .map((item) => {
                return item.id;
            })
            .indexOf(id);
        return index != -1 ? this.newsletterTypes$.getValue()[index] : null;
    }

    public getLocale() {
        return 'nl';
    }

    public getDayLabels() {
        return { su: 'Zo', mo: 'Ma', tu: 'Di', we: 'Wo', th: 'Do', fr: 'Vr', sa: 'Za' };
    }

    // discountcodes
    public getTypeByCode(code: string): string {
        switch (code) {
            case 'unique':
                return 'Uniek';
            case 'group':
                return 'Groep';
            default:
                return '';
        }
    }

    public getDiscountOnByCode(code: string): string {
        switch (code) {
            case 'all':
                return 'Alles';
            case 'category':
                return 'Categorie';
            case 'product':
                return 'Product';
            default:
                return '';
        }
    }

    public getNewsletterTemplates(): any[] {
        return this.newsletterTemplates$.getValue();
    }

    public getNewsletterTemplateById(id): any {
        const index = this.newsletterTemplates$
            .getValue()
            .map((item) => {
                return item.id;
            })
            .indexOf(id);
        return index != -1 ? this.newsletterTemplates$.getValue()[index] : null;
    }
}
