<span class="close-modal-outside"><i (click)="ActiveModal.dismiss()" class="im-icon im-icon-x-light"></i></span>
    <div class="modal-wrap">
        <ng-container *ngIf="!ready">
            <app-spinner [centerVertically]="true"></app-spinner>
        </ng-container>
        <div class="d-flex">
            <div class="d-flex flex-column w-100 flex-grow-1 scroll-y">
                <ng-container *ngIf="loading">
                    <div style="height: 100%;">
                        <app-spinner [centerVertically]="true"></app-spinner>
                    </div>
                </ng-container>
                <ng-container *ngIf="!loading && ready">
                    <div class="">
                        <!-- header -->
                        <div class="detail-modal-header d-flex flex-row justify-content-between align-items-center" style="min-height: 36px;">
                            <h1 style="min-height: 36px;">Afbeelding bewerken</h1>
                        </div>
                    </div>
                    <div class="detail-modal-body">
                        <div *ngIf="file" style="min-height: 275px;">
                            <image-cropper
                                class="opacity-hide"
                                [class.opacity-show]="ready"
                                [imageChangedEvent]="false"
                                [maintainAspectRatio]="true"
                                [aspectRatio]="aspectRatio"
                                [resizeToHeight]="resizeToHeight"
                                [resizeToWidth]="resizeToWidth"
                                [imageFile]="file"
                                format="png"
output="base64"
                                (imageCropped)="imageCropped($event)"
                                (imageLoaded)="false"
                                (cropperReady)="cropperReady()"
                                (loadImageFailed)="false"
                            ></image-cropper>
                        </div>
                        <div *ngIf="true" class="d-flex align-items-center mt-s"  style="padding-left: 5px;">
                            <!-- submit -->
                            <button class="button default" (click)="canSubmit() && submit()" [class.disabled]="!canSubmit()">Bewaren</button>
                            <!-- cancel -->
                            <button class="button-link blue cancel ml-m" (click)="ActiveModal.dismiss()" [class.disabled]="false"><span>Annuleer</span></button>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>