<span class="close-modal"><i (click)="ActiveModal.dismiss()" class="im-icon im-icon-x-light"></i></span>
<div>
    <h1>Lijst ontvangers <ng-container *ngIf="ready">({{recipients.length}})</ng-container></h1>

    <ng-container *ngIf="ready">

        <div class="mw-400-px mb-s mt-m">
            <app-field-text 
                [model]="term" 
                (modelChange)="term = $event" 
                (enter)="getRecipients()" 
                typeClass="search"
                placeholder="Zoek op naam, e-mailadres...">
            </app-field-text>
        </div>

        <app-table
            [heads]="tableHeads" 
            [data]="localRecipients" 
            [sort]=""
            [RPP]="RPP"
            [startRow]="startRow"
            [totalRows]="totalRows"
            [loading]="false"
            [shimmerTypes]="false"
            emptyState="Geen resultaten"
            (sortChange)="false"
            (startRowChange)="changeStartRow($event)"
            classes="full-width"
        ></app-table>
    </ng-container>

</div>