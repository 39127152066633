<span class="close-modal-outside"><i (click)="ActiveModal.dismiss(); " class="im-icon im-icon-x-light"></i></span>
<div class="modal-wrap">
    <ng-container *ngIf="!ready">
        <app-spinner [centerVertically]="true"></app-spinner>
    </ng-container>
    <div *ngIf="activeSubstance" class="d-flex detail-modal">

        <!-- start form -->
        <div class="d-flex flex-column w-100 flex-grow-1 scroll-y">
            <ng-container *ngIf="loading">
                <div style="height: 100%;">
                    <app-spinner [centerVertically]="true"></app-spinner>
                </div>
            </ng-container>
            <ng-container *ngIf="!loading && ready">
                <div class="">
                <!-- <div class="pl-m pr-m pt-s pb-s modal-form-section" [class.edit-section]="sectionIsActive('general') && id"> -->
                    <!-- header -->
                    <div class="detail-modal-header d-flex flex-row justify-content-between align-items-center" style="min-height: 36px;">
                        <h1 style="min-height: 36px;">{{activeSubstance.name}} {{id ? activeSubstance.code : 'Nieuwe werkzame stof'}}</h1>
                        <div class="h-100 d-flex align-items-center" *ngIf="this.activeSection !== 'general'">
                            <!-- delete -->
                            <div *ngIf="true" class="position-relative" appClickOutside (clickOutside)="actionId = null">
                                <button class="button tertiary delete-red" (click)="actionId = 'delete'" [class.disabled]="saving"><i class="im-icon im-icon-trash delete-red icon-l mr-xxxs" style="transform: translateY(-1px);"></i><span>Verwijderen</span></button>
                                <app-confirm-delete (confirm)="delete();" (cancel)="actionId = null" [open]="actionId === 'delete'" positionClass="left" positionClassV="bottom"></app-confirm-delete>
                            </div>
                            <!-- edit -->
                            <button *ngIf="true" class="button tertiary ml-xs" (click)="activeSection = 'general'" [class.disabled]="saving"><i class="im-icon im-icon-pencil icon-l mr-xxxs"></i><span>Wijzigen</span></button>
                            <!-- <div *ngIf="true" class="button-row-sep ml-xs"></div> -->
                            <!-- close -->
                            <!-- <button class="button-link blue ml-xs" (click)="ActiveModal.dismiss()"><span>Sluit</span></button> -->
                        </div>
                        <div class="h-100 d-flex align-items-center" *ngIf="sectionIsActive('general')">
                            <button class="button tertiary" (click)="canExit() && (id ? activeSection = null : ActiveModal.dismiss()); resetForm(); HelpersService.removeParam('werkzameStofID');" [class.disabled]="saving"><span>Annuleren</span></button>
                            <app-button classes="primary-default ml-xs" icon="check-round-filled" text="Bewaren" [disabled]="saving" [submitting]="submitting" (emitClick)="createUpdateActiveSubstance()"></app-button>
                        </div>
                    </div>
                    
                    <div class="detail-modal-body">
                        <!-- content -->
                        <div class="row display-row mw-1200-px">
                            <div class="col-xl-4">
                                <!-- Stofnaam -->
                                <div class="display-content">
                                    <ng-container *ngIf="this.activeSection !== 'general'">
                                        <div class="display-label" [class.text-color]="sectionIsActive('general')">Stofnaam</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{activeSubstance.name | display}}</span>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="sectionIsActive('general')">
                                        <app-field-text
                                            [model]="formSTR.name" 
                                            (modelChange)="formSTR.name = $event; formChange();" 
                                            label="Stofnaam"
                                            [error]="validation.name"
                                            placeholder="Stofnaam"
                                            maxWidth="420">
                                        </app-field-text>
                                    </ng-container>                            
                                </div>
                            </div>
                        </div>
                        <div class="row display-row mw-1200-px">
                            <div class="col-xl-4">
                                <!-- Medicijngroep -->
                                <div class="display-content">
                                    <ng-container *ngIf="this.activeSection !== 'general'">
                                        <div class="display-label" [class.text-color]="sectionIsActive('general')">Medicijngroep</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{activeSubstance.medgroup | display}}</span>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="sectionIsActive('general')">
                                        <app-field-select 
                                            [model]="formSTR.medgroupId"
                                            [options]="medgroups"
                                            (modelChange)="formSTR.medgroupId = $event; formChange();" 
                                            label="Medicijngroep"
                                            [hideLabel]="false"
                                            [error]="validation.medgroupId"
                                            placeholder="Selecteer..."
                                            maxWidth="360">
                                        </app-field-select>
                                    </ng-container>                            
                                </div>
                            </div>
                        </div>
                        <div class="row display-row mw-1200-px">
                            <div class="col-xl-8">
                                <!-- Category -->
                                <div class="display-content">
                                    <ng-container *ngIf="this.activeSection !== 'general'">
                                        <div class="display-label">Categorie</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{activeSubstance.category | display}}</span>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="sectionIsActive('general')">
                                        <app-field-select 
                                            [model]="formSTR.categoryId"
                                            [options]="categories"
                                            (modelChange)="formSTR.categoryId= $event; formChange();" 
                                            label="Categorie"
                                            [hideLabel]="false"
                                            [error]="validation.categoryId"
                                            placeholder="Selecteer..."
                                            maxWidth="176">
                                        </app-field-select>
                                    </ng-container>                            
                                </div>
                            </div>
                        </div>
                        <!-- ATC codes -->
                        <div class="row display-row mw-1200-px">
                            <div class="col-xl-3">
                                <div class="display-content">
                                    <div class="display-label">ATC-code</div>
                                    <ng-container *ngIf="this.activeSection !== 'general'">
                                        <span *ngIf="!activeSubstance.atcCodes">-</span>
                                        <div *ngFor="let atcCode of activeSubstance.atcCodes">
                                            <div class="display-value d-flex flex-row">
                                                <span>{{atcCode.code}}</span>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="sectionIsActive('general')">
                                        <div class="atc-item" *ngFor="let atcCode of formSTR.atcCodes">
                                            <app-field-text
                                                [model]="atcCode.code" 
                                                (modelChange)="atcCode.code = $event; formChange();"
                                                label=""
                                                [error]="validation.atcCode"
                                                placeholder="ATC-code"
                                                maxWidth="176">
                                            </app-field-text>
                                            <i class="im-icon im-icon-x-light" (click)="removeATC(atcCode)"></i>
                                        </div>
                                    <span style="color: #2967C3;">+ </span><span class="atc-add" (click)="addATC()">ATC-code toevoegen</span>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <!-- Advies -->
                        <div class="row display-row mw-1200-px">
                            <div class="col-md-8">
                                <div class="display-content">
                                    <ng-container  *ngIf="this.activeSection !== 'general'">
                                        <div class="display-label">Advies</div>
                                        <div class="display-value d-flex flex-row">
                                            <span class="page-content" [innerHTML]="activeSubstance.advise | safe : 'html'"></span>
                                        </div>
                                    </ng-container>          
                                    <ng-container *ngIf="sectionIsActive('general')">
                                        <app-field-richtext 
                                            [model]="formSTR.advise" 
                                            (modelChange)="formSTR.advise = $event; formChange();" 
                                            [label]="'Advies' | display"
                                            [expanded]="true"
                                            [error]="validation.advise">
                                        </app-field-richtext>
                                    </ng-container>                
                                </div>
                            </div>
                        </div>
                        <div class="row display-row mw-1200-px">
                            <div class="col-md-2">
                                <!-- last mod -->
                                <div class="display-content">
                                    <ng-container *ngIf="this.activeSection !== 'general'">
                                        <div class="display-label">Datum laatste wijziging</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{activeSubstance.lastEdit | formatTs: 'DD-MM-YYYY'}}</span>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="sectionIsActive('general')">
                                        <app-field-date 
                                            [model]="formSTR.lastEdit"
                                            (modelChange)="formSTR.lastEdit = $event; formChange();" 
                                            label="Datum laatste wijziging"
                                            placeholder="dd-mm-yyyy"
                                            [error]="validation.lastEdit">
                                        </app-field-date>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <div class="row display-row mw-1200-px">
                            <div class="col-md-2">
                                <!-- published -->
                                <div class="display-content">
                                    <ng-container *ngIf="this.activeSection !== 'general'">
                                        <div class="display-label">Gepubliceerd</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{getPublishedText() | display}}</span>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="sectionIsActive('general')">
                                        <app-field-radios 
                                            class="d-block"
                                            label="Gepubliceerd"
                                            [model]="formSTR.isPublished"
                                            (modelChange)="formSTR.isPublished = $event"
                                            [error]="validation.isPublished"
                                            optionalString=""
                                            [options]="[{title: 'Ja', value: 1},{title: 'Nee', value: 2}]"
                                            [optionWidth]="80"
                                            [optionColumnWidth]="">
                                        </app-field-radios>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <div class="table--detail" *ngIf="this.activeSection !== 'general'">
                            <h2>Merknamen</h2>
                            <table *ngIf="activeSubstance.brand">
                                <thead>
                                    <tr>
                                        <th style="width: 25%;">Merknaam</th>
                                        <th style="width: 25%;">URL</th>
                                        <th style="width: 25%;">gewijzigd door</th>
                                        <th style="width: 25%;">Laatst gewijzigd</th>
                                        <th style="width: .1%; min-width: 38px;"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let brand of activeSubstance.brand; index as $i">
                                        <td>{{brand.name | display}}</td>
                                        <td><span *ngIf="!brand.url">{{brand.url | display}}</span><a href="{{brand.url}}" target="_blank">{{brand.url}}<i *ngIf="brand.url" class="im-icon im-icon-link-external" style="margin-left: 5px; font-size: 10px; text-decoration: none;"></i></a></td>
                                        <td>{{brand.lastModifiedBy | display}}</td>
                                        <td>{{brand.lastModifiedTS | formatTs: 'DD-MM-YYYY'}}</td>
                                        <td>
                                            <div class="actions-popover-button">
                                                <i class="im-icon im-icon-actions" [ngbPopover]="popContent" [autoClose]="'outside'" (hidden)="helper.deleteBrand = null" popoverClass="actions-popover" [placement]="['bottom-right', 'auto']"></i>
                                            </div>
                                            <ng-template #popContent>
                                                <ul>
                                                    <li (click)="openAddNewBrandModal(brand)"><i class="im-icon im-icon-pencil"></i> <span>Wijzigen</span></li>
                                                    <li><button (click)="helper.deleteBrand = $i" [class.disabled]="saving" class="delete-red"><i class="im-icon im-icon-trash"></i><span>Verwijderen</span></button></li>
                                                    <app-confirm-delete (confirm)="deleteBrand(brand);" (cancel)="helper.deleteBrand = null" [open]="helper.deleteBrand === $i" positionClass="left" positionClassV="bottom"></app-confirm-delete>
                                                </ul>
                                            </ng-template>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="nobrand" *ngIf="!activeSubstance.brand">Er zijn geen merknamen aan deze werkzame stof gelinkt</div>
                            <div class="detail-modal-table-add">
                                <button class="add-brand" (click)="openAddNewBrandModal()"><i class="im-icon im-icon-plus-round"></i>Merknaam toevoegen</button>
                            </div>
                        </div>
                    </div>
                    <div class="detail-modal-footer" *ngIf="activeSubstance.id">
                        <p *ngIf="activeSubstance.createdTS">Aangemaakt op {{activeSubstance.createdTS | formatTs: 'DD-MM-YYYY'}} <span *ngIf="activeSubstance.createdBy">door {{activeSubstance.createdBy}}</span></p>
                        <p>Gewijzigd op {{activeSubstance.lastModifiedTS | formatTs: 'DD-MM-YYYY'}}<span *ngIf="activeSubstance.lastModifiedBy"> door {{activeSubstance.lastModifiedBy}}</span></p> 
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>