import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-recipients-sent-modal',
    templateUrl: './recipients-sent-modal.component.html',
    styleUrls: ['./recipients-sent-modal.component.scss']
})
export class RecipientsSentModalComponent implements OnInit {
    @Input() recipients: any[] = [];

    tableHeads: any[] = [
        { name: 'Email', code: 'email', sortable: false, width: '50%' },
        { name: 'Geopend', code: 'opened', sortable: false, width: '50%' }
    ];
    localRecipients: any[] = [];

    startRow: number = 0;
    RPP: number = 20;
    totalRows: number = null;
    tableLoading: boolean = false;
    term: string = '';

    ready: boolean = true;

    constructor(public ActiveModal: NgbActiveModal) {}

    ngOnInit(): void {
        this.getRecipients();
    }

    getRecipients() {
        let filteredRecipients;
        if (!this.term) {
            filteredRecipients = this.recipients.map((item) => {
                return {
                    ...item,
                    email: {
                        type: 'default',
                        color: 'black',
                        value: item.email
                    },
                    opened: item.opened ? 'Ja' : 'Nee'
                };
            });
        } else {
            filteredRecipients = this.recipients
                .map((item) => {
                    return {
                        ...item,
                        email: {
                            type: 'default',
                            color: 'black',
                            value: item.email
                        },
                        opened: item.opened ? 'Ja' : 'Nee'
                    };
                })
                .filter((item) => {
                    return new RegExp(this.term, 'mi').test(`${item.email.value}`);
                });
        }
        this.totalRows = filteredRecipients.length;
        this.localRecipients = filteredRecipients.slice(this.startRow, this.startRow + this.RPP);
    }

    changeStartRow(startRow: number) {
        this.startRow = startRow;
        this.getRecipients();
    }
}
