<div class="search-radios" [class.search-radios--open]="helper.FORM" [class.search-radios--filled]="this.model !== undefined">

    <!-- field -->
    <div 
        class="inner" 
        (hidden)="helper.FORM = null" 
        [ngbPopover]="popContent" 
        #popContentRef="ngbPopover" 
        placement="bottom-left bottom-right" 
        popoverClass="popover--search" 
        [container]="'body'" 
        [autoClose]="'outside'" 
        (click)="openForm(popContentRef)">
        <!-- empty -->
        <ng-container *ngIf="this.model === undefined">
            <span class="empty">{{placeholder}}</span>
        </ng-container>
        <!-- filled -->
        <ng-container *ngIf="this.model !== undefined">
            <span class="label">{{placeholder}}:</span>
            <!-- model -->
            <div class="d-flex align-items-center">
                <span class="value">{{this.helper.selected?.title}}</span>
            </div>
        </ng-container>
        <!-- icon -->
        <i class="im-icon im-icon-triangle-rounded-down"></i>
    </div>

    <!-- popover -->
    <ng-template #popContent>
        <div class="popover--inner" *ngIf="helper.FORM">
            <div class="options">
                <ng-container *ngFor="let option of options">
                    <div class="option" (click)="select(option)">
                        <div class="fake-radio" [class.active]="option.value === model">
                            <span></span>
                        </div>
                        <div class="title">{{option.title}}</div>
                    </div>
                </ng-container>
            </div>
            <div class="clear-wrap" (click)="clear()" *ngIf="allowClear">Selectie leegmaken</div>
        </div>
    </ng-template>
</div>