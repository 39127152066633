import { Injectable } from '@angular/core';
import { DefaultService } from '../utils/api';
import { ToastrService } from 'ngx-toastr';
import { HelpersService } from './helpers.service';
import { tap } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ContactService {
    constructor(
        private DefaultService: DefaultService,
        private toastr: ToastrService,
        private HelpersService: HelpersService
    ) {}

    public getContact(id: number) {
        return this.DefaultService.contactGetContact(id);
    }

    public deleteContact(id: number) {
        return this.DefaultService.contactsDeleteContact(id).pipe(
            tap((next) => {
                this.toastr.success('Contact werd succesvol verwijderd', 'Contact verwijderd');
            })
        );
    }
}
