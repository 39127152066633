<span class="close-modal-outside"><i (click)="ActiveModal.dismiss()" class="im-icon im-icon-x-light"></i></span>
<div class="modal-wrap">
    <ng-container *ngIf="!ready">
        <app-spinner [centerVertically]="true"></app-spinner>
    </ng-container>
    <div *ngIf="toolkit" class="d-flex detail-modal">

        <!-- start form -->
        <div class="d-flex flex-column w-100 flex-grow-1 scroll-y">
            <ng-container *ngIf="loading">
                <div style="height: 100%;">
                    <app-spinner [centerVertically]="true"></app-spinner>
                </div>
            </ng-container>
            <ng-container *ngIf="!loading && ready">
                <div class="">
                    <!-- header -->
                    <div class="detail-modal-header d-flex flex-row justify-content-between align-items-center" style="min-height: 36px;">
                        <h1 style="min-height: 36px;">{{toolkit.title}} {{id ? toolkit.code : 'Nieuw toolkit artikel'}}</h1>
                        <div class="h-100 d-flex align-items-center" *ngIf="this.activeSection !== 'general'">
                            <!-- delete -->
                            <div *ngIf="true" class="position-relative" appClickOutside (clickOutside)="actionId = null">
                                <button class="button tertiary delete-red" (click)="actionId = 'delete'" [class.disabled]="saving"><i class="im-icon im-icon-trash delete-red icon-l mr-xxxs" style="transform: translateY(-1px);"></i><span>Verwijderen</span></button>
                                <app-confirm-delete (confirm)="delete();" (cancel)="actionId = null" [open]="actionId === 'delete'" positionClass="left" positionClassV="bottom"></app-confirm-delete>
                            </div>
                            <!-- edit -->
                            <button *ngIf="true" class="button tertiary ml-xs" (click)="activeSection = 'general'" [class.disabled]="saving"><i class="im-icon im-icon-pencil icon-l mr-xxxs"></i><span>Wijzigen</span></button>
                        </div>
                        <div class="h-100 d-flex align-items-center" *ngIf="sectionIsActive('general')">
                            <button class="button tertiary" (click)="canExit() && (id ? activeSection = null : ActiveModal.dismiss()); resetForm()" [class.disabled]="saving"><span>Annuleren</span></button>
                            <app-button classes="primary-default ml-xs" icon="check-round-filled" text="Bewaren" [disabled]="saving" [submitting]="submitting" (emitClick)="createUpdateToolkit()"></app-button>
                        </div>
                    </div>
                    
                    <div class="detail-modal-body">
                        <!-- content -->
                        <div class="row display-row mw-1200-px">
                            <div class="col-xl-12">
                                <!-- Titel -->
                                <div class="display-content">
                                    <ng-container *ngIf="this.activeSection !== 'general'">
                                        <div class="display-label" [class.text-color]="sectionIsActive('general')">Titel</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{toolkit.title | display}}</span>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="sectionIsActive('general')">
                                        <app-field-text
                                            [model]="formSTR.title" 
                                            (modelChange)="formSTR.title = $event; formChange();" 
                                            label="Titel"
                                            [error]="validation.title"
                                            placeholder="Titel"
                                            maxWidth="420">
                                        </app-field-text>
                                    </ng-container>                            
                                </div>
                            </div>
                        </div>
                        <!-- Inhoud -->
                        <div class="row display-row mw-1200-px">
                            <div class="col-md-12">
                                <div class="display-content">
                                    <ng-container  *ngIf="this.activeSection !== 'general'">
                                        <div class="display-label">Inhoud</div>
                                        <div class="display-value d-flex flex-row">
                                            <span class="page-content" [innerHTML]="toolkit.content | safe : 'html'"></span>
                                        </div>
                                    </ng-container>          
                                    <ng-container *ngIf="sectionIsActive('general')">
                                        <app-field-richtext 
                                            [model]="formSTR.content" 
                                            (modelChange)="formSTR.content = $event; formChange();" 
                                            [label]="'Inhoud' | display"
                                            [expanded]="true"
                                            [error]="validation.content">
                                        </app-field-richtext>
                                    </ng-container>                
                                </div>
                            </div>
                        </div>
                        <!-- Link -->
                        <div class="row display-row mw-1200-px">
                            <div class="col-xl-12">
                                <div class="display-content">
                                    <ng-container *ngIf="this.activeSection !== 'general'">
                                        <div class="display-label" [class.text-color]="sectionIsActive('general')">Link</div>
                                        <div class="display-value d-flex flex-row">
                                            <a *ngIf="toolkit.link" [href]="toolkit.link" target="_blank" class="format-link">{{toolkit.link}}</a>
                                            <span *ngIf="!toolkit.link">-</span>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="sectionIsActive('general')">
                                        <app-field-text
                                            [model]="formSTR.link" 
                                            (modelChange)="formSTR.link = $event; formChange();" 
                                            label="Link"
                                            [error]="validation.link"
                                            optionalString=""
                                            placeholder="Link"
                                            maxWidth="420">
                                        </app-field-text>
                                    </ng-container>                            
                                </div>
                            </div>
                        </div>
                        <div class="row display-row mw-1200-px">
                            <div class="col-md-5">
                                <!-- Image -->
                                <div class="display-content">
                                    <ng-container *ngIf="this.activeSection !== 'general'">
                                        <div class="display-label">Afbeelding</div>
                                        <div class="display-value d-flex flex-row">
                                            <img *ngIf="toolkit.imageUrl" [src]="toolkit.imageUrl" alt="toolkitfoto">
                                            <span *ngIf="!toolkit.imageUrl">-</span>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="this.activeSection === 'general'">
                                        <app-field-image
                                            (sendFile)="imageChange($event); formChange();"
                                            [label]="'Afbeelding' | display"
                                            [url]="toolkit.imageUrl"
                                            [error]="validation.image"
                                            [optionalString]="'.jpg, .png, .JPEG'">
                                        </app-field-image>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <div class="row display-row mw-1200-px" >
                            <div class="col-md-2">
                                <!-- published -->
                                <div class="display-content">
                                    <ng-container *ngIf="!sectionIsActive('general')">
                                        <div class="display-label">Gepubliceerd</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{getPublishedText() | display}}</span>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="sectionIsActive('general')">
                                        <app-field-radios 
                                            class="d-block"
                                            label="Gepubliceerd"
                                            [model]="formSTR.isPublished"
                                            (modelChange)="formSTR.isPublished = $event"
                                            [error]="validation.isPublished"
                                            optionalString=""
                                            [options]="[{title: 'Ja', value: 1},{title: 'Nee', value: 2}]"
                                            [optionWidth]="80"
                                            [optionColumnWidth]="">
                                        </app-field-radios>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="detail-modal-footer" *ngIf="toolkit.id">
                        <p *ngIf="toolkit.createTS">Aangemaakt op {{toolkit.createTS | formatTs: 'DD-MM-YYYY'}} <span *ngIf="toolkit.createBy">door {{toolkit.createBy}}</span></p>
                        <p>Gewijzigd op {{toolkit.updateTS | formatTs: 'DD-MM-YYYY'}} <span *ngIf="toolkit.updateBy">door {{toolkit.updateBy}}</span></p>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>