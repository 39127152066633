import { Injectable } from '@angular/core';
import { DefaultService } from '../utils/api';

@Injectable({
    providedIn: 'root'
})
export class ProductService {
    constructor(private DefaultService: DefaultService) {}

    public getProducts(searchSTR?): any {
        return this.DefaultService.discountcodeGetProducts(searchSTR);
    }
}
