import { Injectable } from '@angular/core';
import { DefaultService } from '../utils/api';
import { ToastrService } from 'ngx-toastr';
import { tap } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class QuestionsService {
    constructor(private DefaultService: DefaultService, private toastr: ToastrService) {}

    public getQuestion(id: number) {
        return this.DefaultService.questionGetQuestion(id);
    }

    public updateInsertQuestion(body: any, id?: number) {
        return this.DefaultService.questionsUpdateOrInsertQuestion(body).pipe(
            tap((next) => {
                if (id) {
                    this.toastr.success(
                        'Veelgestelde vraag werd succesvol opgeslagen',
                        'Veelgestelde vraag opgeslagen'
                    );
                } else {
                    this.toastr.success(
                        'Veelgestelde vraag werd succesvol aangemaakt',
                        'Veelgestelde vraag aangemaakt'
                    );
                }
            })
        );
    }

    public deleteQuestion(id: number) {
        return this.DefaultService.questionsDeleteQuestion(id).pipe(
            tap((next) => {
                this.toastr.success('Veelgestelde vraag werd succesvol verwijderd', 'Veelgestelde vraag verwijderd');
            })
        );
    }
}
