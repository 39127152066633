import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, takeUntil } from 'rxjs';
import { BannerModalComponent } from 'src/app/components/modals/banner-modal/banner-modal.component';
import { BannerService } from 'src/app/services/banner.service';
import { SearchService } from 'src/app/services/search.service';
import { HelpersService } from 'src/app/services/helpers.service';
import { DefaultService } from 'src/app/utils/api';
import { ConfigService } from 'src/app/services/config.service';

@Component({
    selector: 'app-banners',
    templateUrl: './banners.component.html',
    styleUrls: ['./banners.component.scss']
})
export class BannersComponent implements OnInit, OnDestroy {
    onDestroy$ = new Subject<void>();
    popoverHelper: any = null;
    panelData: any = null;
    hasPanelData: boolean = false;
    searchSTR: any = {};
    SEARCHED: any = {};

    tableHeads: any = [
        { name: 'Titel', code: 'title', sortable: true, width: '70%' },
        { name: 'Aangemaakt op', code: 'createTS', sortable: true, width: '9%' },
        { name: 'Actief', code: 'active', sortable: true, width: '3%' },
        { name: 'Gewijzigd op', code: 'updateTS', sortable: true, width: '9%' },
        { name: 'Gewijzigd door', code: 'updateUser', sortable: true, width: '9%' },
        { name: '', code: 'actions' }
    ];

    tableSort: { code: string; dir: string } = { code: 'active', dir: 'desc' };

    banners: any[] = null;
    startRow: number = 0;
    RPP: number = 50;
    totalRows: number = null;
    tableLoading: boolean = false;
    ready: boolean = false;

    constructor(
        public HelpersService: HelpersService,
        public ModalService: NgbModal,
        public BannerService: BannerService,
        public DefaultService: DefaultService,
        private SearchService: SearchService,
        public ConfigService: ConfigService
    ) {}

    ngOnInit(): void {
        this.initSearchSTR();
        const bannerId = this.HelpersService.getParam('bannerId');
        if (bannerId) this.openCreateUpdateModal(bannerId);
        const page = parseInt(this.HelpersService.getParam('page'));
        if (page) this.startRow = page * this.RPP - this.RPP;
        const query = this.HelpersService.getParam('query');
        if (query) this.searchSTR = JSON.parse(unescape(query));
        this.getBanners();
    }

    getBanners(): void {
        this.SearchService.formatTags({ ...this.searchSTR }, this.getDefaultSearch()).then((response) => {
            this.SEARCHED = response;
            const searchSTR = this.getSearchQuery();
            this.deSelectRows();
            this.tableLoading = true;
            this.DefaultService.bannersGetBanners(searchSTR.startRow, searchSTR.rPP, searchSTR.term, searchSTR.orderBy)
                .pipe(takeUntil(this.onDestroy$))
                .subscribe((next) => {
                    if (this.startRow && this.startRow >= next.rows) {
                        this.resetStartRow();
                        return;
                    }
                    this.banners = next.data.map((item: any) => {
                        return this.getFormattedItem(item);
                    });
                    this.tableLoading = false;
                    this.totalRows = next.rows;
                    this.ready = true;
                    document.body.scrollTop = 0;
                    document.documentElement.scrollTop = 0;
                });
        });
    }

    getFormattedItem(item) {
        const actions = [
            {
                name: 'Wijzigen',
                code: 'edit',
                icon: 'pencil'
            },
            {
                name: 'Verwijderen',
                code: 'delete',
                icon: 'trash',
                class: 'delete-red',
                confirmDelete: true
            }
        ];
        let titleClassName = 'text-color';
        return {
            ...item,
            title: {
                type: 'default',
                classList: titleClassName,
                value: item.title,
                ellipsis: true
            },
            createTS: {
                type: 'ts',
                ts: item.createTS,
                format: 'DD-MM-YYYY'
            },
            updateTS: {
                type: 'ts',
                ts: item.updateTS,
                format: 'DD-MM-YYYY'
            },
            active: { type: 'checkmark', checked: item.active },
            actions: { type: 'actions', actions: actions }
        };
    }

    openCreateUpdateModal(id?: string, editMode?: boolean) {
        const modalRef = this.ModalService.open(BannerModalComponent, {
            windowClass: 'main-modal detail-modal',
            beforeDismiss: () => {
                return modalRef.componentInstance.canExit && modalRef.componentInstance.canExit();
            }
        });
        if (id) {
            this.HelpersService.addParam('bannerId', id);
            //add pathparam to url
            modalRef.componentInstance.id = id;
            modalRef.componentInstance.editMode = editMode;
        } else this.HelpersService.addParam('bannerId', 'new');
        modalRef.result
            .then((returnValue) => {
                switch (returnValue) {
                    case 'delete':
                        this.deSelectRows();
                        this.getBanners();
                        break;
                }
            })
            .finally(() => {
                this.HelpersService.removeParam('bannerId');
            });
        // on create
        modalRef.componentInstance.created.subscribe((banner: any) => {
            this.HelpersService.addParam('bannerId', banner.id);
            this.search(true);
        });
        // on update
        modalRef.componentInstance.updated.subscribe((banner: any) => {
            this.HelpersService.addParam('bannerId', banner.id);
            this.search(true);
            this.getBanners();
        });
    }

    deleteBanner(id: number) {
        this.BannerService.deleteBanner(id).subscribe((next) => {
            this.getBanners();
        });
    }

    dismissPopover() {
        setTimeout(() => {
            this.popoverHelper = null;
        }, 1);
    }

    resetStartRow() {
        this.startRow = 0;
        this.HelpersService.addParam('page', (Math.ceil(this.startRow / this.RPP) + 1).toString());
        this.getBanners();
    }

    changeStartRow(startRow: number) {
        this.startRow = startRow;
        this.HelpersService.addParam('page', (Math.ceil(this.startRow / this.RPP) + 1).toString());
        this.getBanners();
    }

    getSearchQuery() {
        return {
            ...this.searchSTR,
            startRow: this.startRow,
            rPP: this.RPP,
            orderBy: this.getSortARR()
        };
    }

    getDefaultSearch() {
        return {
            banners: []
        };
    }

    getSortARR() {
        if (!this.tableSort || !this.tableSort.code) {
            return [];
        }
        return [`${this.tableSort.code} ${this.tableSort.dir}`];
    }

    setSort(code: string, dir: string) {
        this.tableLoading = true;
        this.tableSort = { code: code, dir: dir };
        this.getBanners();
    }

    tableClick(item: any, head: string) {
        switch (item.type) {
            case 'actions':
                break;
            default:
                this.hasPanelData ? this.selectRow(item) : this.openCreateUpdateModal(item.id);
                break;
        }
    }

    actionClick(item: any, action: string) {
        switch (action) {
            case 'edit':
                this.openCreateUpdateModal(item.id, true);
                break;
            case 'delete':
                this.deleteBanner(item.id);
                break;
        }
    }

    selectRow(item: any) {
        this.banners = this.banners.map((x) => {
            if (x.id === item.id && !x.trActive) {
                x.trActive = true;
                this.panelData = item;
            } else if (x.id === item.id) {
                delete this.panelData;
                delete x.trActive;
            } else {
                delete x.trActive;
            }
            return x;
        });
    }

    search = (reset?: boolean, SEARCH?: any) => {
        SEARCH = SEARCH || this.searchSTR;
        this.searchSTR = { ...SEARCH };
        this.startRow = 0;
        if (reset) {
            this.initSearchSTR();
        }
        if (this.HelpersService.objectIsEmpty(SEARCH)) {
            this.HelpersService.removeParam('query');
        } else {
            const query = escape(JSON.stringify(SEARCH));
            this.HelpersService.addParam('query', query);
        }
        setTimeout(() => {
            this.HelpersService.addParam('page', (Math.ceil(this.startRow / this.RPP) + 1).toString());
        }, 1);
        this.getBanners();
    };

    deSelectRows() {
        if (this.banners) {
            this.banners = this.banners.map((x) => {
                delete x.trActive;
                return x;
            });
        }
        delete this.panelData;
    }

    initSearchSTR() {
        this.searchSTR = {};
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
    }
}
