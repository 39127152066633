import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { DiscountComponent } from './pages/discount/discount.component';
import { LoginComponent } from './pages/login/login.component';
import { NewslettersComponent } from './pages/newsletters/newsletters.component';
import { SelectionsComponent } from './pages/selections/selections.component';
import { LeaveGuardService } from './services/leave-guard.service';
import { ActiveSubstancesComponent } from './pages/active-substances/active-substances.component';
import { NewsComponent } from './pages/news/news.component';
import { TipsComponent } from './pages/tips/tips.component';
import { FaqComponent } from './pages/faq/faq.component';
import { PagesComponent } from './pages/pages/pages.component';
import { ContactComponent } from './pages/contact/contact.component';
import { ToolkitComponent } from './pages/toolkit/toolkit.component';
import { BannersComponent } from './pages/banners/banners.component';
import { HotlineComponent } from './pages/meldpunt/hotline/hotline.component';
import { HotlineDashboardComponent } from './pages/meldpunt/hotline-dashboard/hotline-dashboard.component';
import { HotlineNewsComponent } from './pages/meldpunt/hotline-news/hotline-news.component';
import { HotlinePagesComponent } from './pages/meldpunt/hotline-pages/hotline-pages.component';
import { HotlineNotificationsComponent } from './pages/meldpunt/hotline-notifications/hotline-notifications.component';
import { HotlineMedicinesComponent } from './pages/meldpunt/hotline-medicines/hotline-medicines.component';

const routes: Routes = [
    // { path: '', redirectTo: '/kortingscodes', pathMatch: 'full' }
    // { path: 'login', component: LoginComponent, data: { isPublic: true } }

    // discountcodes
    {
        path: 'kortingscodes',
        component: DiscountComponent,
        canDeactivate: [LeaveGuardService],
        data: { appCode: 'discountcodes' }
    },
    // newsletters
    {
        path: 'dashboard',
        component: DashboardComponent,
        canDeactivate: [LeaveGuardService],
        data: { appCode: 'newsletters' }
    },
    {
        path: 'nieuwsbrieven',
        component: NewslettersComponent,
        canDeactivate: [LeaveGuardService],
        data: { appCode: 'newsletters' }
    },
    {
        path: 'selecties',
        component: SelectionsComponent,
        canDeactivate: [LeaveGuardService],
        data: { appCode: 'newsletters' }
    },
    //Rijveilig
    {
        path: 'rijveilig',
        redirectTo: 'werkzame-stoffen',
        canDeactivate: [LeaveGuardService],
        data: { appCode: 'rijveilig' }
    },
    {
        path: 'werkzame-stoffen',
        component: ActiveSubstancesComponent,
        canDeactivate: [LeaveGuardService],
        data: { appCode: 'rijveilig' }
    },
    {
        path: 'nieuws',
        component: NewsComponent,
        canDeactivate: [LeaveGuardService],
        data: { appCode: 'rijveilig' }
    },
    {
        path: 'handige-tips',
        component: TipsComponent,
        canDeactivate: [LeaveGuardService],
        data: { appCode: 'rijveilig' }
    },
    {
        path: 'veelgestelde-vragen',
        component: FaqComponent,
        canDeactivate: [LeaveGuardService],
        data: { appCode: 'rijveilig' }
    },
    {
        path: 'paginas',
        component: PagesComponent,
        canDeactivate: [LeaveGuardService],
        data: { appCode: 'rijveilig' }
    },
    {
        path: 'contact',
        component: ContactComponent,
        canDeactivate: [LeaveGuardService],
        data: { appCode: 'rijveilig' }
    },
    {
        path: 'toolkit',
        component: ToolkitComponent,
        canDeactivate: [LeaveGuardService],
        data: { appCode: 'rijveilig' }
    },
    {
        path: 'banner',
        component: BannersComponent,
        canDeactivate: [LeaveGuardService],
        data: { appCode: 'rijveilig' }
    },
    //Meldpunt
    {
        path: 'meldpunt',
        canDeactivate: [LeaveGuardService],
        data: { appCode: 'meldpunt' },
        children: [
            {
                path: '',
                redirectTo: 'dashboard',
                canDeactivate: [LeaveGuardService],
                pathMatch: 'full',
                data: { appCode: 'meldpunt' }
            },
            {
                path: 'dashboard',
                component: HotlineDashboardComponent,
                canDeactivate: [LeaveGuardService],
                data: { appCode: 'meldpunt' }
            },
            {
                path: 'meldingen',
                component: HotlineComponent,
                canDeactivate: [LeaveGuardService],
                data: { appCode: 'meldpunt' }
            },
            {
                path: 'nieuws',
                component: HotlineNewsComponent,
                canDeactivate: [LeaveGuardService],
                data: { appCode: 'meldpunt' }
            },
            {
                path: 'paginas',
                component: HotlinePagesComponent,
                canDeactivate: [LeaveGuardService],
                data: { appCode: 'meldpunt' }
            },
            {
                path: 'popups',
                component: HotlineNotificationsComponent,
                canDeactivate: [LeaveGuardService],
                data: { appCode: 'meldpunt' }
            },
            {
                path: 'medicijnen',
                component: HotlineMedicinesComponent,
                canDeactivate: [LeaveGuardService],
                data: { appCode: 'meldpunt' }
            }
        ]
    },
    // 404
    { path: '**', redirectTo: '/' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always' })],
    exports: [RouterModule]
})
export class AppRoutingModule {}
