<div class="page">
    <!-- top bar -->
    <section class="top-bar">
        <!-- search left -->
        <div class="search-wrap">
            <div class="d-flex align-items-center">
                <div class="search-bar-wrap">
                    <!-- search bar -->
                    <app-search-input
                        [disabled]="changeSequence"
                        [model]="searchSTR.term" 
                        (modelChange)="searchSTR.term = $event" 
                        [placeholder]="'In toolkit zoeken...'"
                        maxWidth="240"
                        (enter)="search()">
                    </app-search-input>
                    </div>
                <!-- advanced search button -->
                <button (click)="search(true)" [disabled]="changeSequence" class="button-link underline pt-xxs pb-xxs">Herstellen</button>
            </div>
            <div class="search-sub-wrap d-flex">
                <div class="d-flex align-items-start">
                    <div class="tags" *ngIf="SEARCHED?.length">
                        <div class="tag d-inline-flex align-items-center" *ngFor="let tag of SEARCHED">
                            <span><span class="title">{{tag.title}}: </span><span class="value">{{tag.value}}</span></span>
                            <i class="im-icon im-icon-close-round-filled" (click)="SearchService.removeTag(tag.key, searchSTR, search)"></i>
                        </div>
                    </div>
                    <!-- reset search button -->
                    <button *ngIf="SEARCHED?.length" (click)="search(true)" class="button-link underline">Alle filters wissen</button>
                </div>
            </div>
        </div>
        <div class="d-flex align-items-center">
            <!-- overview -->
            <a href="{{this.ConfigService.getConfig().appOverviewUrl}}" class="d-flex align-items-center portal-link">
                <i class="im-icon im-icon-portal-dots"></i>
            </a>
            <!-- profile right -->
            <div class="profile-wrap d-flex align-items-center">
                <app-profile-section></app-profile-section>
            </div>
        </div>
    </section>
    <!-- end top bar -->

    <!-- title -->
    <div class="d-flex justify-content-between">
        <h1 class="">Toolkit <span [class.saving]="tableLoading" class="saving--pre" *ngIf="ready">({{totalRows}})</span></h1>
        <div class="d-flex align-items-center" *ngIf="!changeSequence" style="gap: 16px">
            <app-button classes="default--light" icon="sort-up-down" text="Volgorde wijzigen" (emitClick)="clickChangeSequence()"></app-button>
            <app-button classes="primary-default" icon="plus-round" text="Toolkit toevoegen" [disabled]="changeSequence" [submitting]="false" (emitClick)="openCreateUpdateModal()"></app-button>
        </div>
        <!-- change sequence -->
        <div class="d-flex align-items-center change-sequence" *ngIf="changeSequence" style="gap: 16px">
            <div class="title" style="color: #717277;">Volgorde wijzigen:</div>
            <button class="button tertiary" [class.saving]="saving || tableLoading" (click)="cancelUpdateSequence()">Annuleren</button>
            <app-button classes="primary-default" icon="check-round-filled" text="Volgorde bewaren" [disabled]="saving || tableLoading" [submitting]="saving" (emitClick)="submitSequence()"></app-button>
        </div>
    </div>

   <!-- table -->
   <div class="table-wrap">
    <app-table
        [heads]="tableHeads" 
        [data]="toolkits" 
        [sort]="tableSort"
        [RPP]="RPP"
        [startRow]="startRow"
        [totalRows]="totalRows"
        [loading]="tableLoading"
        [ready]="ready"
        emptyState="Geen resultaten"
        (sortChange)="setSort($event.code, $event.dir)"
        (clickData)="tableClick($event.item, $event.head)"
        (clickAction)="actionClick($event.item, $event.action)"
        (startRowChange)="changeStartRow($event)"

        [changeSequence]="changeSequence"
    ></app-table>
   </div>

</div>