import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BannerService } from 'src/app/services/banner.service';
import { DefaultService } from 'src/app/utils/api';

@Component({
    selector: 'app-banner-modal',
    templateUrl: './banner-modal.component.html',
    styleUrls: ['./banner-modal.component.scss']
})
export class BannerModalComponent implements OnInit {
    @Input() id?: any;
    @Output() created = new EventEmitter();
    @Output() updated = new EventEmitter();
    banner: any = {};
    ready: boolean = false;
    loading: boolean = false;
    activeBanner: any = {};
    validation: any = {};
    changes: boolean = false;
    submitting: boolean = false;

    formSTR: any = { active: 0 };

    constructor(
        public ActiveModal: NgbActiveModal,
        private BannerService: BannerService,
        private DefaultService: DefaultService
    ) {}

    ngOnInit(): void {
        if (this.id && this.id !== 'new') {
            this.getActiveBanner();
        } else {
            this.activeBanner = {};
            this.resetForm();
            this.ready = true;
        }
    }

    getActiveBanner() {
        this.loading = true;
        this.DefaultService.bannerGetBanner(this.id).subscribe((res) => {
            this.activeBanner = res;
            this.resetForm();
            this.loading = false;
            this.ready = true;
        });
    }

    formChange() {
        this.changes = true;
    }

    resetForm() {
        this.formSTR = { ...this.activeBanner };
        if (!this.formSTR.text) {
            this.formSTR.text = '';
            this.formSTR.active = true;
        }
    }

    submit() {
        const FORM = this.formSTR;
        this.submitting = true;
        if (FORM.buttonShow) {
            FORM.buttonShow = true;
        } else {
            FORM.buttonShow = false;
        }
        if (FORM.newTab) {
            FORM.newTab = true;
        } else {
            FORM.newTab = false;
        }
        this.BannerService.updateBanner(FORM, this.id || null).subscribe((res) => {
            this.submitting = false;
            this.banner.id ? this.updated.emit(this.activeBanner) : this.created.emit(this.activeBanner);
            this.ActiveModal.close();
        });
    }

    canExit(): boolean {
        if (!this.changes || confirm('Je hebt onopgeslagen wijzigingen, weet je zeker dat je wilt afsluiten?')) {
            this.changes = false;
            return true;
        } else {
            return false;
        }
    }
}
