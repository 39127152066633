<div 
    class="search-multiselect" 
    [class.search-multiselect--filled]="selected?.length"
    [class.search-multiselect--open]="p?.isOpen()" 
    >

    <!-- field -->
    <div 
        class="inner" 
        [ngbPopover]="popContent" 
        ngbPopover="ngbPopover" 
        #p="ngbPopover"
        placement="bottom-left bottom-right" 
        popoverClass="popover--search" 
        [autoClose]="'outside'" 
        >
        <!-- empty -->
        <ng-container *ngIf="!selected?.length">
            <span class="empty">{{placeholder}}</span>
        </ng-container>
        <!-- filled -->
        <ng-container *ngIf="selected?.length">
            <span class="label">{{placeholder}}:</span>
            <!-- model -->
            <div class="d-flex align-items-center">
                <span class="value">{{selected[0]?.title}}</span>
                <span class="more" *ngIf="selected.length > 1">+{{selected.length - 1}}</span>
            </div>
        </ng-container>
        <!-- icon -->
        <i class="im-icon im-icon-triangle-rounded-down"></i>
    </div>

    <!-- popover -->
    <ng-template #popContent>
        <div class="popover--inner">
            <div class="options">
                <ng-container *ngFor="let option of options">
                    <div class="option" (click)="toggleSelect(option)">
                        <div class="fake-check" [class.active]="model?.indexOf(option.value) !== -1"></div>
                        <div class="title">{{option.title}}</div>
                    </div>
                </ng-container>
            </div>
            <div class="clear-wrap" (click)="clear()" *ngIf="allowClear">Selectie leegmaken</div>
        </div>
    </ng-template>
</div>