<div class="page">

    <!-- top bar -->
    <section class="top-bar">
        <!-- search left -->
        <div class="search-wrap">
            <div class="d-flex align-items-center">
                <div class="search-bar-wrap">
                    <!-- search bar -->
                    <app-field-text
                        [model]="searchSTR.term" 
                        (modelChange)="searchSTR.term = $event" 
                        (enter)="search()"
                        [placeholder]="'Zoek kortingscode...'">
                    </app-field-text>
                    <!-- advanced search -->
                    <div class="advanced-search-wrap" *ngIf="advancedSearchOpen">
                        <!-- advanced search head -->
                        <div class="title">
                            <span>Geavanceerd zoeken</span>
                            <span class="x" (click)="advancedSearchOpen = false"><i class="im-icon im-icon-x-light"></i></span>
                        </div>
                        <!-- advanced search body -->
                        <div class="row display-row">
                            <div class="col-6">
                                <app-field-text 
                                    [model]="searchSTR.term" 
                                    (modelChange)="searchSTR.term = $event" 
                                    (enter)="search()" 
                                    label="Term"
                                    placeholder="Zoek kortingscode">
                                </app-field-text>
                            </div>
                            <div class="col-6">
                                <app-field-radios 
                                    class="d-block"
                                    label="Status"
                                    [canUncheck]="true"
                                    [model]="searchSTR.status" 
                                    (modelChange)="searchSTR.status = $event"
                                    [options]="[{title: 'Geldig', value: 'valid'},{title: 'Inactief', value: 'inactive'}]"
                                    [optionWidth]=""
                                    [optionColumnWidth]="6">
                                </app-field-radios>
                            </div>
                        </div>
                        <div class="row display-row">
                            <div class="col-6">
                                <app-field-select 
                                    [model]="searchSTR.category" 
                                    [options]="categoryARR"
                                    (modelChange)="searchSTR.category = $event;" 
                                    label="Categorie"
                                    placeholder="Alle">
                                </app-field-select>
                            </div>
                            <div class="col-6">
                                <app-field-radios 
                                    class="d-block"
                                    label="Gebruikt"
                                    [canUncheck]="true"
                                    [model]="searchSTR.used" 
                                    (modelChange)="searchSTR.used = $event"
                                    [options]="[{title: 'Ja', value: 'yes'},{title: 'Nee', value: 'no'}]"
                                    [optionWidth]=""
                                    [optionColumnWidth]="6">
                                </app-field-radios>
                            </div>
                        </div>
                        <div class="row display-row">
                            <div class="col-6">
                                <app-field-typeahead-small
                                    [detectModelChange]="searchSTR.product"
                                    [initialModel]="searchSTR.initialProduct" 
                                    (modelChange)="searchSTR.product = $event ? $event.item.key : '';searchSTR.initialProduct = $event ? $event.item : ''" 
                                    [options]="productARR"
                                    [searchKeyARR]="['title','value','artikelCode', 'productType']"
                                    [afterARR]="['productType','artikelCode']"
                                    [afterInputARR]="['artikelCode']"
                                    [editable]="false"
                                    label="Product"
                                    placeholder="Zoek op product"
                                    (enter)="search()">
                                </app-field-typeahead-small>
                            </div>
                            <div class="col-6">
                                <app-field-radios 
                                    class="d-block"
                                    label="Type"
                                    [canUncheck]="true"
                                    [model]="searchSTR.type" 
                                    (modelChange)="searchSTR.type = $event"
                                    [options]="[{title: 'Unieke code', value: 'unique'},{title: 'Groepscode', value: 'group'}]"
                                    [optionWidth]=""
                                    [optionColumnWidth]="6">
                                </app-field-radios>
                            </div>
                        </div>
                        <!-- advanced search foot -->
                        <div class="sep"></div>
                        <div class="foot">
                            <button (click)="search(true);" class="button-link underline"><span>Alle filters wissen</span></button>
                            <button (click)="search();" class="button default dark text-center d-block">Zoeken</button>
                        </div>
                    </div>
                    <div class="advanced-search-backdrop" *ngIf="advancedSearchOpen" (click)="advancedSearchOpen = false"></div>
                </div>
                <!-- advanced search button -->
                <button (click)="advancedSearchOpen = true;" class="button-link underline pt-xxs pb-xxs">Geavanceerd zoeken</button>
            </div>
            <div class="search-sub-wrap d-flex">
                <div class="d-flex align-items-start">
                    <div class="tags" *ngIf="SEARCHED?.length">
                        <div class="tag d-inline-flex align-items-center" *ngFor="let tag of SEARCHED">
                            <span><span class="title">{{tag.title}}: </span><span class="value">{{tag.value}}</span></span>
                            <i class="im-icon im-icon-close-round-filled" (click)="SearchService.removeTag(tag.key, searchSTR, search)"></i>
                        </div>
                    </div>
                    <!-- reset search button -->
                    <button *ngIf="SEARCHED?.length" (click)="search(true)" class="button-link underline">Alle filters wissen</button>
                </div>
            </div>
        </div>
        <div class="d-flex align-items-center">
            <!-- overview -->
            <a href="{{this.ConfigService.getConfig().appOverviewUrl}}" class="d-flex align-items-center portal-link">
                <i class="im-icon im-icon-portal-dots"></i>
            </a>
            <!-- profile right -->
            <div class="profile-wrap d-flex align-items-center">
                <app-profile-section></app-profile-section>
            </div>
        </div>
    </section>
    <!-- end top bar -->

    <!-- side panel -->
    <div class="side-panel d-flex flex-column justify-content-between" *ngIf="false">
        <div>
            <div class="position-relative">
                <!-- ADVANCED -->
                <div class="w-100" *ngIf="searchType === 'advanced'" [@switchPanelAnimation]>
                    <div class="row row-form">
                        <div class="col-md-12">
                            <app-field-text 
                                [model]="searchSTR.term" 
                                (modelChange)="searchSTR.term = $event" 
                                (enter)="search()" 
                                label="Code"
                                placeholder="Zoek kortingscode">
                            </app-field-text>
                        </div>
                    </div>
                    <div class="row row-form">
                        <div class="col-md-12">
                            <app-field-radios 
                                class="d-block"
                                label="Status"
                                [canUncheck]="true"
                                [model]="searchSTR.status" 
                                (modelChange)="searchSTR.status = $event"
                                [options]="[{title: 'Geldig', value: 'valid'},{title: 'Inactief', value: 'inactive'}]"
                                [optionWidth]=""
                                [optionColumnWidth]="6">
                            </app-field-radios>
                        </div>
                    </div>
                    <div class="row row-form">
                        <div class="col-md-12">
                            <app-field-radios 
                                class="d-block"
                                label="Gebruikt"
                                [canUncheck]="true"
                                [model]="searchSTR.used" 
                                (modelChange)="searchSTR.used = $event"
                                [options]="[{title: 'Ja', value: 'yes'},{title: 'Nee', value: 'no'}]"
                                [optionWidth]=""
                                [optionColumnWidth]="6">
                            </app-field-radios>
                        </div>
                    </div>
                    <div class="row row-form">
                        <div class="col-md-12">
                            <app-field-typeahead-small
                                [detectModelChange]="searchSTR.product"
                                [initialModel]="searchSTR.initialProduct" 
                                (modelChange)="searchSTR.product = $event ? $event.item.key : '';searchSTR.initialProduct = $event ? $event.item : ''" 
                                [options]="productARR"
                                [searchKeyARR]="['title','value','artikelCode', 'productType']"
                                [afterARR]="['productType','artikelCode']"
                                [afterInputARR]="['artikelCode']"
                                [editable]="false"
                                label="Product"
                                placeholder="Zoek op product"
                                (enter)="search()">
                            </app-field-typeahead-small>
                            <!-- <app-field-text 
                                [model]="searchSTR.product" 
                                (modelChange)="searchSTR.product = $event"
                                (enter)="search()"
                                label="Product" 
                                placeholder="Zoek op product">
                            </app-field-text> -->
                        </div>
                    </div>
                    <div class="row row-form">
                        <div class="col-md-12">
                            <app-field-select 
                                [model]="searchSTR.category" 
                                [options]="categoryARR"
                                (modelChange)="searchSTR.category = $event;" 
                                label="Categorie"
                                placeholder="Alle">
                            </app-field-select>
                        </div>
                    </div>
                    <app-field-radios 
                        class="d-block"
                        label="Type"
                        [canUncheck]="true"
                        [model]="searchSTR.type" 
                        (modelChange)="searchSTR.type = $event"
                        [options]="[{title: 'Unieke code', value: 'unique'},{title: 'Groepscode', value: 'group'}]"
                        [optionWidth]=""
                        [optionColumnWidth]="6">
                    </app-field-radios>
                    <div class="mt-s d-flex flex-column align-items-center search-button-container">
                        <button (click)="search()" class="button default w-100 text-center d-block">Zoek</button>
                        <button (click)="search(true)" class="button-link mt-s fontsize-s"><span>Alle filters wissen</span></button>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <!-- title -->
    <div class="d-flex justify-content-between">
        <h1 class="">Kortingscodes <span [class.saving]="tableLoading" class="saving--pre" *ngIf="ready">({{totalRows}})</span></h1>
        <app-button classes="primary-default" icon="plus-round" text="Nieuwe kortingscode" [disabled]="false" [submitting]="false" (emitClick)="openCreateUpdateModal()"></app-button>
    </div>

   <!-- table -->
   <div class="table-wrap">
    <app-table
        [heads]="tableHeads" 
        [data]="discounts" 
        [sort]="tableSort"
        [RPP]="RPP"
        [startRow]="startRow"
        [totalRows]="totalRows"
        [loading]="tableLoading"
        exportLabel="Exporteer deze lijst"
        [exportBusy]="exportBusy"
        [ready]="ready"
        emptyState="Geen resultaten"
        (sortChange)="setSort($event.code, $event.dir)"
        (clickData)="tableClick($event.item, $event.head)"
        (clickAction)="actionClick($event.item, $event.action)"
        (startRowChange)="changeStartRow($event)"
        (clickExport)="export()"
    ></app-table>
   </div>

</div>